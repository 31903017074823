(function(app, $) {
	var $cache = {};
	var $requestStore = null;
	var showLanguageCountries = [];
	var urlsOfDisabledCountries = [];
	var defaultOrigin = 'Home-Show';

	function initializeCache() {
		$cache = {
			document: $(document),
			countryConfirm: $('.js-countryselect_confirm'),
			languageSelectorWrapper: $('.js-language_selector'),
			countrySelector: $('.js-country_selector select.country'),
			countrySelectorPopup: $('.js-country_selector-popup select.country'),
			countrySelectorContent: $('.js-country_selector-country'),
			countryList: $('.js-language_selector-country_list'),
			localeList: $('.js-language_selector-language_list'),
			updateQueryElements: $('[data-update-query]'),
			selectedCountryClass: 'b-language_selector-country_item--selected',
			selectedLocaleClass: 'b-language_selector-language_item--selected',
			chooseDestination: $('.js-country_choose-destination'),
			popupLocalesInput: $('.js-localeselect select'),
			popupLocalesBlock: $('.b-modal_locale_redirect_block'),
			popupLocalesCountryBlock: $('.b-modal_locale_redirect_countryselect'),
			popupLocalesMoreSelector: '.js-modal_local_more',
			flyoutWrapper: $('.js-header_min_country_selector'),
			closeFlyout: $('.js-country_choose-close'),
			countryChooseLink: $('.js-country_choose-link'),
			headerCountrySelector: $('.js-header_country_selector_item'),
			countryLinkSel: '.js-country_selection-link',
			countryLinkMoreSel: '.js-country_selection-link_more',
			languageSelLink: $('.js-language_selector_link'),
			titleHoverClassName: 'b-header_country_selector-title_hover',
			titleTabletHoverClassName: 'b-header_country_selector-title_hover_tablet',
			nativeLangSelector: $('.js-native-langselector'),
			nativeCountrySelector: $('.js-native-countryselector'),
			countryInfoBlock: $('.js-country_info'),
			countryInfoBlockId: '#js-country_info',
			headerCountrySelected: $('.js-country_selector-selected'),
			showLocalesPopupCookieKey: 'localeRedirectPopup',
			preferredLanguageCookieKey: 'preferredLanguage',
			pageUrl: app.urls.pageURL,
			categoryRefinementSel: '.js-category_refinement-link',
			activeRefinementSel: '.js-refinement-link-active',
			currentCountryCode: app.user.country.value
		};

		$cache.countrySelectorTitle = $cache.headerCountrySelector.find('.js-country_selector-title');

		$cache.languageSelector = $cache.languageSelectorWrapper.find('select');
		if ($cache.countrySelector.length) {
			getShowLanguageCountry($cache.countrySelector.find('option'));
			styleLanguageSelector($cache.countrySelector.val());
		}
	}

	function initializeDOM() {
		setGeneralElements();
		setSelectedCountry();
		setSelectedLocale();
	}

	function getShowLanguageCountry($optionList) {
		$optionList.each(function() {
			var $this = $(this);
			if ($this.data('showLanguage')) {
				showLanguageCountries.push($(this).val());
			}
			if ($this.data('externalLink')) {
				urlsOfDisabledCountries.push($(this).val());
			}
		});
	}

	/**
	 * @private
	 * @function
	 * @description Show Tooltip for select country destination
	 */
	function showTooltip() {
		if ($cache.chooseDestination.length < 1) {
			return;
		}
		$cache.countrySelectorContent.addClass('h-hidden');
		$cache.chooseDestination.removeClass('h-hidden');
		$cache.flyoutWrapper.removeClass('h-hidden').addClass('h-show');
	}

	/**
	 * @private
	 * @function
	 * @description Hide Tooltip for select country destination
	 * 				and replace content to normal country selector
	 */
	function hideTooltip() {
		setCountryDestinationCookie();
		$cache.flyoutWrapper.addClass('h-hidden').removeClass('h-show');
		$cache.chooseDestination.addClass('h-hidden');
		$cache.countrySelectorContent.removeClass('h-hidden');
	}

	/**
	 * @private
	 * @function
	 * @description Get status from newsletter cookies
	 * @returns {Boolean} if newsletter modal showed - return "true"
	 */
	function isNewsletterShowed() {
		return !!$.cookie('nlPopupCountSession') || $.cookie('nlPopupCount') > 1;
	}

	/**
	 * @private
	 * @function
	 * @description Get cookie "countryDestination"
	 * @returns {Boolean} if Country Tooltip Closed - return "true"
	 */
	function isCountryTooltipClosed() {
		return $.cookie('countryDestination');
	}

	/**
	 * @private
	 * @function
	 * @description Get cookie "countrySelected"
	 * @returns {Boolean} if country selected - return "true"
	 */
	function isCountrySelected() {
		return $.cookie('countrySelected');
	}

	/**
	 * @private
	 * @function
	 * @description Set cookie "countryDestination" (use after close tooltip)
	 */
	function setCountryDestinationCookie() {
		var expiration = new Date();
		var year = 31536000; // seconds in one year
		expiration.setTime(expiration.getTime() + (year * 1000));
		$.cookie('countryDestination', true, { expires: expiration, path: '/' });
	}

	function changeCountry(url) {
		var isMenuToggle =
			app.util.getQueryStringParams(url, false).hasOwnProperty('menuToggle') &&
			!app.util.getQueryStringParams(window.location.href, false).hasOwnProperty('menuToggle');
		app.ajax.load({
			url: url,
			dataType: 'json',
			callback: function(data) {
				if (navigator.serviceWorker && navigator.serviceWorker.controller) {
					navigator.serviceWorker.controller.postMessage({
						type: 'SW_EMPTY_CACHE'
					});
				}
				if (data && data.location && data.location.length) {
					app.page.redirect(data.location);
				} else {
					if (isMenuToggle) {
						window.location.href = app.util.appendParamToURL(window.location.href, 'menuToggle', 1);
					} else {
						app.page.refresh(true);
					}
				}
			}
		});
	}

	function addAppliedFilters(url) {
		var params = app.util.getQueryStringParams(window.location.search);
		var activeRefinementCategory = $($cache.categoryRefinementSel + $cache.activeRefinementSel);
		if (activeRefinementCategory.length > 0 && activeRefinementCategory.data('cgid') !== 'undefined') {
			params.cgid = activeRefinementCategory.data('cgid');
		}
		Object.keys(params).forEach(function(key) {
			if (key.search('prefn|prefv|cgid') > -1) {
				var value = params[key];
				url = app.util.appendParamToURL(url, key, value);
			}
		});
		return url;
	}

	function initializeEvents() {
		if ($cache.chooseDestination.length) {
			$cache.closeFlyout.on('click', hideTooltip);
			$cache.countryChooseLink.on('click', hideTooltip);
		}

		$cache.document.on('click', $cache.countryLinkSel, function(e) {
			e.preventDefault();
			var data = {
				url: $(e.currentTarget).attr('href'),
				selectedCountryCode: $(this).parent().data('countrycode').toUpperCase()
			};

			if (app.page.ns === 'search') {
				data.url = addAppliedFilters(data.url);
			}
			app.components.global.localeselector.redirectURL = data.url;
			$cache.document.trigger('modal.removegiftcert.confirm', data);
		});

		$cache.document.on('click', $cache.countryLinkMoreSel, function() {
			var url = $(this).attr('href');
			if (app.page.ns === 'search') {
				url = addAppliedFilters(url);
			}
			$(this).attr('href', url);
		});

		$cache.countrySelector.on('change', function() {
			$cache.countryInfoBlock.hide(); //hide all blocks
			styleLanguageSelector($(this).val());
			// find and show only necessary block with info
			$($cache.countryInfoBlockId + $cache.countrySelector.val()).show();

			if (app.configs.showCountrySpecificLocalesOnly) {
				showLocalesForCountry($cache.countrySelector, $cache.languageSelector);
			}
		});

		$cache.countrySelectorPopup.on('change', function() {
			showLocalesForCountry($cache.countrySelectorPopup, $cache.popupLocalesInput);
		});

		$cache.languageSelector.on('change', function() {
			var url = $(this)
				.find('option:selected')
				.data('url');
			window.location.href =
				$cache.currentCountryCode === $cache.countrySelector.val()
					? app.util.removeParamsFromURL(url, ['country'])
					: app.util.appendParamsToUrl(url, { country: $cache.countrySelector.val() });
		});

		$cache.countryConfirm.on('click', function(e) {
			e.preventDefault();
			var $form = $(this).closest('form'),
				data = {};
			data.Country = $form.find('select.country').val(),
			data.Language = $form.find('.js-language_selector select').val(),
			$form.find('[type=hidden]').each(function(){
				data[this.name] = this.value;
			});

			if (data.Country) {
				$requestStore = $form;
				app.components.global.localeselector.redirectURL = app.util.appendParamsToUrl($form.attr('action'), data);
				$cache.document.trigger('modal.removegiftcert.confirm', { url: app.components.global.localeselector.redirectURL, selectedCountryCode: data.Country });
			}
		});

		$(document).on('click', '.js-country_change_confirm', function(e) {
			e.preventDefault();
			app.fancybox.close();
			if ($requestStore && $requestStore.location && !!$requestStore.location.length) {
				app.page.redirect($requestStore.location);
			} else {
				if ($requestStore) {
					$requestStore.submit();
				}
			}
		});

		$cache.document.on('click', $cache.popupLocalesMoreSelector, function() {
			app.components.global.localeselector.init();
			$cache.popupLocalesBlock.addClass('h-hidden');
			$cache.popupLocalesCountryBlock.removeClass('h-hidden');
			$('#dwfrm_countrygateway_pageURL').val($cache.pageUrl);
		});

		if ($cache.chooseDestination.length) {
			if (
				isNewsletterShowed() &&
				!isCountryTooltipClosed() &&
				!isCountrySelected() &&
				app.currentCustomer.getUserClicksNumber() >= app.preferences.minClicksToShowChooseCountry
			) {
				showTooltip();

				$(document).on('click', function(event) {
					if (
						$(event.target)
							.closest('.js-header_min_country_selector')
							.get(0) == null
					) {
						hideTooltip();
					}
				});
			}
		}

		$cache.countrySelectorTitle.on('touchstart', function() {
			$cache.countrySelectorTitle.removeClass($cache.titleHoverClassName);
			if ($cache.flyoutWrapper.hasClass('h-hidden')) {
				$cache.countrySelectorTitle.addClass($cache.titleTabletHoverClassName);
				$cache.flyoutWrapper.removeClass('h-hidden');
			} else {
				$cache.countrySelectorTitle.removeClass($cache.titleTabletHoverClassName);
				$cache.flyoutWrapper.addClass('h-hidden');
			}
		});

		$cache.countrySelectorTitle.on('mouseenter', function() {
			$cache.flyoutWrapper.removeClass('h-hidden');
		});

		$cache.headerCountrySelector.on('mouseleave', function() {
			$cache.flyoutWrapper.addClass('h-hidden');
			$cache.countrySelectorTitle.removeClass($cache.titleTabletHoverClassName);
		});

		if (app.corsOrigin) {
			$cache.languageSelLink.on('click', function(e) {
				e.preventDefault();
				$.ajax($(this).attr('href'), {
					data: { format: 'ajax' },
					xhrFields: app.corsOrigin ? { withCredentials: true } : {}
				}).always(function() {
					window.location.reload();
				});
			});
		} else if (app.util.getConfig('languageSelector.appendCurrentQuery')) {
			$cache.languageSelLink.on('click', function() {
				var $anchor = $(this);
				var query = app.util.getQueryStringParams(window.location.search.slice(1), true);
				var hash = window.location.hash.slice(1);
				if (hash.length && hash.indexOf('=') > -1) {
					//assume that hash contains refinement query string
					$.extend(query, app.util.getQueryStringParams(hash, true));
				}
				var uri = app.util.getUri(this, true);
				$.extend(uri.queryParams, query);
				$anchor.attr('href', uri.url + '?' + app.util.convertMapToQueryString(uri.queryParams, true));
			});
		} else {
			$cache.languageSelLink.on('click', function() {
				var $anchor = $(this);
				var url = addAppliedFilters($anchor.prop("href"));
				$anchor.prop("href", url);
			});
		}

		$cache.nativeLangSelector.on('change', function(){
			var value = $(this).val();
			if(value !== ''){
				document.location.href = value;
			}
		});

		$cache.nativeCountrySelector.on('change', function(){
			var $this = $(this);
			var data = {
				url: $this.val(),
				selectedCountryCode: $this.find('option:selected').text()
			};
			if (data.url) {
				if ($this.find('option:selected').data('mode') === 'ajax') {
					data.url = addAppliedFilters(data.url);
					app.components.global.localeselector.redirectURL = data.url;
					$cache.document.trigger('modal.removegiftcert.confirm', data);
				} else {
					document.location.href = data.url;
				}
			}
		});

		$cache.document.on('ready', function() {
			var showLocalesPopup = $.cookie($cache.showLocalesPopupCookieKey);
			if (showLocalesPopup && showLocalesPopup !== '0') {
				$cache.document.trigger('modal.redirectLocale.confirm');
			}
		});
	}

	function styleLanguageSelector(selectedLanguage) {
		if (!showLanguageCountries.length || $.inArray(selectedLanguage, showLanguageCountries) === -1) {
			$cache.languageSelectorWrapper.addClass('h-hidden');
		} else {
			$cache.languageSelectorWrapper.removeClass('h-hidden');
		}

		if (urlsOfDisabledCountries.length && $.inArray(selectedLanguage, urlsOfDisabledCountries) !== -1) {
			$cache.languageSelectorWrapper.find('select').prop('disabled', true);
		} else {
			$cache.languageSelectorWrapper.find('select').prop('disabled', false);
		}
	}

	function setGeneralElements(){
		$cache.updateQueryElements.length && updateQueryStrings($cache.updateQueryElements);
	}

	const setSelectedCountry = () => {
		const $currentCountry = $cache.currentCountryCode;

		if ($cache.countryList.length > 0) {
			updateQueryStrings($cache.countryList.find('li a'), 'country');

			if ($cache.headerCountrySelected.length > 0) {
				updateQueryStrings($cache.headerCountrySelected.find('a'), 'country');
			}
		} else {
			updateQueryStrings($cache.nativeCountrySelector.find('option'), 'country');
		}

		$cache.countryList.each((i, el) => {
			const $list = $(el);
			let $selectedItem = $list.find('li[data-countrycode="' + ($currentCountry ? $currentCountry.toLowerCase() : '') + '"]');

			if ($selectedItem.length) {
				$selectedItem.addClass($cache.selectedCountryClass);
			}

			if (!app.util.getConfig('country.showSelectedCountryOnTop')) {
				return;
			}

			if (!$selectedItem.length) {
				const $currentCountryName = app.currentCustomer.getShippingCountryName();
				const $lastItem = $list.find('li[data-countrycode]').last();
				const anchor = $lastItem.find($cache.countryLinkSel);

				$lastItem.addClass($cache.selectedCountryClass).attr('data-countrycode', $currentCountry.toLowerCase());
				anchor.attr('href', anchor.attr('href').replace(/([&?]Country=)\w+/, '$1' + $currentCountry));
				anchor.text($currentCountryName);

				$selectedItem = $lastItem;
			}

			$list.prepend($selectedItem);
		});
	}

	function setSelectedLocale() {
		if ($cache.localeList.length > 0) {
			updateQueryStrings($cache.localeList.find('li a'), 'locale');
		} else {
			updateQueryStrings($cache.nativeLangSelector.find('option'), 'locale');
		}

		var preferredLanguageCookie = $.cookie($cache.preferredLanguageCookieKey);
		var langItem = $cache.localeList.find('li[data-locale=' + preferredLanguageCookie + ']');

		if (!langItem.length && preferredLanguageCookie === 'en') {
			langItem = $cache.localeList.find('li[data-locale=default]');
		}

		langItem.addClass($cache.selectedLocaleClass);
	}

	function updateQueryStrings($anchors, source) {
		var queryMap;

		if (!app.constants.IS_CONTINUE_URL_BANNED_FROM_LOCALE_SELECTOR) {
			queryMap = app.util.getQueryStringParams(app.currentCustomer.getCurrentQueryString(), true);
			queryMap = updateQueryStringsWithSource(queryMap, source);
			queryMap.origin = encodeURIComponent(app.currentCustomer.getCurrentPipeline());
		} else {
			queryMap = {
				origin: encodeURIComponent(defaultOrigin)
			};
		}

		$anchors.each(function(i, el) {
			var $anchor = $(el);
			var currentQueryMap = $.extend({}, queryMap);
			var dataUpdateQuery = $anchor.data('updateQuery');

			if (dataUpdateQuery) {
				currentQueryMap = updateQueryStringsWithSource(currentQueryMap, dataUpdateQuery);
			}

			if($anchor.attr('href')) {
				$anchor.attr('href', app.util.appendParamsToUrl($anchor.attr('href') , currentQueryMap, true));
			} else {
				$anchor.attr('value', app.util.appendParamsToUrl($anchor.attr('value') , currentQueryMap, true));
			}
		});
	}

	function updateQueryStringsWithSource(queryMap, source) {
		queryMap = queryMap || {};
		if (source) {
			if (source === 'locale') {
				delete queryMap.dwcont;

				if (app.util.getConfig('navigation.toggleOnLanguageSwitch')) {
					queryMap[app.util.getConfig('navigation.toggleParamName')] = 1;
				}
			} else if (source === 'country') {
				delete queryMap.dwcont;

				if (app.util.getConfig('navigation.toggleOnCountrySwitch')) {
					queryMap[app.util.getConfig('navigation.toggleParamName')] = 1;
				}
			}
		}
		return queryMap;
	}
	/**
	 * This function gets relevant locale options for language select element depending on selected country
	 * @param {JQuery} $countrySelector - html select element for countries
	 * @param {JQuery} $localesSelector - html select element for locales
	 * @returns {void}
	 */
	function showLocalesForCountry($countrySelector, $localesSelector) {
		var selectedCountry = $countrySelector.val();
		var $submitButton = $countrySelector.closest('form').find('button');

		$submitButton.prop('disabled', true);
		var url = app.util.appendParamsToUrl(app.urls.getLocalesForCountry, { countryCode: selectedCountry });

		app.ajax.load({
			url: url,
			callback: function(data) {
				var localesObject = {};

				try {
					localesObject = JSON.parse(data);
				} catch (e) {
					return false;
				}
				$localesSelector.empty();
				var keys = Object.keys(localesObject);
				var $localesList = $(document.createDocumentFragment());

				for (var i = 0; i < keys.length; i++) {
					var key = keys[i];
					var option = $('<option></option>')
						.attr({
							value: localesObject[key].id
						})
						.text(localesObject[key].name);

					$localesList.append(option);
				}
				$localesSelector.append($localesList);
				$submitButton.prop('disabled', false);
			}
		});
	}

	/*************** app.components.global.languageselector public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.localeselector = {
		// initializations
		init: function() {
			initializeCache();
			initializeDOM();
			initializeEvents();
		},
		changeCountry: changeCountry,
		redirectURL: false
	};
})((window.app = window.app || {}), jQuery);
