(function(app, $) {
	var $cache = {},
		fancyBoxTimeout;

	function initCache() {
		$cache = {
			document: $(document),
			window: $(window),
			wishlistBlock: $('.js-wishlistblock'),
			wishlistQuantity: $('.js-wishlist-quantity'),
			pdpProductIDSel: '.js-product_id:first',
			addToStatusSel: '.js-addtostatus',
			wishlistCls: 'b-product_add_wishlist',
			cartCls: 'b-product_add_event'
		};
	}

	function initEvents() {
		$cache.window.on('orientationchange', function(){
			var cartFancyBox = $('.' + $cache.cartCls);
			if(cartFancyBox.length){
				clearTimeout(fancyBoxTimeout);
				fancyBoxTimeout = setTimeout(function(){
					addToStatusCorrection($cache.cartCls);
				}, 500);
			}
		});
		
		$cache.window.on('appleypay.privacy.revealed', function(e, privacyBlock, firstOpened){
			privacyBlock = $(privacyBlock);
			
			if(firstOpened && privacyBlock.closest('.' + $cache.cartCls).length){
				addToStatusCorrection($cache.cartCls);
			}
		});
		
		$cache.document.on('fancy.mobile.correction', function(e, wrapCSS){
			addToStatusCorrection(wrapCSS);
		});
		
		$cache.document.on('product.added', function(e, container, info) {
			var isPopupShown = !app.preferences.isMiniCartEnabled && app.preferences.productShowPopup;

			if ('added' === e.namespace && isPopupShown) {
				var queryParams = {
					addedTo: 'cart',
					productID: $($cache.pdpProductIDSel).val()
				};
				
				if (typeof info === 'object') {
					queryParams.success = info.success;
					queryParams.errorMessage = info.errorMessage;
				}
				
				showResultPopup('product', queryParams);
			}
		});

		$cache.document.on('wishlist.added', function(e, quantity, hidePopup) {
			var isPopupShown = hidePopup !== true;

			if (app.page.ns === 'checkout') {
				isPopupShown = isPopupShown && app.preferences.productCheckoutShowPopup && !$cache.wishlistBlock.length;
			} else {
				isPopupShown = isPopupShown && app.preferences.productShowPopup;
			}

			if (isPopupShown) {
				var queryParams = {
					addedTo: 'wishlist',
					productID: $($cache.pdpProductIDSel).val()
				};

				showResultPopup('wishlist', queryParams);
			}

			if (quantity) {
				$cache.wishlistQuantity.text(quantity);
			}
		});
	}

	function addToStatusCorrection(wrapCSS) {
		var fancyBox = $('.' + wrapCSS);
		var addToStatusBox = fancyBox.find($cache.addToStatusSel);

		if (wrapCSS === $cache.cartCls && addToStatusBox.length && addToStatusBox.css('position') === 'absolute') {
			var extraPadding = 10;
			var fancyBoxHeight = fancyBox.outerHeight();
			var addToStatusBoxHeight = addToStatusBox.height();

			if (addToStatusBoxHeight > fancyBoxHeight) {
				addToStatusBox.css('top', parseInt(fancyBoxHeight / 2 + (addToStatusBoxHeight - fancyBoxHeight) / 2 + extraPadding) + 'px');
			} else {
				addToStatusBox.css('top', '50%');
			}
		}
	}

	function showResultPopup(type, queryParams) {
		var url = app.util.appendParamsToUrl(app.urls.productOnAddTo, queryParams);

		if (url) {
			app.fancybox.open(url, {
				type: 'ajax',
				width: '100%',
				margin: 0,
				padding: 0,
				wrapCSS: type === 'wishlist' ? $cache.wishlistCls : $cache.cartCls,
				autoSize: true,
				onReady: function() {
					if (this.wrap) {
						this.wrap.css('visibility', 'hidden');
					}
				},
				beforeShow: false,
				afterShow: function() {
					$cache.document.trigger('fancy.mobile.added');
					$cache.document.trigger('fancy.mobile.correction', this.wrapCSS);
				},
				afterClose: function() {
					$cache.document.trigger('fancy.mobile.closed');
				}
			});
		}
	}

	app.components = app.components || {};
	app.components.product = app.components.product || {};
	app.components.product.mobile = {
		init: function() {
			initCache();
			initEvents();
		}
	};
})((window.app = window.app || {}), jQuery);
