;( function(app) {
	var $cache,
		dataGetters,
		providerSchemeConfig,
		fillProviderProductsConfig,
		fillRecommendationBlockHandler,
		quickViewButtonHandler;

	providerSchemeConfig = {
		DEMANDWARE: {
			CART: {
				params: ['maxrecommendations', 'productsincartids', 'slotcontentproductsids']
			},
			WISHLIST: {
				params: ['slotcontentproductsids']
			},
			PROFILE: {
				params: ['slotcontentproductsids']
			}
		}
	};
	

	fillProviderProductsConfig = {
		DEMANDWARE: function(
			$block,
			recommendationsProvider,
			providerData,
			fillRecommendationBlockHandler,
			dataGetters,
			quickViewButtonHandler
		) {
			var typeOptions = {
				DEMANDWARELASTVISITED: {
					url: app.urls.getDWLastVisited,
					isProviderDataRequired: false,
					restrictedZones: []
				},
				DEMANDWARECROSSSELL: { url: app.urls.getDWRecommendations },
				DEMANDWAREUPSELL: { url: app.urls.getDWRecommendationsUpSell },
				DEMANDWAREOTHERS: { url: app.urls.getDWRecommendationsOthers },
				DEMANDWAREALSOVIEW: {
					url: app.urls.getDWRecommendationsAlsoView
				},
				DEMANDWAREALSOBOUGHT: {
					url: app.urls.getDWRecommendationsAlsoBought
				},
				DEMANDWARESHOPTHELOOK: {
					url: app.urls.getDWRecommendationsShopTheLook
				},
				DEMANDWARETOPSELL: { url: app.urls.getDWRecommendationsTopSell }
			};
			typeOptions[recommendationsProvider.type]['restrictedZones'] = 'restrictedZones' in typeOptions[recommendationsProvider.type] ? typeOptions[recommendationsProvider.type]['restrictedZones'] : ['CART', 'EMPTY_CART', 'NOHITS', 'WISHLIST', 'PROFILE'];
			getDemandwareRecommendation(
				$block,
				recommendationsProvider,
				providerData,
				fillRecommendationBlockHandler,
				dataGetters,
				quickViewButtonHandler,
				typeOptions[recommendationsProvider.type]
			);
		}
	};
	
	/**  app.recommendations.customconfig namespace */
	app.recommendations = app.recommendations || {};
	app.recommendations.customconfig = {
		getDataGetters : function(){
			return dataGetters;
		},
		getDataGetters : function(){
			return dataGetters;
		},
		getProviderSchemeConfig : function(){
			return providerSchemeConfig;
		},
		getFillProviderProductsConfig : function(){
			return fillProviderProductsConfig;
		},
		getCache : function(){
			return $cache;
		},
		getFillRecommendationBlockHandler : function(){
			return fillRecommendationBlockHandler;
		},
		getQuickViewButtonHandler : function(){
			return quickViewButtonHandler;
		}
		
	};
}(window.app = window.app || {}, jQuery));
