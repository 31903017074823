// app.components.global.all 
(function (app, $) {
	/*************** app.components.global.all public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.all = {
		init : function (params) {
			var inititem = '', initlist = params.initlist, loadedNSs = [], notLoadedNSs = [];
			for (var i = 0, length = params.initlist.length; i < length; i++) {
				inititem = initlist[i];
				var duplicatedWithNamespace = inititem == app.page.ns;

				if (app.hasOwnProperty(inititem) && app[inititem].hasOwnProperty('init') && !duplicatedWithNamespace) {
					app[initlist[i]].init();
					loadedNSs.push(initlist[i]);
				} else if(duplicatedWithNamespace) {
					console.debug('Global namespace  was duplicated for:', inititem);
				} else {
					notLoadedNSs.push('app.' + [initlist[i]]);
				}
			}
			console.debug('Initialized global namespaces: ', loadedNSs);
			if (notLoadedNSs.length){
				console.warn('Not defined global namespaces: ', notLoadedNSs);
			}
		}
	};
}(window.app = window.app || {}, jQuery));