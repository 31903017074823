/**
 * @class app.owlcarousel
 */

(function(app, $) {
	var $cache = {
			promoBanner: $('.js-header-promo-container')
		},
		carouselSettings,
		carouselWrapper = '.js-owl_carousel',
		carouselWrapperClass = 'js-owl_carousel',
		mobileCarouselWrapper = '.js-mobile-owl_carousel',
		carouselNavItems = '.js-owl_carousel_nav',
		carouselOneItem = 'b-owl_carousel-one_item',
		navigationPosition = 'slide', //data-index attribute to store Number - position to navigate to.
		carouselsGroup = '.js-owl_carousels_group',
		singleClass = 'b-owl_carousel-item_single',
		carouselItemsSel = '.b-owl_carousel-item',
		carouselNavigationControls = '.b-owl_carousel-nav_controls',
		carouselNavigation = '.b-owl_carousel-nav';
	 
	function getCurrentWindowWidth () {
		return $(window).width();
	}
		
	function initCache () {
		$cache.carouselsGroup = $(carouselsGroup);
		$cache.carousels = $cache.carouselsGroup.find(carouselWrapper);
	}

	function defineElementBehaviourOnTouchScreen() {
		$(carouselNavItems).css('touch-action', 'none');
		$(carouselNavigation).css('touch-action', 'none');
		$('.owl-stage-outer').css('touch-action', 'pan-y');
	}

	function initNavigation() {
		$cache.carouselsGroup.on('click', carouselNavItems, function(e) {
			var $parent = $(e.delegateTarget);
			var $this = $(this);
			var $allNavItems = $parent.find(carouselNavItems);
			var $currentCarousel = $parent.find(carouselWrapper).data('owlCarousel');

			$allNavItems.removeClass('m-active');
			$this.addClass('m-active');
			if (typeof $this.data(navigationPosition) == 'number') {
				$currentCarousel.to($this.data(navigationPosition) - 1, 1, true);
			} else if ($this.data(navigationPosition) == 'prev') {
				$currentCarousel.prev();
			} else {
				$currentCarousel.next();
			}

			$currentCarousel._plugins.autoplay.destroy();
		});

		$cache.carousels.on('changed.owl.carousel', function(e) {
			var navigationItems = $(e.currentTarget)
				.closest(carouselsGroup)
				.find(carouselNavItems);

			if (!navigationItems.length) return;

			navigationItems.removeClass('m-active');

			var currentItemIndex = e.item.index;

			if (e.relatedTarget.settings.loop) {
				var lastItemIndex = navigationItems.length + 2;
				currentItemIndex = currentItemIndex == lastItemIndex ? 0 : currentItemIndex - 2;
			}

			navigationItems.eq(currentItemIndex).addClass('m-active');
		});

		// Initialize navigation on custom dot container if it defined
		// Both, slider container & navigation block must be inside carouselGroup block
		if (carouselSettings && carouselSettings.dotsContainer) {
			$cache.carouselsGroup.on('click', '.' + carouselSettings.dotClass, function() {
				$cache.carousels.trigger('to.owl.carousel', [$(this).index(), carouselSettings.navSpeed]);
			});
		}

		// Initialize slide changing by click event on slide element
		if (carouselSettings && carouselSettings.slideClickNext) {
			$(carouselWrapper).on('click', '.' + carouselSettings.activeClass, function(e) {
				$(e.delegateTarget)
					.data('owlCarousel')
					.next();
			});
		}
	}

	// Documentation can be find here:
	// http://www.owlcarousel.owlgraphic.com/docs/api-options.html
	// http://www.owlcarousel.owlgraphic.com/docs/api-classes.html
	// http://www.owlcarousel.owlgraphic.com/docs/api-events.html

	var settings = {
		// Most important owl features
		// The number of items you want to see on the screen.
		// Type: Number. Default: 3
		items: 1,

		// Minimum number of items for which carousel is inited
		minItemsInit: 0,

		// margin-right(px) on item.
		// Type: Number. Default: 0.
		margin: 0,

		// Inifnity loop. Duplicate last and first items to get loop illusion.
		// Type: Boolean. Default: false.
		loop: false,

		// Center item. Works well with even an odd number of items.
		// Type: Boolean. Default: false
		// center: false,

		// Mouse drag enabled.
		// Type: Boolean. Default: true.
		mouseDrag: false,

		// Touch drag enabled.
		// Type: Boolean. Default: true.
		touchDrag: true,

		// Stage pull to edge.
		// Type: Boolean. Default: true
		pullDrag: true,

		// Item pull to edge.
		// Type: Boolean. Default: false
		freeDrag: false,

		// Padding left and right on stage (can see neighbours).
		// Type: Number. Default: 0
		stagePadding: 0,

		// Merge items. Looking for data-merge='{number}' inside item..
		// Type: Boolean. Default: false
		merge: false,

		// Fit merged items if screen is smaller than items value.
		// Type: Boolean. Default: true
		mergeFit: true,

		// Set non grid content. Try using width style on divs.
		// Type: Boolean. Default: false
		autoWidth: false,

		// Start position or URL Hash string like '#id'.
		// Type: Number/String. Default: 0
		startPosition: 0,

		// Listen to url hash changes. data-hash on items is required.
		// Type: Boolean. Default: false
		URLhashListener: false,

		// Show next/prev buttons.
		// Type: Boolean. Default: false
		nav: false,

		// Go to first/last.
		// Type: Boolean. Default: true
		navRewind: false,

		// HTML allowed.
		// Type: Array. Default: ['next', 'prev']
		navText: ['next', 'prev'],

		// Show next slide by clicking on current slide
		// Type: Boolean. Default: false
		slideClickNext: false,

		// Navigation slide by x. 'page' string can be set to slide by page.
		// Type: Number/String. Default: 1
		slideBy: 1,

		// Show dots navigation.
		// Type: Boolean. Default: true
		dots: false,

		// Show dots each x item.
		// Type: Number/Boolean. Default: false
		dotsEach: false,

		// Lazy load images. data-src and data-src-retina for highres. Also load images into background inline style if element is not <img>
		// Type: Boolean. Default: false
		lazyLoad: false,

		// lazyContent was introduced during beta tests but i removed it from the final release due to bad implementation. It is a nice options so i will work on it in the nearest feature.
		// Type: Boolean. Default: false
		lazyContent: false,

		// Autoplay.
		// Type: Boolean. Default: false
		autoplay: false,

		// Autoplay interval timeout.
		// Type: Number. Default: 5000
		autoplayTimeout: 5000,

		// Pause on mouse hover.
		// Type: Boolean. Default: false
		autoplayHoverPause: false,

		// Speed Calculate. More info to come..
		// Type: Number. Default: 250
		// smartSpeed: 250,

		// Speed Calculate. More info to come.. Don't touch it for now
		// Type: Number. Default: 100.
		// fluidSpeed: 100,

		// autoplay speed.
		// Type: Number/Boolean. Default: false
		// autoplaySpeed: false,

		// Navigation speed.
		// Type: Number/Boolean. Default: false
		navSpeed: false,

		// Pagination speed. Don't touch it for now
		// Type: Boolean. Default: Number/Boolean
		dotsSpeed: false,

		// Drag end speed.
		// Type: Number/Boolean. Default: false
		dragEndSpeed: false,

		// Enable callback events.
		// Type: Boolean. Default: true
		callbacks: true,

		// Object containing responsive options. Can be set to false to remove responsive capabilities.
		// Type: Object. Default: empty object
		responsive: {},

		// CSS3 animation out.
		// Type: String/Bolean. Default: false
		animateOut: false,

		// CSS3 animation in.
		// Type: String/Bolean. Default: false
		animateIn: false,

		// Easing for CSS2 $.animate.
		// Type: String. Default: swing
		fallbackEasing: 'swing',

		// Callback to retrieve basic information (current item/pages/widths). Info function second parameter is Owl DOM object reference.
		// Type: Function. Default: false
		// info: false,

		// Use it if owl items are deep nasted inside some generated content. E.g 'youritem'. Dont use dot before class name.
		// Type: String/Class. Default: false
		// nestedItemSelector: false,

		// DOM element type for owl-item.
		// Type: String. Default: div
		// itemElement: 'li',

		// DOM element type for owl-stage.
		// Type: String. Default: div
		// stageElement: 'ul',

		// Set your own container for nav.
		// Type: String/Class/ID/Bolean. Default: false
		// navContainer: false,

		// Set your own container for nav.
		// Type: String/Class/ID/Bolean. Default: false
		dotsContainer: false,

		// CSS Styles
		// Theme Class.
		// Type: Class/String. Default: owl-theme
		themeClass: 'b-owl_carousel-theme',

		// Base element Class.
		// Type: Class/String. Default: owl-carousel
		baseClass: 'b-owl_carousel',

		// Item Class.
		// Type: Class/String. Default: owl-item
		itemClass: 'b-owl_carousel-item',

		// Center Class.
		// Type: Class/String. Default: center
		centerClass: 'b-owl_carousel-center',

		// Active Class.
		// Type: Class/String. Default: active
		activeClass: 'b-owl_carousel-item_active',

		// Nav container Class.
		// Type: Class/String. Default: owl-nav
		navContainerClass: 'b-owl_carousel-nav',

		// Nav buttons Classes.
		// Type: Array/Class/String. Default: ['owl-prev','owl-next']
		navClass: ['b-owl_carousel-nav_prev', 'b-owl_carousel-nav_next'],

		// Controls Class - wrapper for navs and dots.
		// Type: Class/String. Default: owl-controls
		controlsClass: 'b-owl_carousel-nav_controls',

		// Dot Class.
		// Type: Class/String. Default: owl-dot
		dotClass: 'b-owl_carousel-nav_dot',

		// Dots Class - Container for dots..
		// Type: Class/String. Default: owl-dots
		dotsClass: 'b-owl_carousel-nav_dots',

		// Auto height Class.
		// Type: Class/String. Default: owl-height
		autoHeightClass: 'b-owl_carousel-height',

		// Callbacks -
		beforeUpdate: function (element) {
			element.trigger('owl.beforeUpdate');
		},
		afterUpdate: function (element) {
			element.trigger('owl.afterUpdate');
		},
		beforeInit: function (element) {
			element.trigger('owl.beforeInit');
		},
		afterInit: function (element) {
			element.trigger('owl.afterInit', this);
		},
		beforeMove: false,
		afterMove: false,
		afterAction: false,
		startDragging: false,
		afterLazyLoad: false,

		// Vertically center navigation to. Selector to which navigation arrow will vertically center (must be inside the carousel, if inside the slide, first one will be used)
		// Type: Class/String. Default: false
		vCenterNavigationTo: false
	};

	function getNumberOfConfigItemsOnCurrentResolution (dataSettings) {
		var numberOfConfigItemsOnCurrentResolution = dataSettings.items ? dataSettings.items : settings.items;

		if (!!dataSettings.responsive) {
			var carouselBreakPointsSorted = Object.keys(dataSettings.responsive).sort(function(a, b) {
				return a - b;
			});

			for (var i = 0, length = carouselBreakPointsSorted.length; i < length; i++) {
				if (getCurrentWindowWidth() >= carouselBreakPointsSorted[i] * 1) {
					numberOfConfigItemsOnCurrentResolution = dataSettings.responsive[carouselBreakPointsSorted[i]].items * 1;
					continue;
				} else {
					break;
				}
			}
		}

		return numberOfConfigItemsOnCurrentResolution;
	}

	function showExtraArrows(dataSettings, nestedItems) {
		// Hide owl carousel's arrows, when number of products  less then number of visible config items,
		var numberOfConfigItemsOnCurrentResolution = getNumberOfConfigItemsOnCurrentResolution(dataSettings);

		if (nestedItems.length <= numberOfConfigItemsOnCurrentResolution) {
			return false;
		}
		
		return true;
	}

	function initCarousel (customSettings) {
		var $carousel = $(this),
			dataSettings = typeof customSettings !== 'undefined' && customSettings ? customSettings : ($carousel.data('settings') ? $carousel.data('settings') : {}),
			nestedItems = dataSettings.nestedItemSelector
				? $carousel.find('.' + dataSettings.nestedItemSelector)
				: $carousel.children();
		if (
			!dataSettings['startPosition'] &&
			dataSettings['startPosition'] !== 0 &&
			$carousel.hasClass('js-carousel_start_position')
		) {
			dataSettings['startPosition'] = getStartPosition($carousel);
		}

		carouselSettings = $.extend({}, settings, dataSettings);
		if (!$carousel.data('owlCarousel')) {
			if (nestedItems.length >= carouselSettings.minItemsInit) {
				$carousel.owlCarousel(carouselSettings);
			} else {
				$carousel.removeClass(carouselWrapperClass);
			}
		}

		// set onclick events for carousel links and areas
		if ($carousel.hasClass('js-carousel_start_position')) {
			$carousel.find('a, area').on('click', function (e) {
				e.preventDefault();
				var currentInstance = $carousel.data('owlCarousel');
				if (currentInstance && (currentInstance['_current'] || currentInstance['_current'] === 0)) {
					setStartPosition($carousel, currentInstance['_current'] - 2);
				}
				app.page.redirect($(this).attr('href'));
				return false;
			});
		}

		// Hide owl carousel's arrows, when number of products  less then number of visible config items
		$carousel.on('refreshed.owl.carousel', function () {
			var configNav = dataSettings.nav ? dataSettings.nav : settings.nav;
			if (configNav && dataSettings.nestedItemSelector) {
			var showCarouselNavigation = showExtraArrows(dataSettings, nestedItems);
				var carouselArrows = $(this)
					.children(carouselNavigationControls)
					.children(carouselNavigation)
					.children();
				showCarouselNavigation ? carouselArrows.show() : carouselArrows.hide();
			}
		});

		// Vertically center navigation controls to a specific carousel element instead of the whole carousel
		var vCenterNavigationTo = carouselSettings.vCenterNavigationTo;
		if (vCenterNavigationTo) {
			var navClassSelector = carouselSettings.navClass.map((c) => `.${c}`).join(',');

			var vCenterNavigation = function() {
				var $centerTarget = $carousel.find(vCenterNavigationTo);
				if ($centerTarget.length) {
					var centerTargetTopOffset = $centerTarget.offset().top - $centerTarget.parent().offset().top;
					$carousel.find(navClassSelector).css({
						top: centerTargetTopOffset + $centerTarget.height() / 2
					});
				}
			};

			$carousel.on('resized.owl.carousel', vCenterNavigation);

			vCenterNavigation();
		}

		defineElementBehaviourOnTouchScreen();
	}

	/**
	 * Gets carousel start position from cookies
	 * @input {Object} Carousel
	 * @return {Number} Start position
	 **/
	function getStartPosition (carousel) {
		var carouselName = carousel.data('carouselname'),
			startPosition = 0,
			slidersStartPositions = $.cookie('slidersStartPositions');
		if (carouselName && slidersStartPositions && slidersStartPositions.indexOf(carouselName) > -1) {
			startPosition = slidersStartPositions.substr(
				slidersStartPositions.indexOf(carouselName) + carouselName.length + 1,
				2
			);
			if (startPosition[1] == ';') {
				startPosition = startPosition[0];
			}
		}
		return startPosition;
	}

	/**
	 * Sets carousel start position into cookies
	 * @input {Object} Carousel
	 * @input {Number} Start position to set
	 * @return void
	 **/
	function setStartPosition (carousel, pStartPosition) {
		var slidersStartPositions = $.cookie('slidersStartPositions') || '',
			carouselName = carousel.data('carouselname');
		if (slidersStartPositions && slidersStartPositions.indexOf(carouselName) > -1) {
			var startPositionsArray = slidersStartPositions.split(';');
			startPositionsArray[$.inArray(carouselName + ':' + getStartPosition(carousel), startPositionsArray)] =
				carouselName + ':' + pStartPosition;
			slidersStartPositions = startPositionsArray.join(';');
		} else {
			slidersStartPositions += carouselName + ':' + pStartPosition + ';';
		}
		$.cookie('slidersStartPositions', slidersStartPositions);
	}

	function initAllCarousels () {
		// add specific class if only one item in carousel
		$(carouselWrapper).on('initialized.owl.carousel', function (e) {
			if (e.page.count == 1) {
				$(carouselWrapper).addClass(singleClass);
			}
			$(this).find(carouselItemsSel).length <= 1 ? $(this).addClass(carouselOneItem) : '';
		});
		$(this)
			.find(carouselWrapper)
			.each(function(){
				initCarousel.call(this);
			});
	}

	function initMobileCarousels () {
		$(this)
			.find(mobileCarouselWrapper)
			.each(function(){
				initCarousel.call(this);
			});
		if ($cache.promoBanner.data('settings')) {
			// get the max. height of item
			$cache.promoBanner.on('initialized.owl.carousel changed.owl.carousel', function () {
				var maxHeight = 0;
				$(this)
					.find(carouselItemsSel)
					.each(function () {
						var thisHeight = parseInt($(this).height());
						maxHeight = maxHeight >= thisHeight ? maxHeight : thisHeight;
					});
				$(this)
					.find('.owl-stage-outer')
					.css('height', maxHeight + 'px');
			});
		}
	}

	app.owlcarousel = {
		init: function () {
			initCache();
			initAllCarousels.call(document);
			if (app.device.isMobileView()) {
				initMobileCarousels.call(document);
			}
			initNavigation();
		},

		getInstance: function (instanceID) {
			if (!instanceID) {
				return;
			}
			return $(document)
				.find(instanceID)
				.data('owlCarousel');
		},

		initCarousel: function ($carousel, customSettings) {
			initCarousel.call($carousel, customSettings);
		}
	};
})((window.app = window.app || {}), jQuery);
