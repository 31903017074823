/**
 * Components mobile config specific
 **/
( function(app, $) {

	var componentsConfig = {};

	/*************** publish app.components.config.mobile.specific***************/
	app.componentsconfig = app.componentsconfig || {};
	app.componentsconfig.mobile = app.componentsconfig.mobile || {};
	app.componentsconfig.mobile.specific = componentsConfig;
}(window.app = window.app || {}, jQuery));
