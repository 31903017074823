/**
 * @class app.page
 */
(function (app, $) {

	app.page = {
		title : "",
		type : "",
		setContext : function (o) {
			$.extend(app.page, o);
		},
		params : app.util.getQueryStringParams(window.location.search.substr(1)),
		redirect : function(newURL) {
			var t=setTimeout("window.location.href='"+newURL+"'",0);
		},
		refresh : function(hashRequired) {
			window.location.reload(!!hashRequired);

		},
		addElement : function(key, val) {
			elements[key] = val;
			return this;
		},
		getElement : function(key) {
			return elements[key] || {};
			
		},
		setContexAfterAjaxCall : function () {
			var $pcElement = $('.page_context_data').last();
			if ($pcElement.length>0){
				try {
					var pageContexData = $pcElement.html().replace(/<!--(.*?)-->/gm, "$1"),
						pageContexDataObject = pageContexData?JSON.parse(pageContexData):{};	
					app.page.setContext(pageContexDataObject);					
				} catch(e){
					console.error(e.message);
				}
				$pcElement.remove();
			}
		},
		isMobileScreenSize : false
	};
}(window.app = window.app || {}, jQuery));