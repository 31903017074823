/**
 * @class search.js-refinement
 */

(function(app, $) {
	/**
	 * @function
	 * @description Initializes the DOM of the Product Landing Page
	 */

	const $cache = {};
	const tabs = {};
	let configs = {
		closeOnClickOutside: false,
		attachClassOnBody: false,
		updateOnSelect: true
	};

	function initializeConfigs(params) {
		if (params) {
			configs = $.extend(true, {}, configs, params);
		}
	}

	function initializeCache() {
		$cache.document = $(document);
		$cache.body = $('body');
		$cache.sortByWrapper = $('#js-sorting-tab, .js-sorting-tab');
		$cache.filterWrapper = $('.js-filter-tab_content');
		$cache.sortOptions = $('.js_sorting-option');
		$cache.appliedFilters = $cache.filterWrapper.find('form :selected');
		$cache.refinementSelectSel = '.js-refinement-select option:selected';
		$cache.refinementCategorySelect = $('.js-refinement-category-select');
		$cache.refinementCntrSel = '.js-refinement';
		$cache.refinementCounterSel = '.js-refinement-counter';
		$cache.clearAllFilters = '.js-refinebar_reset';
		$cache.filterTabSel = '.js-filter-tab';
		$cache.filterTabSwitcherSel = '.js-filter-tab-switcher';
		$cache.tabClose = '.js-tab-close';
		$cache.searchTopBarOpenClass = 'b-search_actions_top_bar--open';
	}

	function initializeRefinementsCache() {
		$cache.refinementInputs = $('.js-refinement-select');
		$cache.refinementInputsCbox = $('.js-refinement-checkbox');
		$cache.clearAllFilters = $('.js-refinebar_reset');
	}

	function styleRefinementElement() {
		$cache.refinementInputs.each(function() {
			if ($(this).val() != '*') {
				$(this).addClass('b-refinement-list--selected');
			} else {
				$(this).removeClass('b-refinement-list--selected');
			}
		});
	}

	function resetRefinementElement() {
		$cache.refinementInputs.each(function() {
			$(this).removeClass('b-refinement-list--selected');
		});
	}

	function initializeDom() {
		const clickOutside = (e) => {
			const $target = $(e.target);
			const $tabs = $('.js-search_actions_top_bar');

			if (!$target.is($tabs) && !$tabs.has($target).length) {
				$cache.document.trigger('refinements-update');
				$cache.body.off('click', clickOutside);
			}
		};

		$(document).on('refinements-update', function(e, isClosingFilters) {
			if (configs.attachClassOnBody && $cache.body.hasClass(configs.attachClassOnBody)) {
				$cache.body.removeClass(configs.attachClassOnBody);
			}

			if (!isClosingFilters) {
				app.components.search.mobile.reinit();
			}

			styleRefinementElement();
			$('.js-search_actions_top_bar').tabs({
				collapsible: true,
				active: false,
				activate: function(event, ui) {
					ui.oldPanel.toggleClass($cache.searchTopBarOpenClass);
					ui.newPanel.toggleClass($cache.searchTopBarOpenClass);

					const isClosed = Object.keys(ui.newPanel).length === 0;

					if (!isClosed) {
						if (configs.attachClassOnBody && !$cache.body.hasClass(configs.attachClassOnBody)) {
							$cache.body.addClass(configs.attachClassOnBody);
						}
						if (configs.closeOnClickOutside) {
							setTimeout(() => {
								$cache.body.on('click', clickOutside);
							}, 50);
						}
					} else {
						if (configs.attachClassOnBody && $cache.body.hasClass(configs.attachClassOnBody)) {
							$cache.body.removeClass(configs.attachClassOnBody);
						}
						if (configs.closeOnClickOutside) {
							$cache.body.off('click', clickOutside);
						}
					}
				}
			});
		});
	}

	function initializeEvents() {
		/**
		 * 	Sort by functional
		 */
		$cache.sortByWrapper.on('change', 'input:checkbox', function() {
			$cache.sortByWrapper
				.find('input:checkbox')
				.not(this)
				.prop('checked', false);
		});

		$cache.sortOptions.on('click', function(e) {
			e.preventDefault();
			$cache.sortOptions.removeClass('checked');
			var $this = $(this),
				url = $this.attr('href'),
				newUrl = '';
			if (url) {
				app.search.updateProductListing(url);
				$this.addClass('checked');
			} else {
				newUrl = app.util.removeParamFromURL(window.location.href, 'srule');
				newUrl = app.util.removeParamFromURL(newUrl, 'start');
				newUrl = app.util.removeParamFromURL(newUrl, 'sz');
			}
		});

		$cache.sortByWrapper.on('submit', 'form', function() {
			var $this = $(this),
				url = $(this)
					.find('input:checked')
					.attr('value'),
				newUrl = '';
			if (url) {
				app.search.updateProductListing(url);
			} else {
				newUrl = app.util.removeParamFromURL(window.location.href, 'srule');
				newUrl = app.util.removeParamFromURL(newUrl, 'start');
				newUrl = app.util.removeParamFromURL(newUrl, 'sz');
				app.search.updateProductListing(newUrl, false);
			}
			$cache.document.trigger('mobile.filter.submit');
			return false;
		});

		$cache.sortByWrapper.on('click', 'button[type=reset]', function() {
			$cache.sortByWrapper.find('input:checked').prop('checked', false);
			resetRefinementElement();
			return false;
		});

		/**
		 * 	 Filter functional
		 */
		$cache.filterWrapper.on('submit', 'form', function(e) {
			var $this = $(this);
			$this.addClass('js-form-submitting');
			app.search.updateProductListing(tabs[$this.data('active-tab-class')].url, false);
			styleRefinementElement();
			e.stopImmediatePropagation();
			$cache.document.trigger('mobile.filter.submit');
			return false;
		});

		$cache.filterWrapper.on('click', 'button[type=reset]', function() {
			$(this)
				.closest("form[name='Product-Filter-Options']")
				.find('select')
				.children()
				.removeAttr('selected');
			$cache.document.trigger('mobile.filter.reset');
			return false;
		});

		$cache.document.on('click', $cache.filterTabSwitcherSel, function() {
			var $this = $(this),
				dropdownCls = $this.data('tab-class');

			dropdownCls && $('.' + dropdownCls).show();
			$cache.filterWrapper.find('form').data('active-tab-class', dropdownCls);

			$this.siblings($cache.filterTabSwitcherSel).each(function() {
				var dropdownCls = $(this).data('tab-class');
				dropdownCls && $('.' + dropdownCls).hide();
			});
		});
		
		
		/**
		 * 	 Extra functional
		 */

		$cache.document.on('click', $cache.tabClose, function(){
			$cache.document.trigger('refinements-update', { isClosingFilters: true });
		});
	}

	function initializeRefinementsEvents() {
		var isSafariBrowser = app.device.browser().safari,
			eventToListen = 'change';
		if (isSafariBrowser && app.device.currentDevice() === 'mobile') {
			eventToListen = 'focusout';
		}

		$cache.refinementInputs.on(eventToListen, function() {
			var $this = $(this),
				curTabCls = $this.closest($cache.refinementCntrSel).data('tab-class'),
				searchUrl = _getSearchUrl(curTabCls, $cache.filterWrapper.find('form :selected'));
			if ($this.find('option:selected').data('cgid')) {
				if ( searchUrl.indexOf('?') !== -1 ){
					searchUrl = searchUrl.replace(/(.*)(\?.*)/, $this.find('option:selected').val());
				} else{
					searchUrl = $this.find('option:selected').val();
				}
			}
			tabs[curTabCls].url = searchUrl;
			searchUrl = app.util.appendParamToURL(searchUrl, 'refinementsOnly', 1);
			searchUrl = app.util.appendParamToURL(searchUrl, 'format', 'ajax');

			if (configs.updateOnSelect) {
				$.get(searchUrl).done(function(data) {
					$cache.filterWrapper.html(data);
					app.components.search.mobile.reinitRefinements();
				});
			} else {
				$this.find('option[value="*"]').prop('selected', false);
				if (!$this.find('option:selected').length) {
					$this.find('option[value="*"]').prop('selected', true);
				}
			}

			return false;
		});

		$cache.refinementInputsCbox.on("click", function() {
			var $this = $(this),
				curTabCls = $this.closest($cache.refinementCntrSel).data('tab-class'),
				searchUrl = _getSearchUrl(curTabCls, $cache.filterWrapper.find('form :checked'));

			tabs[curTabCls].url = searchUrl;
			searchUrl = app.util.appendParamToURL(searchUrl, 'refinementsOnly', 1);
			searchUrl = app.util.appendParamToURL(searchUrl, 'format', 'ajax');

			if (configs.updateOnSelect) {
				$.get(searchUrl).done(function(data) {
					$cache.filterWrapper.html(data);
					app.components.search.mobile.reinitRefinements();
				});
			}
		});

		if (isSafariBrowser) {
			var option = $('<option>').attr('disabled', 'disabled');
			$cache.refinementInputs.prepend(option);
		}

		$cache.clearAllFilters.on('click', function() {
			var activeTabClass = $(this).closest('form').data('active-tab-class')
			var tabFilters = getTabRefinements(activeTabClass);
			var url = window.location.href;
			var params = app.util.getUri(url).queryParams;

			for (key in params) {
				if (key.indexOf('prefn') !== -1 && tabFilters.indexOf(params[key]) !== -1) {
					url = app.util.removeParamsFromURL(url, [key, 'prefv' + key.slice(-1)]);
				}
			}

			if (tabFilters.indexOf('pmin') !== -1 && tabFilters.indexOf('pmax') !== -1) {
				url = app.util.removeParamsFromURL(url, ['pmin', 'pmax']);
			}

			if ($cache.refinementCategorySelect.length) {
				url = app.util.removeParamFromURL(url, 'cgid');
				url = app.util.appendParamToURL(url, 'cgid', $cache.refinementCategorySelect.data('parent-category'));
			}

			app.search.updateProductListing(url, false);
			$cache.document.trigger('mobile.filter.reset');
			return false;
		});
	}

	function _getSearchUrl(tabCls, $applied) {
		tabs[tabCls].url = $cache.filterWrapper.find('form').attr('action');
		var result = getTabActiveRefinements(tabCls, $applied),
			counter = 1,
			queryObj = {},
			notEncode = app.util.isEncodedUri(tabs[tabCls].url);

		for (key in result) {
			if(key === 'pmin' || key === 'pmax') {
				queryObj[key] = result[key];
			} else {
				queryObj['prefn' + counter] = key;
				queryObj['prefv' + counter] = result[key];
				counter++;
			}
		}

		tabs[tabCls].url = app.util.appendParamsToUrl(tabs[tabCls].url, queryObj, notEncode);
		return tabs[tabCls].url;
	}

	function getTabActiveRefinements(tabCls, $applied) {
		var optionCls = tabs[tabCls].optionClass,
			$applied = ($applied && $applied.not('.' + optionCls)) || {},
			$selectedOptions = {},
			$checkedOptions = {},
			result = {};
			
		if ($cache.filterWrapper.first().hasClass($cache.searchTopBarOpenClass)) {
			$cache.filterWrapper = $cache.filterWrapper.first();
		} else {
			$cache.filterWrapper=$cache.filterWrapper.last();
		};
		$selectedOptions = $cache.filterWrapper.find('form .' + optionCls + ':selected'),
		$checkedOptions = $cache.filterWrapper.find('form .' + optionCls + ':checked'),
					
		$selectedOptions = $.merge($selectedOptions, $checkedOptions, $applied);
		
		var currentSrule = $cache.filterWrapper.data("currentSrule");
		var sruleWasChanged = false;
		
		$.each($selectedOptions, function() {
			var option_data = $(this).data(),
				prefn,
				prefv;
			if (option_data && 'prefn' in option_data && 'prefv' in option_data) {
				prefn = option_data.prefn;
				prefv = option_data.prefv;
				if (prefn in result) {
					result[prefn] = result[prefn] + '|' + prefv;
				} else {
					result[prefn] = prefv;
				}
			}
			if(option_data && option_data.pmin && option_data.pmax) {
				result.pmin = parseFloat(option_data.pmin);
				result.pmax = parseFloat(option_data.pmax);
			}
			if (option_data && 'cgid' in option_data) {
				tabs[tabCls].url = app.util.removeParamFromURL(tabs[tabCls].url, 'cgid');
				tabs[tabCls].url = app.util.appendParamToURL(tabs[tabCls].url, 'cgid', option_data.cgid);
			}
			if (option_data && 'srule' in option_data && !sruleWasChanged) {
				tabs[tabCls].url = app.util.appendParamToURL(tabs[tabCls].url, 'srule', option_data.srule);
				$cache.filterWrapper.data("currentSrule", option_data.srule);
				if(option_data.srule !== currentSrule) {
					sruleWasChanged = true;
				}
			} 
		});
		return result;
	}

	function getTabRefinements(tabCls) {
		var optionCls = tabs[tabCls].optionClass;
		var result = [];

		if ($cache.filterWrapper.first().hasClass($cache.searchTopBarOpenClass)) {
			$cache.filterWrapper = $cache.filterWrapper.first();
		} else {
			$cache.filterWrapper=$cache.filterWrapper.last();
		};

		$selectedOptions = $cache.filterWrapper.find('form .' + optionCls);

		$.each($selectedOptions, function () {
			var option_data = $(this).data();
			if (option_data && 'prefn' in option_data) {
				var prefn = option_data.prefn;
				if (result.indexOf(prefn) === -1) {
					result.push(prefn);
				}
			}

			if(option_data && option_data.pmin && option_data.pmax) {
				result.push('pmin', 'pmax');
			}
		});
		return result;
	}

	function initializeFilterCountDom() {
		$($cache.refinementCounterSel).remove();
		$($cache.filterTabSel).append($('<span/>').addClass('b-refinement-counter js-refinement-counter'));
	}

	/**
	 * @function Updates number of filtered values
	 * */
	function updateFilterCount() {
		var count = getAppliedFiltersCount();

		if (count) {
			$($cache.refinementCounterSel)
				.empty()
				.text(count);
		} else {
			$($cache.refinementCounterSel).remove();
		}
	}

	/**
	 * @function Searching selected elements in filter DOM
	 * @return amount of selected values
	 * */
	function getAppliedFiltersCount() {
		var count = 0;

		$($cache.refinementSelectSel).each(function(elem) {
			var $this = $(this);
			if ($this.is(':selected') && $this.data('prefv')) {
				count++;
			}
		}, count);

		return count;
	}

	function initTabsConfigs() {
		var tabsConfigs = app.util.getConfig('filters.tabs'),
			url = window.location.href;
		for (var i = 0, len = tabsConfigs.length; i < len; i++) {
			tabs[tabsConfigs[i].dropdownClass] = {
				url: url,
				optionClass: tabsConfigs[i].optionClass
			};
		}
	}

	app.components = app.components || {};
	app.components.search = app.components.search || {};
	app.components.search.mobile = {
		init: function(params) {
			initializeDom();
			jQuery(document).trigger('refinements-update'); // event handle will call `reinit` method
			initializeConfigs(params);
		},
		reinit: function(params) {
			// reinit all after submit form
			initializeCache();
			initTabsConfigs();
			initializeEvents();
			app.components.search.mobile.reinitRefinements();
			initializeConfigs(params);
		},
		reinitRefinements: function(params) {
			// reinit refinements only after select/unselect refinement option
			initializeRefinementsCache();
			styleRefinementElement();
			initializeRefinementsEvents();
			initializeConfigs(params);
			if (app.preferences.showFilteredAmount) {
				initializeFilterCountDom();
				updateFilterCount();
			}
		}
	};
})((window.app = window.app || {}), jQuery);
