import CSRF from 'oneapp/src/utils/CSRF';

/**
 * @class app.wishlist
 */
(function(app, $) {
	var $cache = {},
		$document = $(document),
		wishlistTableSimpleRowClass = 'b-wishlist_table-item--',
		wishlistTableFirstRowClass = 'b-wishlist_table-item--first',
		initedComponent = false,
		documentEventsInitialized = false,
		methods,
		beforeAddToWishlistFunction = null,
		initialized = false,
		_customizationMemoize = {};

	function initializeCache() {
		$cache = {
			document: $(document),
			addedToWishlistMsg: $('.js-added_to_wishlist'),
			editAddress: $('.js-edit_address'),
			wishlistTable: $('.js-pt_wishlist .js-table-item_list'),
			wishlistFlyout: $('.js-wishlist_flyout_container'),
			wishlistContainer: $('.js-wishlist_flyout_container, .js-pt_wishlist .js-table-item_list'),
			wishlistQty: '.js-wishlist_qty',
			addtoWishListError: $('.js-add_to_wishlist_error'),
			wishlistFlyoutMouseover: $('.js-wishlist_flyout-mouseover'),
			wishlistTooltip: $('.js-header-tooltip'),
			wishlistTooltipValSel: '.js-wishlist-tooltip_value',
			addtoWishListSel: '.js-add_to_wishlist, .js-hp_wishlist-add',
			addtoWishListCopySel: '.js-add_to_wishlist_copy',
			addtoWishListMultiVarSel: '.js-add_to_wishlist-multivar',
			addtoWishListClose: '.js-add_to_wishlist-close',
			productContentSel: '.js-product_content',
			productTileSel: '.js-product_tile',
			errorVariationsSel: '.js-error_variations',
			emptyCartRecommBlockSel: '.js-empty-cart-recommendations-block',
			variantsBlockSel: '.js-product_tile-variations',
			wishlistPopupSel: '.js-wishlist_popup_wrapper',
			popupCloseButton: $('.js-variation-popup-close'),
			fancyboxOverlay: '.fancybox-overlay',
			popupWrapper: 'b-wishlist_popup_wrapper js-wishlist_popup_wrapper',
			popupOverlay: 'b-wishlist_popup_wrapper-overlay',
			errorPopup: $('.js-error-variation-popup'),
			wishListConfirmationContent: $('.js-wishlist-confirmation-popup'),
			cartWishlistBtnClass: 'js-cart_wishlist-btn',
			hiddenCls: 'h-hidden',
			variantsOpenedCls: 'm-variations-opened',
			wishlistAddedClass: 'b-add_to_wishlist--added',
			wishlistBlock: $('.js-wishlistblock'),
			wishlistItem: null,
			pdpQuickviewSel: '.js-pdp_primary_content-quickview',
			isPDPConfigurable: 'configurator' in app.components.product && app.components.product.configurator.isConfigurable,
			customInputSel : 'input[name = customProducts]',
			addtowishlist: 'addtowishlist',
			addToWishlistItemNum: 'addToWishlistItemNum',
			wishlistShowLoginFlyout: 'wishlistShowLoginFlyout',
			cartWishlistBtn: '.js-cart_wishlist-btn',
			updateWishlistAddToCartQuantity: $('.js-update-wishlist-add-to-cart-quantity'),
			wishlistTableListItemSel: '.js-wishlist_table-list_item',
			productQuantitySel: '.js-product_quantity',
			addToWishlistSel: ".js-add_to_wishlist",
			localStorageKey: "wishlistPIDS",
			wishListPIDS: [],
			sid: $.cookie('sid'),
			contentsToParse: [],
			localStorageInitialized: false,
			lastVisitedSel: '.js-last_visited',
			recommendationsBlockSel: '.js-productrecommendation_general',
			wishlistDeleteForm: $('.js-wishlist-delete-form'),
			notifyMePopupSel: '.js-notifyme_popup'
		};

		var localStorageItem;
		try{
			localStorageItem = JSON.parse(localStorage.getItem($cache.localStorageKey));
			if ($cache.sid === localStorageItem.sid && !app.currentCustomer.isAuthenticated()) {
				$cache.wishListPIDS = localStorageItem && 'products' in localStorageItem && Array.isArray(localStorageItem.products) && localStorageItem.products || [];
				$cache.localStorageInitialized = true;
			} else {
				initStorage();
			}
		}
		catch(e){
			initStorage();
			console.log("Cannot parse JSON of wishlist local storage", e);
		}
	}

	/**
	 * @private
	 * @function
	 * @description Binds the send to friend and address changed events to the wishlist page
	 */
	function initializeEvents() {
		app.components.global.sendToFriend.initializeDialog('.js-list_table_header', '.js-send_to_friend');
		app.components.global.sendToFriend.initializeDialog('.js-table-item_list', '.js-send_to_friend');
		$cache.editAddress.on('change', function() {
			window.location.href = app.util.appendParamToURL(app.urls.wishlistAddress, 'AddressID', $(this).val());
		});

		//add js logic to remove the , from the qty field to pass regex expression on client side
		$('.js-option_quantity_desired div input').focusout(function() {
			$(this).val(
				$(this)
					.val()
					.replace(',', '')
			);
		});

		//add js logic to remove item in dropdown wishlist
		$cache.wishlistFlyout.on('submit', '.js-remove_from_wishlist', function(e) {
			e.preventDefault();

			if (app.page.ns !== 'wishlist') {
				var itemid = $(this)
						.find('input[name="itemid"]')
						.val(),
					href = '';
				if (!itemid.length || !app.urls.removeProdyctFromWishlist) return;
				href = app.util.appendParamsToUrl(app.urls.removeProdyctFromWishlist, { pliid: itemid, format: 'ajax' });
				$.ajax({
					url: href,
					xhrFields: app.corsOrigin ? { withCredentials: true } : undefined,
					type: 'get'
				}).done(function(data) {
					if (data) {
						$cache.wishlistFlyout.html(data);
						app.componentsMgr.loadComponent('global.headerwishlist');
						$document.trigger('wishlist.removed');
					}
				});
				e.stopImmediatePropagation();
				return false;
			}
		});

		$cache.popupCloseButton.on('click', function() {
			app.fancybox.close();
		});

		$cache.wishlistContainer.on('click', $cache.notifyMePopupSel, app.product.openNotifyMePopup);

		// manage appearence of wishlist tooltip
		if (app.device.currentDevice() === 'desktop' && !initedComponent) {
			initedComponent = true;
			$cache.wishlistFlyoutMouseover.hover(function() {
				$cache.wishlistTooltip.toggleClass('h-hidden');
			});
		}

		$($cache.wishlistQty).on('change', function() {
			var $parentForm = $(this).closest('form');
			if ($parentForm.valid()) {
				$parentForm.find('.js-product_list-updateqty_button').click();
				app.page.refresh();
			}
		});

		if ($.cookie($cache.addtowishlist) && app.components.global && app.currentCustomer.isAuthenticated()) {
			if(typeof $.cookie('addToWishlistItemNum') !== 'undefined') {
				var cookieVal = $.cookie($cache.addToWishlistItemNum);
				$cache.wishlistItem = $($cache.cartWishlistBtn + '[data-line-item-num="' + cookieVal + '"]');
				$.removeCookie($cache.addToWishlistItemNum);
			}
			addtoWishListAction($.cookie($cache.addtowishlist), {hidepopup: !$.cookie($cache.wishlistShowLoginFlyout)});
			$.removeCookie($cache.addtowishlist, { path: '/' });
		}

		if (app.page.ns !== 'account') {
			$.removeCookie($cache.wishlistShowLoginFlyout, { path: '/' });
		}

		if (app.device.isMobileView()) {
			app.components.product.mobile.init();
		}

		$cache.updateWishlistAddToCartQuantity.on('change', function() {
			var $this = $(this);
			$this.closest($cache.wishlistTableListItemSel).find($cache.productQuantitySel).val($this.val());
		});

		$cache.wishlistDeleteForm.submit(function () {
			var pid = $(this).data('pid');
			if(pid){
				deleteFromStorage(pid);
			}

			if (app.preferences.emarsysWishlistReminderEnabled) {
				var form = $(this).closest('form');
				var formActionUrl = form.attr('action');

				form.attr('action', app.util.appendParamToURL(formActionUrl, 'triggerWishlistReminder', true));
			}
		});

		$cache.document.on('last.visited.loaded', function(){
			var lastVisitedBlock = $($cache.lastVisitedSel);
			if (lastVisitedBlock.length) {
				updateWishlistButtons(lastVisitedBlock);
			}
		});

		$cache.document.on('recommendations.loaded', function() {
			var recommendationsBlock = $($cache.recommendationsBlockSel);
			if (recommendationsBlock.length) {
				updateWishlistButtons(recommendationsBlock);
			}
		});
	}

	function initializeDocumentEvents() {
		// add product to whishlist if user registered
		var swatchAnchorEvent = Modernizr.touchevents && !$($cache.addtoWishListSel).is('a, div, span') ? 'touchstart' : 'click';
		$document.on(swatchAnchorEvent, $cache.addtoWishListSel, function(event) {
			var el = this;
			var dataJSON;
			var elDataLineItemJSON = $(el).data("customization-json");
			var customInput = $($cache.customInputSel);

			if ((typeof elDataLineItemJSON !== 'undefined' && elDataLineItemJSON !== '')){
				dataJSON = JSON.stringify(elDataLineItemJSON);
			} else if (typeof customInput.val() !== 'undefined' && customInput.val() !== ''){
				dataJSON = customInput.val();
			}

			if (($cache.isPDPConfigurable || typeof elDataLineItemJSON !== 'undefined')) {
				//Check if configurations are overlapped
				if (!dataJSON) {
					wishlistHandlerWrapper();
				}
				else if (wishlistHandlerWrapper()) {
					var customizationID;
					if (_customizationMemoize.hasOwnProperty(dataJSON)) {
						customizationID = _customizationMemoize[dataJSON];
						updateHref($(el), customizationID);
						return addProductToWhishlist(el, event, dataJSON);
					} else {
						app.components.product.configurator.saveProductCustomization(
							function (response) {
								customizationID = response.customizationID.toString();
								_customizationMemoize[dataJSON] = customizationID;
								updateHref($(el), customizationID);
								return addProductToWhishlist(el, event, dataJSON);
							}, dataJSON, {'isWishlist' : true}
						);
					}
				}
			} else {
				updateHref($(this));
				return addProductToWhishlist(this, event);
			}
		});

		$document.on('click', $cache.addtoWishListClose, function() {
			var productTile = $(this).closest($cache.productTileSel);

			productTile.find($cache.variantsBlockSel).removeClass('error-variant_unchosen');
			productTile.removeClass($cache.variantsOpenedCls);
		});

		// add product with multiple variants to wishlist
		$document.on('click', $cache.addtoWishListMultiVarSel, function(event) {
			var $this = $(this),
				productTile = $this.closest($cache.productTileSel),
				variantsBlock = productTile.length
					? productTile.find($cache.variantsBlockSel)
					: $this.closest($cache.variantsBlockSel),
				isVariantChosen = variantsBlock.data('variant-chosen');

			if (isVariantChosen === true) {
				updateHref($this);
				return addProductToWhishlist(this, event);
			} else {
				isVariantChosen === false && variantsBlock.addClass('error-variant_unchosen');
				showVariants($this, productTile, variantsBlock);
			}
			event.stopImmediatePropagation();
		});

		$document.on('product.added', function(event, container) {
			if (!app.preferences.removeProductFromWishlist) {
				return false;
			}
			var form = $(container);
			if (app.page.ns !== 'wishlist' || !form.data('wishlistItem')) return;
			var itemid = form.find('input[name="itemid"]').val(),
				href = '';
			if (!itemid.length || !app.urls.wishlistItemRemove) return;
			href = app.util.appendParamsToUrl(app.urls.wishlistItemRemove, {
				pliid: itemid,
				format: 'ajax'
			});
			$.ajax({
				url: href,
				type: 'get'
			}).done(function() {
				var $controlContainer = form.closest('tr');
				if ($controlContainer.siblings('.js_wishlist-controls').length) {
					if ($controlContainer.prev('tr').hasClass(wishlistTableFirstRowClass)) {
						$controlContainer
							.next('tr')
							.addClass(wishlistTableFirstRowClass)
							.removeClass(wishlistTableSimpleRowClass);
					}
					$controlContainer.prev('tr').remove();
					$controlContainer.remove();

					$document.trigger('wishlist.updated', 'table');
				} else {
					if ('popup' !== app.preferences.cartAddProductAjaxTarget) {
						app.page.refresh();
					}
				}
			});
		});

		$document.on('wishlist.added wishlistblock.removed', function() {
			if ($cache.wishlistItem && !$cache.wishlistBlock.length) {
				$cache.wishlistItem.addClass($cache.wishlistAddedClass).html(app.resources.SAVED_TO_WISHLIST);
				$cache.wishlistItem.trigger('wishlistbutton.added');
			}
		});

		$document.on('cart.addproduct.popup.close', function() {
			if (app.page.ns !== 'wishlist') return;
			app.page.refresh();
		});

		if (app.device.isMobileView() && app.page.ns === 'product') {
			$document.on('click', $cache.addtoWishListCopySel, function(event) {
				var $element = $($cache.addtoWishListSel);
				if ($element.length) {
					addProductToWhishlist($element[0], event);
				}
			});
		}
	}

	function updateHref($el, customizationID) {
		var pid = $el.data('pid'),
			url = $el.data('href');
		if (pid) {
			url = app.util.appendParamToURL(url, 'pid', pid);
		}
		if (app.preferences.emarsysWishlistReminderEnabled) {
			url = app.util.appendParamToURL(url, 'triggerWishlistReminder', true);
		}
		if (typeof customizationID !== 'undefined' && customizationID !== '') {
			url = app.util.removeParamFromURL(url, 'customizationID');
			url = app.util.appendParamToURL(url, 'customizationID', customizationID);
		}
		$el.data('href', url);
	}

	function showVariants($this, $tile, $variantsBlock) {
		if ($variantsBlock && $variantsBlock.length) {
			if (app.device.isMobileView()) {
				!$($cache.wishlistPopupSel).length &&
					app.fancybox.open($variantsBlock, {
						wrapCSS: $cache.popupWrapper
					});
			} else {
				$tile.addClass($cache.variantsOpenedCls);
			}
			$variantsBlock.data('variant-chosen', false);
		} else {
			var pid = $this.data('pid'),
				params = { pid: pid, tmpl: 'selectvariation' };
			if (app.device.isMobileView()) {
				app.fancybox.open(app.util.appendParamsToUrl(app.urls.productGetVariants, params), {
					wrapCSS: $cache.popupWrapper,
					afterShow: function() {
						$($cache.wishlistPopupSel)
							.find($cache.variantsBlockSel)
							.data('variant-chosen', false);
					}
				});
			} else {
				app.ajax.load({
					url: app.util.appendParamsToUrl(app.urls.productGetVariants, params),
					callback: function(data) {
						if (data) {
							$tile
								.append(data)
								.addClass($cache.variantsOpenedCls)
								.find($cache.variantsBlockSel)
								.data('variant-chosen', false);
						}
					}
				});
			}
		}
	}

	function addtoWishListAction(href, opts) {
		if (href) {
			href = app.util.appendParamToURL(href, 'sourcepage', app.page.ns);
			var postData = {};

			if (opts.customizationJSON) {
				postData['customizationJSON'] = opts.customizationJSON;
			}

			$.ajax({
				url: href,
				method: 'POST',
				contentType: 'application/json',
				data: JSON.stringify(postData)
			})
				.done(function(data) {
					if (data) {
						if (app.page.ns !== 'checkout') {
							$cache.wishlistFlyout.html(data);
						}

						if (app.page.ns === 'wishlist') {
							app.page.redirect(app.urls.wishlistShow);

							return false;
						}

						app.componentsMgr.loadComponent('global.headerwishlist');

						// sometimes we need to show fancy box after product was added but keep shothelook popup opened
						if (app.fancybox && !(opts.eventSource === 'shopthelook')) {
							app.fancybox.close();
						}

						if (app.preferences.wishlistAddAuthenticated === 'popup') {
							app.fancybox.open($cache.wishListConfirmationContent, {
								wrapCSS: $cache.popupWrapper,
								minHeight: 'auto',
								afterShow: function() {
									$($cache.fancyboxOverlay).addClass($cache.popupOverlay);
									$($cache.popupCloseButton).on('click', function() {
										app.fancybox.close();
									});
								}
							});
						} else {
							$cache.addedToWishlistMsg.show();
						}

						$document.trigger(
							'wishlist.added',
							[$(data)
								.find($cache.wishlistQty)
								.text(),
							(opts && opts.hidepopup), $cache.wishlistFlyout]
						);

						if (typeof opts !== 'undefined' && typeof opts.pid !== 'undefined') {
							addToStorage(opts.pid);
						}
					}
				});
		}
	}

	function deleteFromStorage(pid){
		var pIDindex = $cache.wishListPIDS.indexOf(pid);
		if(pIDindex !== -1){
			$cache.wishListPIDS.splice(pIDindex, 1);
			updateStorage();
		}
	}

	function findProductInWishlist(pid) {
		return $cache.wishListPIDS.indexOf(pid);
	}

	function addToStorage(pid) {
		pid = pid ? pid.toString() : '';

		if ($cache.wishListPIDS.indexOf(pid) === -1) {
			$cache.wishListPIDS.push(pid);
			updateStorage();
		}
	}

	function updateStorage() {
		localStorage.setItem($cache.localStorageKey, JSON.stringify({"sid": $cache.sid, "products": $cache.wishListPIDS}));
	}

	function initStorage() {
		if (app.currentCustomer.isAuthenticated() || app.preferences.enableWishlistForNotAuthUser) {
			$.get(app.urls.wishlistAjaxGet).done(function(jsonData) {
				app.wishlistData = jsonData;

				if (app.currentCustomer.isAuthenticated()) {
					$.each(jsonData, function(key, val) {
						$cache.wishListPIDS.push(val.PID);
					});
					$cache.localStorageInitialized = true;

					document.dispatchEvent(new CustomEvent('wishlist.buttons.update'));

					updateStorage();
				}
			}).fail(function() {
				updateStorage();
			});
		} else {
			$cache.localStorageInitialized = true;
			updateStorage();
		}
	}

	function updateWishlistButtons(container) {
		if(!initialized){
			app.wishlist.init();
		}
		function buttonsUpdater() {
			if($cache.wishListPIDS && Array.isArray($cache.wishListPIDS) && $cache.wishListPIDS.length > 0){
				container.find($cache.addToWishlistSel).each(function () {
					var $this = $(this);

					for (var i = 0, len = $cache.wishListPIDS.length; i < len; i++) {
						if ($this.data('pid') && $cache.wishListPIDS[i].toString() === $this.data('pid').toString()) {
							$this.addClass($cache.wishlistAddedClass);
						}
					}
				});
			}
		}

		if($cache.localStorageInitialized){
			buttonsUpdater();
		}
		else{
			document.onreadystatechange = function () {
				if(document.readyState === "complete"){
					if($cache.localStorageInitialized) {
						buttonsUpdater();
					}
				}
			};
		}
	}

	function wishlistHandlerWrapper(){
		if(typeof beforeAddToWishlistFunction === 'function'){
			return beforeAddToWishlistFunction.call(beforeAddToWishlistFunction);
		}
		return true;
	}

	function addProductToWhishlist(element, event, customizationJSON) {
		var $this = $(element),
			$productContentBlock = $this.closest($cache.productContentSel),
			productErrorBlock = $productContentBlock.find($cache.errorVariationsSel),
			eventSource = event.currentTarget.dataset.source;
		$document.trigger('wishlist.beforeadded', $this);
		$cache.wishlistItem = $this;
		if (productErrorBlock.length > 0) {
			if (
				app.preferences.isPdpPopupEnabled &&
				app.page.ns === 'product' &&
				$this.closest($cache.pdpQuickviewSel).length === 0
			) {
				$document.trigger('product.valid', [productErrorBlock]);
				if (productErrorBlock.is(':visible')) {
					productErrorBlock.hide();
				}
				app.fancybox.open($cache.errorPopup, {
					wrapCSS: $cache.popupWrapper,
					afterShow: function() {
						$($cache.fancyboxOverlay).addClass($cache.popupOverlay);
					}
				});
			} else {
				$document.trigger('product.invalid', [productErrorBlock]);
				productErrorBlock.show();

				if (app.preferences.pdpScrollToVariationsError) {
					if (!app.util.isVisibleFullHeight(productErrorBlock[0])) {
						var sizeSelectorPosition = $productContentBlock.offset().top;
						var headerHeight = $('header.js-header_main:first').height();
						app.util.scrollBrowser(sizeSelectorPosition - headerHeight);
					}
				}

				return false;
			}
		} else {
			if (app.currentCustomer.isAuthenticated() || app.preferences.enableWishlistForNotAuthUser) {
				var isEmptyCartRecommCarousel = $this.closest($cache.emptyCartRecommBlockSel).length;
				if (app.preferences.redirectToWishlistFromCartPage && $this.hasClass($cache.cartWishlistBtnClass)) {
					window.location = app.util.appendParamsToUrl($this.data('href'), { redirectToWishlist: true });
				} else if (
					('redirect' === app.preferences.wishlistAddAuthenticated &&
						!(app.device.isMobileView() && app.preferences.productShowPopup)) ||
					(isEmptyCartRecommCarousel && !$cache.wishlistBlock.length && !app.device.isMobileView())
				) {
					window.location = app.util.appendParamsToUrl($this.data('href'), { format: 'redirect' });
				} else {
					addtoWishListAction($this.data('href'), {
						pid: $this.data('pid'),
						customizationJSON: customizationJSON,
						eventSource: eventSource
					});
					typeof event !== 'undefined' && event.stopImmediatePropagation();
				}
			} else {
				if (app.device.isMobileView() && !app.preferences.enableMobileWishlistLoginPopup) {
					window.location = app.util.appendParamsToUrl($this.data('href'), {
						format: 'redirect',
						returnUrl:
							app.preferences.wishlistAddNotauthenticatedMobileReturn === 'wishlist'
								? app.urls.wishlistShow
								: encodeURIComponent(window.location)
					});
				} else if ('redirect' === app.preferences.wishlistAddNotauthenticated) {
					window.location = app.util.appendParamsToUrl($this.data('href'), { format: 'redirect' });
				} else {
					$.cookie($cache.addtowishlist, $this.data('href'), {
						path: '/'
					});
					$.cookie($cache.addToWishlistItemNum, $cache.wishlistItem.data('line-item-num'));
					if (app.preferences.wishlistShowLoginFlyout) {
						$.cookie($cache.wishlistShowLoginFlyout, true, {
							path: '/'
						});
						if (app.preferences.wishlistShowLoginError === true) {
							$cache.addtoWishListError.show();
						}
						if (
							app.page.ns === 'checkout' &&
							!app.device.isMobileView()
						) {
							app.components.account.fakelogin.showPopup();
						} else if (!app.preferences.customFakeLoginLogic) {
							typeof event !== 'undefined' && app.components.account.fakelogin.show(event);
						}
					}
					if ((!app.preferences.enableMobileWishlistLoginPopup && app.fancybox) && !(eventSource === 'shopthelook')) {
						app.fancybox.close();
					}

					if (app.preferences.wishlistRedirectAfterClose) {
						$document.on('fancybox.closed', function() {
							var toUrl = $this.data('href');
							toUrl = app.util.removeParamFromURL(toUrl, 'format');
							toUrl = app.util.appendParamToURL(toUrl, 'format', 'redirect');
							app.page.redirect(toUrl);
						});
						typeof event !== 'undefined' && event.stopImmediatePropagation();
					}
				}
			}

			return false;
		}

		if (
			app.preferences.plpScrollTopOnAddToWishlist == 'true' &&
			(app.preferences.isPdpPopupEnabled && app.page.ns !== 'product')
		) {
			app.util.scrollBrowser(0);
		}
	}

	function updateTooltipVal(val) {
		val = parseInt(val);
		if (val > 0) {
			$($cache.wishlistTooltipValSel).text(
				val == 1 ? app.resources.TOOLTIP_ITEM.replace('#', val) : app.resources.TOOLTIP_ITEMS.replace('#', val)
			);
		} else {
			$($cache.wishlistTooltipValSel).text(app.resources.TOOLTIP_EMPTY);
		}
	}

	/** ************* Object of method for external functionality ************** */
	methods = {
		beforeAddToWishlistFunction : function(func){
			beforeAddToWishlistFunction = func;
		}
	};

	/******* app.wishlist public object ********/
	app.wishlist = {
		/**
		 * @function
		 * @description Binds events to the wishlist page
		 */
		init: function() {
			if(!initialized) {
				initializeCache();
				app.product && app.product.initAddToCart($('.js-wishlist-add_to_cart'));
				initializeEvents();

				if (!documentEventsInitialized) {
					initializeDocumentEvents();
					documentEventsInitialized = true;
				}

				initialized = true;
			}
		},
		addProductToWhishlist: addProductToWhishlist,
		updateHref: updateHref,
		updateTooltipVal: updateTooltipVal,
		beforeAddToWishlistFunction : methods.beforeAddToWishlistFunction,
		updateWishlistButtons: updateWishlistButtons,
		deleteFromStorage: deleteFromStorage,
		addToStorage: addToStorage,
		findProductInWishlist: findProductInWishlist,
		addtoWishListAction: addtoWishListAction
	};
})((window.app = window.app || {}), jQuery);
