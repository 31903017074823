import CSRF from 'oneapp/src/utils/CSRF';

( function(app, $) {
	var $cache = {},
		iframeLogin,
		iframeTarget = 'loginiframe',
		submitEvent = Modernizr.touchevents ? 'touchstart' : 'click',
		eventMessage = "message",
		loginFormFields = {},
		messageToPost,
		timer = {
			id : null,
			clear : function () {
				if (timer.id) {
					window.clearTimeout(timer.id);
					delete timer.id;
				}
			},
			start : function (duration) {
				timer.id = setTimeout(app.components.account.fakelogin.closelogin, duration);
			}
		},
		configs = {
			checkoutHideOnMouseLeave : true
		},
		timeOut = app.preferences.fakeloginTimeout;

	function initializeConfigs(params) {
		if(params) {
			configs = $.extend(true, {}, configs, params);
		}
	}
	
	function sendToIframe(message) {
		var $iframeContainer = $($cache.iframeContainerSel);
		if ($iframeContainer.length) {
			$iframeContainer.empty();
			$iframeContainer.append('<iframe id="js-login_iframe" src="' +
					$iframeContainer.data('iframeUrl') + '"/>');
			messageToPost = message;
		}
	}

	function initializeCache() {

		$cache = {
			dropdownActiveClass: 'b-login_dropdown-active',
			linkActiveClass: 'b-login_header-link-active',
			fakeSubmitSel : ".js-fake_submit",
			fieldsForIframeSel : ".js-fields_for_iframe",
			errorFormTextSel : ".js-error_form",
			minimizedClass: 'h-minimized',
			loginFormSel : "form.js-login_account-form",
			loginLink: $('.js-fakelogin-link'),
			wrapper : $('.js-login_dropdown'),
			wrapperSel: '.js-login_dropdown',
			loginFlyout : $('.js-login_dropdown-flyout'),
			loginHeaderTitle : $('.js-login_dropdown-title'),
			checkoutRefreshForm: $('#checkoutRefreshForm'),
			iframeContainerSel : '.js-login_iframe_container',
			document: $(document),
			wrapperHoverClass : $('.js-login_dropdown').data('custom-class'),
			wishlistBlockLoginSel : '.js-wishlistblock-login',
			externalPlaceholderSel : '.js-external_placeholder',
			focusedInputSel: "input:focus",
			csrfTokenName: 'csrf_token'
		};

		loginFormFields = {
			username : "#dwfrm_login_username",
			password : "#dwfrm_login_password",
			newsletter : "#dwfrm_login_signup",
			rememberme : "#dwfrm_login_rememberme",
			newsfor	: "[name='dwfrm_newsletter_simple_newsfor']:checked"
		};
	}

	function initializeEvents() {
		if (configs.checkoutHideOnMouseLeave) {
			$cache.wrapper.on('mouseenter toggle', function (e) {
				e.stopPropagation();
				showPopup.call(this, e);
			})
				.on('mouseleave', function(e) {
					if (!$cache.loginLink.find($cache.wrapperSel).length) {
						hidePopup(e);
					}
				});

			$cache.loginLink.on('mouseenter touchstart toggle', function (e) {
				if(this === e.target){
					e.stopPropagation();
					showPopup.call(this, e);
				}
			})
				.on('mouseleave', function(e){
					hidePopup(e);
				});
			
			$cache.document.on('touchstart', function(e){
				e.stopPropagation();
				ipadHidePopup.call(this, e);
			});
		}
		$cache.document.on("fakelogin.reinitcache", function(){
			initializeCache();
		});

		/*
		 * catch fake login submit and send message to iframe
		 */
		$cache.document.on(submitEvent, $cache.fakeSubmitSel, function(e){
			submitInIframe(e);
			return false;
		});
		$cache.document.on('submit', $cache.loginFormSel, function (e) {
			submitInIframe(e);
			return false;
		});
		/*
		 * catch message events from login iframe
		 */

		$(window).on(eventMessage, function(e) {
			var originUrl = e.originalEvent.origin,
				message,
				iframe;

			if( originUrl === getIframeUrl()) {
				// sign in user after iframe is ready
				if (e.originalEvent.data === 'loginiframe.ready') {
					iframe = document.getElementById('js-login_iframe');
					if (iframe && messageToPost) {
						iframe.contentWindow.postMessage(JSON.stringify(messageToPost), getIframeUrl());
						messageToPost = '';
					}
					return;
				}

				try{
					message = JSON.parse(e.originalEvent.data);
				} catch(e) {
				}

				if ( message && message.oneapp && message.oneapp.target == iframeTarget ) {
					var response = message.data;
					if (response.success) {
						if(app.page.type == "orderconfirmation") {
							window.location.href = app.urls.homePage;
						}
						else if($cache.checkoutRefreshForm.length){
							CSRF.populateFormByCSRFToken($cache.checkoutRefreshForm[0], true)
								.then(() => $cache.checkoutRefreshForm.submit());
						}
						else {
							var onLogin = app.device.isMobileView() ? app.preferences.fakeloginMobileOnlogin : app.preferences.fakeloginOnlogin;
							var isNohitsWithWishlistLoginBlock = app.page.ns === 'search' && $($cache.wishlistBlockLoginSel).length > 0;
							if ('refresh' === onLogin || isNohitsWithWishlistLoginBlock) {
								app.page.refresh(true);
							} else {
								var onLoginPipeline = app.device.isMobileView() ? app.preferences.fakeloginMobileOnloginPipeline : app.preferences.fakeloginOnloginPipeline;
								app.page.redirect(onLoginPipeline);
							}
						}
					} else if (response.redirectURL) {
						app.page.redirect(response.redirectURL);
					} else if (response.error) {
						$($cache.errorFormTextSel).text(response.error).show();
					}
				}
			}
		});

		if (app.preferences.showFakeloginOnHover === 'true') {
			$cache.loginHeaderTitle.on('mouseenter', function () {
				app.components.account.fakelogin.show();
			});
		}
	}
	
	function hidePopup (e) {
		if (e.relatedTarget != null) {
			if ($cache.loginLink.length){
				$cache.wrapper.removeClass($cache.wrapperHoverClass)
					.addClass($cache.minimizedClass)
					.removeClass($cache.dropdownActiveClass);
				$cache.loginLink.removeClass($cache.linkActiveClass);
				$cache.wrapper.find($cache.fieldsForIframeSel).find($cache.focusedInputSel).blur();
			} else {
				$cache.wrapper.removeClass($cache.wrapperHoverClass);
			};
			if(navigator.userAgent.match(/iPad/i)){
				$cache.document.off('touchstart', function (e) {
					e.stopPropagation();
					ipadHidePopup.call(this, e);
				});
			}
		}
	};

	function ipadHidePopup(e) {
		var	$clicked = $(e.target);
		e.relatedTarget = e.target;
		if ($clicked !== $cache.wrapper && !($clicked.parents($cache.wrapperSel).length)) {
			hidePopup(e);
		}
	};
	
	function showPopup () {
		var $this = $(this);
		if ($cache.loginLink.length){
			$cache.wrapper.addClass($cache.wrapperHoverClass)
				.removeClass($cache.minimizedClass)
				.addClass($cache.dropdownActiveClass);
			$cache.loginLink.addClass($cache.linkActiveClass);
			//related to browser autofill and overlapp placeholders
			$this.find($cache.externalPlaceholderSel + ':not(.f-state-error)').trigger('focusin');
			$cache.document.trigger('fakelogin.showpopup');
		} else {
			$cache.wrapper.addClass($cache.wrapperHoverClass);
		}
	};

	function submitInIframe(e) {
		e.preventDefault();
		var wrapperClass = $(e.target).data('wrapper'),
			$loginForm = $((wrapperClass ? wrapperClass + ' ' : '') + $cache.loginFormSel);

		$((wrapperClass ? wrapperClass + ' ' : '') + $cache.errorFormTextSel).hide();
		$loginForm.validate();
		if (!$loginForm.valid()) {
			return false;
		}
		if ($($cache.iframeContainerSel).length) {
			var $fieldsForIframe = $((wrapperClass ? wrapperClass + ' ' : '') + $cache.fieldsForIframeSel),
				formData = {
					username : $fieldsForIframe.find( loginFormFields.username ).val() || "",
					password : $fieldsForIframe.find( loginFormFields.password ).val() || "",
					newsletter : $fieldsForIframe.find( loginFormFields.newsletter ).prop("checked") || false,
					rememberme : $fieldsForIframe.find( loginFormFields.rememberme ).prop("checked") || false,
					newsfor	: $fieldsForIframe.find( loginFormFields.newsfor ).val() || ""
				};

			formData[$cache.csrfTokenName] = $fieldsForIframe.find('input[name$="csrf_token"]')?.get(0)?.value;

			sendToIframe({
				formData : formData,
				oneapp   : {target : iframeTarget}
			});

			return;
		}
	}
	
	/*
	 * get origin url for post message to iframe
	 */
	function getIframeUrl() {
		var $iframeContainer = $($cache.iframeContainerSel);
		if ($iframeContainer.length) {
			var obj = app.util.getUri($iframeContainer.data('iframeUrl'));
			return obj.protocol + '//' + obj.hostname;
		}
		return 'https://' + window.location.host;
	}

	/**
	 * @namespace app.components.account.fakelogin public object
	 **/
	app.components = app.components || {};
	app.components.account = app.components.account || {};
	app.components.account.fakelogin = {

		init : function(params) {
			submitEvent = (params && 'submitEvent' in params) ? params['submitEvent'] : submitEvent;
			initializeConfigs(params);
			initializeCache();
			initializeEvents();
		},

		show : function() {
			timer.clear();

			// show the item
			$cache.loginFlyout.addClass('h-show');
			$cache.loginHeaderTitle.addClass('h-toggled');
			// after a time out automatically close it
			timer.start( timeOut );
		},

		closelogin : function() {
			timer.clear();
			$cache.loginFlyout.removeClass("h-show");
			$cache.loginHeaderTitle.removeClass('h-toggled');
		},

		getIframe : function() {
			return iframeLogin;
		},
		sendMessage : sendToIframe,
		showPopup : showPopup,
		hidePopup : hidePopup
	};
}(window.app = window.app || {}, jQuery));
