(function (app, $) {
	
	function initializeParams(params) {	
		$.extend(app.fancybox.settings, params);
	}

  /*************** app.components.global.fancybox public object ***************/
  app.components = app.components || {};
  app.components.global = app.components.global || {};
  app.components.global.fancybox = {
      init : function (params) {
    	  initializeParams(params);
      }
  };
})(window.app = window.app || {}, jQuery);
