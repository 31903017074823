/**
 * @class app.progress
 */
(function (app, $) {
	var loader;
	var setOfClass = {};
	app.progress = {
		/**
		 * @function
		 * @description Shows an AJAX-loader on top of a given container
		 * @param {Element} container The Element on top of which the AJAX-Loader will be shown
		 */
		show: function (container, loaderClassKey) {
			var target = (!container || $(container).length===0) ? $("body") : $(container),
				loaderClass = ( typeof(loaderClassKey) === "string" && setOfClass.hasOwnProperty(loaderClassKey) ) ? " " + setOfClass[loaderClassKey] : "";
			
			loader = loader || $(".loader");

			if (loader.length===0) {
				loader = $("<div/>").addClass("loader" + loaderClass).append($("<div/>").addClass("loader-indicator"), $("<div/>").addClass("loader-bg"));
			}
			return loader.removeClass()
				.addClass("loader" + loaderClass)
				.appendTo(target)
				.show();
		},
		/**
		 * @function
		 * @description Hides an AJAX-loader
		 */
		hide: function () {
			if (loader) { loader.hide(); }
		},
		
		/**
		 * @function
		 * @description This function is used when you need set specific class for loader
		 * @param {loaderClassKey : String} key which are used for additional class sets
		 * @param {loaderClass : String} String will be added inside class attribute right after "loader" 
		 */
		setAditionalClass: function (loaderClassKey, loaderClass) {
			if (typeof(loaderClassKey) === "string" && typeof(loaderClass) === "string"){
				setOfClass[loaderClassKey] = loaderClass;
			}
		}
	};
}(window.app = window.app || {}, jQuery));