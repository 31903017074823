/**
 * app.components.js
 * class for loading and initializing components 
 *  */
( function(app, $) {
	var componentsConfig = {}
	
	app.initializedApps = app.initializedApps || [];
	app.initializedApps.push("app.components");
	
	function setNamespaceConfig(namespace) {
		//Fix config structure
		componentsConfig[namespace] = componentsConfig[namespace] || {};
		componentsConfig[namespace].components = componentsConfig[namespace].components || {};

		//Define page specific config. Depends on currentPage from pageContext
		if (componentsConfig[namespace].pages && app.page.currentPage && componentsConfig[namespace].pages.hasOwnProperty(app.page.currentPage) && componentsConfig[namespace].pages[app.page.currentPage].components) {
			componentsConfig[namespace].components = $.extend(true, componentsConfig[namespace].components, componentsConfig[namespace].pages[app.page.currentPage].components);
		}
	}

	function initNamespaceConfig(namespace) {
		//Extend configuration by brand configuration
		componentsConfig[namespace] = $.extend(true, app.componentsconfig.global[namespace], app.componentsconfig.specific[namespace] || {});
		if(app.device.isMobileView()){
			app.componentsconfig.mobile = app.componentsconfig.mobile|| {};
			app.componentsconfig.mobile.global = app.componentsconfig.mobile.global || {};
			componentsConfig[namespace] = $.extend(true, componentsConfig[namespace], app.componentsconfig.mobile.global[namespace] || {}, app.componentsconfig.mobile.specific[namespace] || {});
		}
		setNamespaceConfig(namespace);
	}
	
	function extendNamespaceWithGlobal(namespace){
		//Add Global components to namespace config
		componentsConfig["global"] = $.extend(true, app.componentsconfig.global["global"], app.componentsconfig.specific["global"] || {});
		if(app.device.isMobileView()){
			componentsConfig["global"] = $.extend(true, componentsConfig["global"], app.componentsconfig.mobile.global["global"], app.componentsconfig.mobile.specific["global"] || {});
		}
		setNamespaceConfig("global");
		componentsConfig[namespace].components = $.extend(true, componentsConfig["global"].components, componentsConfig[namespace].components);
	}

	function initNamespaceComponents(namespace) {
		
		var debugComponents = [], disabledComponents = [], undefinedComponents = [];
		for (var componentPath in componentsConfig[namespace].components) {
			var parts = componentPath.split('.'), componentNS = parts[0], component = parts[1];
			if (componentsConfig[namespace].components[componentNS + '.' + component] && componentsConfig[namespace].components[componentNS + '.' + component].hasOwnProperty('enabled') && !componentsConfig[namespace].components[componentNS + '.' + component].enabled) {
				disabledComponents.push(componentNS + '.' + component);		
				continue;			
			}
			if (app.components[componentNS] && app.components[componentNS][component] && app.components[componentNS][component].init) {
					app.components[componentNS][component].init(componentsConfig[namespace].components[componentNS + '.' + component]);
					debugComponents.push(componentNS + '.' + component);
			}
			else{
				undefinedComponents.push(componentNS + '.' + component);
			}
		}
		console.debug( 'Initialized components: ', debugComponents);
		if (disabledComponents.length){
			console.debug( 'Disabled components: ', disabledComponents);
		}
		if (undefinedComponents.length){
			console.debug( 'Undefined components: ', undefinedComponents);
		}
		console.debug( 'Configuration Object:', componentsConfig[namespace].components );
	}
	
	/**
	 * Load component by request with configuration specific for 
	 * current namespace or for passed namespace
	 * @param componentPath {String} Component name, ie. "global.minicart"
	 * @param namespace {Object} Component configuration object
	 * @return {void}
	 */
	function loadComponent(componentPath, componentConfig) {
		if (!componentPath) return;
		
		var parts = componentPath.split('.'), 
			namespace = app.page.ns,
			componentNS = parts[0], 
			component = parts[1],
			config = {};
		
		initNamespaceConfig(namespace);
		extendNamespaceWithGlobal(namespace);
		
		if (!componentsConfig[namespace] || !componentsConfig[namespace].components[componentPath]) {
			console.debug('Force init. Component ' + componentPath + ' is missed in components configuration object');
			return;
		}
		
		componentConfig = 'object' === typeof componentConfig ? componentConfig : {};
		config = $.extend({}, componentsConfig[namespace].components[componentPath], componentConfig);
		
		if (config.hasOwnProperty('enabled') && !config.enabled) {
			console.debug('Force init. Component ' + componentPath + ' is disabled');
			return;
		}
		
		if (app.components[componentNS] && app.components[componentNS][component] && app.components[componentNS][component].init) {
			app.components[componentNS][component].init(config);
			console.debug('Force init. Component ' + componentPath + ' has been initialized');
		} else {
			console.debug('Force init. Component ' + componentPath + ' is undefined');
		}
	}

	/**
	 * Validate is component with given name enabled through components configuration
	 * @param componentName {String} Component name, ie. "global.minicart"
	 * @return {Boolean}
	 */
	function isComponentEnabled(componentName) {
		var isEnabled = false;
		var parts = componentName.split('.');
		var namespace = parts.shift();
		var componentConfig = { enabled: false };

		if (namespace in componentsConfig) {
			var components = componentsConfig[namespace].components;

			if (componentName in components) {
				componentConfig = components[componentName];
			} else {
				var component = parts.join('.');

				if (component in components) {
					componentConfig = components[component];
				}
			}

			isEnabled = !componentConfig.hasOwnProperty('enabled') || !!componentConfig.enabled;
		}

		return isEnabled;
	}

	/*************** app.componentsMgr public object ***************/
	app.componentsMgr = {
		/**
		 * Loads by default with global components
		 *  */
		load : function(namespace) {
			if (!namespace && !componentsConfig[namespace]) {
				return;
			}
			console.debug( 'AutoInit ' + namespace +  ' components' );
			initNamespaceConfig(namespace);
			extendNamespaceWithGlobal(namespace);
			initNamespaceComponents(namespace);
		},
		/**
		 * Loads by request from other scripts only namspace components depends on current page
		 *  */
		loadns : function(namespace) {
			if (!namespace && !componentsConfig[namespace]) {
				return;
			}
			console.debug( 'Force Init ' + namespace +  ' components' );
			app[namespace].init();
			componentsConfig[namespace] = {};
			initNamespaceConfig(namespace);
			initNamespaceComponents(namespace);
		},
		loadComponent: loadComponent,
		isComponentEnabled: isComponentEnabled
	};

}(window.app = window.app || {}, jQuery));

