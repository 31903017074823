import AmazonPayButtonMgr from 'amazon/AmazonPayButtonMgr';

// app.components.global.minicart
(function(app, $) {
	var $cache = {},
		initialized = false,
		timer = {
			id: null,
			clear: function() {
				if (timer.id) {
					window.clearTimeout(timer.id);
					delete timer.id;
				}
			},
			start: function(duration) {
				timer.id = setTimeout(app.components.global.minicart.close, duration);
			}
		},
		mcOptions = {
			mcWrapperSelector: '.mini-cart-total',
			mcSelector: '.mini-cart-content',
			mcRemoveSelector: '.js-mini_cart-remove',
			mcCloseSelector: '.js-mini_cart-close',
			mcWrapperSelectorEvent: 'mouseenter'
		};

	function initializeCache() {
		$cache.document = $(document);
		$cache.body = $('body');
		$cache.minicart = $('.js-mini_cart');
		$cache.minicartSelector = '.js-mini_cart';
		$cache.mcContent = $cache.minicart.find('.js-mini_cart-flyout');
		$cache.mcTitleSel = '.js-mini_cart-title';
		$cache.mcContentSel = '.js-mini_cart-flyout';
		$cache.mcTitle = $cache.minicart.find('.js-mini_cart-title');
		$cache.mcClose = $cache.minicart.find('.js-mini_cart-close');
		$cache.mcProductList = $cache.minicart.find('.mini-cart-products');
		$cache.mcProducts = $cache.mcProductList.children('.mini-cart-product');
		$cache.shippingInfoIconSel = '.js-shipping-info-icon';
		$cache.shippingInfoContentSel = '.js-shipping-info-content';
		$cache.hiddenClass = 'h-hidden';
		$cache.minicartContainerSel = '.js-mini_cart-flyout';
		$cache.minicartContainer = $('.js-mini_cart-flyout');
		$cache.header = $('.js-header_main');
	}

	function initializeEvents() {
		// bind hover event to the cart total link at the top right corner
		$cache.minicart
			.on(mcOptions.mcWrapperSelectorEvent, mcOptions.mcWrapperSelector, function(e) {
				if (mcOptions.mcWrapperSelectorEvent === 'click' && $cache.mcContent.not(':visible')) {
					e.preventDefault();
					app.components.global.minicart.slide();
				}
				if ($cache.mcContent.not(':visible')) {
					app.components.global.minicart.slide();
				}
			})
			.on('mouseenter', mcOptions.mcSelector, function() {
				timer.clear();
			})
			.on('mouseleave', mcOptions.mcSelector, function() {
				timer.clear();
				timer.start(4000);
			})
			.on('click', mcOptions.mcCloseSelector, function() {
				app.components.global.minicart.close();
			})
			.on('click', mcOptions.mcRemoveSelector, function(e) {
				e.preventDefault();
				var $this = $(this);
				$.ajax(this.href, {
					data: {
						uuid: $this.data('item'),
						type: $this.data('type')
					},
					xhrFields: app.corsOrigin ? { withCredentials: true } : undefined
				})
					.done(function(data) {
						if (data) {
							$cache.document.trigger('minicart.product.removed', { html: data });
							if (app.preferences.dynamicPromotionsEnable) {
								$cache.document.trigger('dynamicpromotion.pdp.update');
								$cache.document.trigger('dynamicpromotion.minicart.update', data);
							}
							$cache.document.trigger('minicart.afterload');
						} else {
							location.href = location.href;
						}
					})
					.fail(function() {
						location.href = location.href;
					});
			});

		$cache.mcProducts.append('<div class="js-mini_cart-toggler">&nbsp;</div>');
		$cache.mcProductList.toggledList({
			toggleClass: 'collapsed',
			triggerSelector: '.js-mini_cart-toggler',
			eventName: 'click'
		});

		$cache.document.on('minicart.show minicart.product.removed', function (event, data) {
			app.components.global.minicart.show(data && data.html, data && data.silent);
		});

		renderAmazonPayButton();

		$cache.document.on('minicart.load minicart.afterload minicart.product.removed', function() {
			renderAmazonPayButton();

			if ($('klarna-placement').length) {
				app.components.global.klarnapayments.siteMessageUpdate();
			}
		});

		if (app.device.isMobileView()) {
			$cache.document.on('fancy.mobile.added', function() {
				renderAmazonPayButton();

				if ($('klarna-placement').length) {
					app.components.global.klarnapayments.siteMessageUpdate();
				}
			});
		}

		initShippingInfoPopup();
	}

	function initShippingInfoPopup() {
		if (app.device.isMobileView()){
			$cache.document.on('click', $cache.shippingInfoIconSel, function(){
				var $this = $(this),
					$shippingInfoCont = $($cache.shippingInfoContentSel);
				if ($shippingInfoCont.find('.content-asset').children().length > 0) {
					app.fancybox.open($this, {
						content : $shippingInfoCont.html(),
						wrapCSS : 'shipping-info-popup',
						autoSize : false,
						afterClose : function() {
							$cache.document.trigger('product.added');
						}
					});
				}
			});
		} else {
			$cache.document.on('mouseenter mouseleave', $cache.shippingInfoIconSel, function(e){
				var $shippingInfoCont = $($cache.shippingInfoContentSel);
				if ($shippingInfoCont.find('.content-asset').children().length > 0) {
					$shippingInfoCont.toggleClass($cache.hiddenClass , e.type !== 'mouseenter' );
				}
			});
		}
	}

	/**
	 * @function
	 * @description Shows the given content in the mini cart
	 * @param {String} A HTML string with the content which will be shown
	 */
	function show(html, silent = false) {
		var content = $(html).find($cache.minicartContainerSel);
		var titleContent = $(html).find($cache.mcTitleSel);
		$cache.minicartContainer.html(content.html());
		$cache.mcTitle.html(titleContent.html());

		if (!silent && app.preferences.plpScrollTopOnAddToCart === 'true') {
			app.util.scrollBrowser(0);
		}

		app.components.global.minicart.slide(silent);
		$cache.document.trigger('minicart.load');
	}

	function showMinicartTemplate($minicart) {
		if ($minicart && $minicart.length) {
			$cache.document.trigger('minicart.show', {
				html: app.util.renderTemplate($minicart.html(), {})
			});
		}
	}

	function renderAmazonPayButton() {
		if (app.amazon && app.amazon.pay.enabled) {
			const amazonPayButtons = document.querySelectorAll('.js-amazon-pay-button');

			if (amazonPayButtons.length > 0) {
				for (const amazonPayButton of amazonPayButtons) {
					if (!amazonPayButton.classList.contains('h-hidden')) {
						var newAmazonPayButton = document.createElement('div');
						newAmazonPayButton.setAttribute('class', 'b-amazon-pay-button js-amazon-pay-button');
						newAmazonPayButton.setAttribute('data-model-basket', 'amazon.applicable');
						newAmazonPayButton.innerHTML = app.amazon.pay.buttonBody;
						amazonPayButton.replaceWith(newAmazonPayButton);
						AmazonPayButtonMgr.render(newAmazonPayButton, app.amazon.pay, 'Cart');
					}
				}
			}
		}
	}

	/*************** app.components.cart.minicart public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.minicart = {
		// during page loading, the Demandware URL is stored here
		url: '',
		// initializations
		init: function(params) {
			mcOptions = $.extend(mcOptions, params || {});
			initializeCache();
			initializeEvents();

			initialized = true;
		},

		show: show,

		/**
		 * @function
		 * @description Slides down and show the contents of the mini cart
		 */
		slide: function(silent = false) {
			if (!initialized) {
				app.components.global.minicart.init();
			}

			if (app.components.global.minicart.suppressSlideDown && app.components.global.minicart.suppressSlideDown()) {
				return;
			}
			if (!silent) {
				$cache.mcContent.addClass('h-show');
				$cache.mcTitle.addClass('h-toggled');
				$cache.body.addClass('minicart-open');
			}
		},
		/**
		 * @function
		 * @description Closes the mini cart with given delay
		 * @param {Number} delay The delay in milliseconds
		 */
		close: function() {
			$cache.mcContent.removeClass('h-show');
			$cache.mcTitle.removeClass('h-toggled');
			$cache.body.removeClass('minicart-open');
			$cache.header.trigger('update.header');
		},
		/**
		 * @function
		 * @description Hook which can be replaced by individual pages/page types (e.g. cart)
		 */
		suppressSlideDown: function() {
			return false;
		},

		showMinicartTemplate: showMinicartTemplate
	};
})((window.app = window.app || {}), jQuery);
