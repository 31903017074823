(function (app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			document: $(document),
			countrySelectModalOpened: false,
			countrySelectApproved: false,
			countryCode: false,
			redirectCountryEvent: 'modal.redirect.confirm',
			redirectLocaleEvent: 'modal.redirectLocale.confirm',
			removeGiftCertEvent: 'modal.removegiftcert.confirm',
			countryRedirectWrapper: 'b-modal_country_redirect',
			giftCertRedirectApproveSel: '.js-confirm_giftcert_approve',
			redirectCountryApproveSel: '.js-confirm_approve',
			redirectCancelSel: '.js-confirm_cancel',
			redirectLocaleApprove: $('.js-confirm_locale-redirect'),
			selectedCountrySel: $('select.country'),
			selectedPrevCountry: $('select.country').val(),
			selectedCountryNativeSel: $('.js-native-countryselector'),
			storedCountryNativeSel: $('.js-native-countryselector').html(),
			currentCountryCode: app.user.country.value
		};
		if ($cache.selectedCountrySel) {
			$cache.selectedCountrySel.data('value', $cache.selectedPrevCountry);
		}
	};
	
	function initializeEvents() {
		$cache.document.on($cache.redirectCountryEvent, function(e, data) {
			if (app.util.getConfig('multidomain.allowconfirmationPopup')) {
				$.ajax(app.urls.showRedirectCountryPopup, {
					data: { countryCode: $cache.countryCode },
					xhrFields: app.corsOrigin ? { withCredentials: true } : undefined
				})
					.done(function(response) {
						if (response) {
							showPopup(response);
						} else {
							redirectCountry(data);
						}

						if ($.isFunction(data)) {
							data();
						}
					});
			} else {
				redirectCountry(data);
				if ($.isFunction(data)) {
					data();
				}
			}
		});

		$cache.document.on($cache.redirectLocaleEvent, function() {
			$.ajax(app.urls.showRedirectLocalePopup)
				.done(function(response) {
					if (response) {
						response = app.util.renderTemplate(response, {
							COUNTRY: $(response).find('.js-locale_popup-title').data('country')
						});
						app.fancybox.open(response, {
							afterClose: function() {
								if (!$.cookie(app.configs.firstVisit.keyName)) {
									app.components.global.firstvisitbanner.init();
								}
							},
							wrapCSS: 'fancybox-locale-redirect'
						});
					}
				});
		});

		$cache.document.on('click', '.js-confirm_locale-redirect', function() {
			$cache.redirectLocaleApprove = $('.js-confirm_locale-redirect');
			var countryCode = $cache.redirectLocaleApprove.data('country');
			var defaultCountryURL = app.util.appendParamsToUrl(app.urls.changeCountry, { Country: countryCode, format: 'ajax', popup: 'true' });
			app.components.global.localeselector.changeCountry(defaultCountryURL);
		});

		$cache.document.on($cache.removeGiftCertEvent, function(e, data) {
			$cache.countryCode = $cache.selectedCountrySel.val() || data.selectedCountryCode;
			$.ajax(app.urls.showRemoveGiftCertPopup, {
				data: { countryCode: $cache.countryCode },
				xhrFields: app.corsOrigin ? { withCredentials: true } : undefined
			})
				.done(function(response) {
					if (response) {
						showPopup(response);
						if ($.isFunction(data)) {
							data();
						}
					} else {
						$cache.document.trigger($cache.redirectCountryEvent, data);
					}
				});
		});

		$cache.document.on('click', $cache.giftCertRedirectApproveSel, function(e) {
			e.preventDefault();
			$.get(app.urls.removeGiftCertLineItems, { countryCode: $cache.countryCode })
				.done(function(res) {
					redirectCountry(res, true);
				});
		});

		$cache.document.on('click', $cache.redirectCountryApproveSel, function(e) {
			$cache.countrySelectApproved = true;
			e.preventDefault();
			$.ajax({
				url: app.urls.removeLineItems,
				type : 'GET',
				xhrFields: app.corsOrigin ? { withCredentials: true } : undefined,
				data: { Country: $cache.countryCode }
			}).done(function(res) {
				redirectCountry(res, true);
			});
		});

		$cache.document.on('click', $cache.redirectCancelSel, function(e) {
			e.preventDefault();
			app.fancybox.close();
		});

		$cache.selectedCountrySel.on('change', function() {
			var selectedCountyCode = $(this);

			if (app.page.ns === 'checkout' && $cache.countrySelectApproved) {
				selectedCountyCode.attr('disabled', 'disabled');
			}

			$cache.selectedPrevCountry = selectedCountyCode.data('value');
			selectedCountyCode.data('value', selectedCountyCode.val());
		});

		$cache.document.on('cart.shippingCountryChange', function() {
			$cache.selectedCountrySel.removeAttr('disabled');
		});
	}

	function showPopup(res) {
		var sideAccordionSettings = app.util.getConfig('sideAccordion');
		app.fancybox.open(res, {
			wrapCSS: $cache.countryRedirectWrapper + (sideAccordionSettings.enabled ? sideAccordionSettings.externalItemClass : ''),
			onUpdate: function() {
				if (sideAccordionSettings.enabled) {
					$cache.document.trigger('externalItem.opened', ['.' + $cache.countryRedirectWrapper, $cache.redirectCancelSel]);
				}
			},
			afterClose: function() {
				var prevCountryURL = app.util.appendParamsToUrl(app.urls.changeCountry, { Country: $cache.currentCountryCode, format: 'ajax' });
				$.get(prevCountryURL)
					.done(function() {
						$cache.selectedCountrySel.val($cache.selectedPrevCountry || $cache.currentCountryCode);
						$cache.selectedCountrySel.data('value', $cache.currentCountryCode);
						$cache.countrySelectModalOpened = false;
						$cache.selectedCountrySel.trigger('change');

						if ($cache.storedCountryNativeSel) {
							$cache.selectedCountryNativeSel.html($cache.storedCountryNativeSel);
						}
					});
				if (sideAccordionSettings.enabled) {
					$cache.document.trigger('externalItem.closed');
				}
			}
		});
	}

	function redirectCountry(res, isModalApproveTrigger) {
		if (res && res.location && !app.components.global.localeselector.redirectURL) {
			app.page.redirect(res.location);
		} else if (app.components.global.localeselector.redirectURL) {
			app.components.global.localeselector.changeCountry(app.components.global.localeselector.redirectURL);
		} else if (isModalApproveTrigger) {
			app.components.global.localeselector.redirectURL = app.util.appendParamsToUrl(app.urls.changeCountry, { Country: $cache.countryCode, format: 'ajax' });
			app.components.global.localeselector.changeCountry(app.components.global.localeselector.redirectURL);
		}
	}

	/*	app.components.global.modal public object	*/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.modal = {
		init: function() {
			initializeCache();
			initializeEvents();
		}
	};
})(window.app = window.app || {}, jQuery);
