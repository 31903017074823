/**
 * @class app.validator
 */
(function (app, $) {

	var elem = $('.phone').attr('name'),
		phoneCodeElement = $('.phoneCode'),
		regex = {
			phoneInitial: /^\d{2,4}-?\d{2,4}-?\d{2,4}(-?\d{2,4})?$/,
			phoneSimple: /^(?:\+\d{1,3}|0\d{1,3}|00\d{1,2})?(?:\s?\(\d+\))?(?:[-\/\s.]|\d)+$/,
			replaceDashes: /[-." "]{1,}/g,
			zip: {
				us: /^\d{5}(-\d{4})?$/,
				ca: /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1}$|^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1}\s*\d{1}[A-Z]{1}\d{1}$/,
				gb: /^(([gG][iI][rR]{0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/i,
				gi: /^(([gG][iI][rR]{0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/i,
				at: /^[0-9]{4}$/,
				be: /^[0-9]{4}$/,
				bn: /^[BKTP][A-Z][0-9]{4}$/,
				ch: /^[0-9]{4}$/,
				cz: /^[0-9]{3}\s?[0-9]{2}$/,
				dk: /^[0-9]{4}$/,
				hu: /^[0-9]{4}$/,
				ie: /^(([A-Za-z]\d{2})|([A-Za-z]\d[A-Za-z]))\s[A-Za-z\d]{4}$/,
				lu: /^[0-9]{4}$/,
				nl: /^(nl[-\s]?)?[\d]{4}\s*[a-z]{0,2}$/i,
				pl: /^[0-9]{2}-?[0-9]{3}$/,
				pt: /^[0-9]{4}-?[0-9]{3}$/,
				se: /^[0-9]{3}\s?[0-9]{2}$/,
				si: /^([a-z]{2}[-\s])?[\d]{4}$/i,
				sk: /^[0-9]{3}\s?[0-9]{2}$/,
				mt: /^.+$/,
				hk: /^999077$/,
				def: /^[0-9]{3,8}$/
			},
			email: new RegExp(app.util.getConfig('validation.email')),
			addr: /^(?=.*[a-zA-Z]).+$/
		},
		settings = {
			// global form validator settings
			errorClass : 'f-state-error',
			validClass : 'f-state-valid',
			warningClass : 'f-state-warning',
			errorElement : 'span',
			errorMsgClass : 'f-error_text',
			onclick: function (element) {
				if(this.checkable(element)) {
					this.element(element);
				}
			},
			onkeyup : function(element) {
				// onkeyup validation only for fields with the class 'byte'
				if ($(element).hasClass('byte')) {
					this.element(element);
				}
			},
			onfocusout: function(element) {
				if (!this.checkable(element)) {
					// fix for groups as jquery.validate-1.9.0 contains bugs for groups validation
					var group = this.groups[element.name];
					if (group) {
						var context = this;
						$.each(context.groups, function(name, testgroup) {
							if (testgroup === group) {
								var elements = document.getElementsByName(name);
								if (elements.length > 0) {
									context.element(elements[0]);
								}
							}
						});
					}
					else {
						this.element(element);
					}
				}
			},
			errorPlacement: function(error, element) {
				element.attr('aria-invalid', true);
				element.trigger("invalidate");
				if (element.is('select')) {
					if ([app.forms.names.bridalDay, app.forms.names.bridalMonth, app.forms.names.bridalYear].indexOf(element.attr("name")) !== -1) {
						error.appendTo(element.closest(".f-field-wide").find(".js-bridal-error-placeholder"));
					}
					else {
						error.appendTo(element.closest(".f-select-wrapper").siblings(".f-error_message").find(".f-error_message-block"));
					}
				} else if (element.attr('name') === app.forms.names.codCode) {
					$('.js-cod_code_verify_status').html(error);
				} else {
					error.appendTo(element.siblings(".f-error_message").find(".f-error_message-block"));
				}
			},
			success: function(label) {
				var element = $('#' + label.attr('for'));

				element.attr('aria-invalid', false);

				if (element.attr('name') === app.forms.names.codCode) {
					$('.js-cod_code_verify_status').html(app.resources.COD_CODE_VALID);
				}
			},
			rules: {},
			groups: {
				appointmentDate: app.forms.names.bridalDay + " " + app.forms.names.bridalMonth + " " + app.forms.names.bridalYear
			},
			messages: {}
		},
		$cache = {
			body: $('body'),
			onlyNumericSel: '.js-only-numeric',
			phoneCodeSel: 'select[name$=_phoneCode]',
			phoneSel: 'input[name$=phone]',
			emailField: '.js-registration-email',
			errorMessage: '.f-error_message',
			errorText: 'f-error_text',
			errorState: 'f-state-error',
			errorMessageBlock: '.f-error_message-block',
			emailFieldParent: '.f-field'
		};

	var bridalRuleObject = {
		validateFieldsFilled: true,
		validApointmentDate: true
	};
	var bridalMessagesObject = {
		validateFieldsFilled: app.resources.SOME_FIELDS_MISSING,
		validApointmentDate: app.resources.ENTER_CORRECT_DATE
	};

	settings.rules[app.forms.names.bridalDay] = bridalRuleObject;
	settings.rules[app.forms.names.bridalMonth] = bridalRuleObject;
	settings.rules[app.forms.names.bridalYear] = bridalRuleObject;

	settings.messages[app.forms.names.bridalDay] = bridalMessagesObject;
	settings.messages[app.forms.names.bridalMonth] = bridalMessagesObject;
	settings.messages[app.forms.names.bridalYear] = bridalMessagesObject;

	try {
		var cvcValidationRules = JSON.parse(app.preferences.cardCVCValidationRules);
	} catch(e) {
		console.error('Error while parsing cardCVCValidationRules. Error: ' + e.message);
	}

	settings.rules[elem]= {
	      required: isRequiredElement(phoneCodeElement)
	};

	settings.rules[app.forms.names.codCode] = {
		required: true,
		validCode: true
	};

	/**
	 * @function
	 * @description return a function for validation 'fildName' field dependently on state of 'relationFieldName' field
	 * @param {String} name of the field we need validator for
	 * @param {String} name of the field that impacts on validation rules
	 */
	function getRelationValidator(fieldName, relationFieldName) {
		return function (value, el) {
			var formWrapper = $(el).closest(".js-form_wrapper"),
				country = (formWrapper.length ? formWrapper : $(el).closest('form')).find('.' + relationFieldName),
				isOptional = this.optional(el),
				countryCode = '';
			if (country.length) {
				countryCode = country.val()
			} else {
				var countryDataField = $(el).closest('form').find('.js-address-country-name');
				if (countryDataField.length) {
					countryCode = countryDataField.data('country-code');
				}
			}
			return isOptional || validateZipByCountry(countryCode, value);
		};
	}
	/**
	 * @function
	 * @description returns a function for validation 'fildName' field dependently on state of 'relationFieldName' field
	 * reuses getRelationValidator functionality for zip codes
	 * @param {String} name of the field we need validator for
	 * @param {String} name of the field that impacts on validation rules
	 */
	function getZipOrCityValidator(fieldName, relationFieldName) {
		//save function returned by getRelationValidator in a closure variable
		var zipValidator = getRelationValidator(fieldName, relationFieldName);
		return function(value, el) {
			if ( !/\d/.test(value) ) {
				//if city entered(no digits)
				return true;
			} else {
				//call zipValidator saved in a closure variable
				return zipValidator.call(this, value, el);
			}
		};
	}
	/**
	 * @function
	 * @description updates attribute type for zip input tag
	 */
	function updateZipType(countrySel) {
		var countryFields = countrySel ? $(countrySel) : $(".country");
		var zipInputs = $(".zip, .postalCode");
		var country = app.user.country.value;
		setZipType(zipInputs, country);
		if (countryFields.length){
			countryFields.each(function(){
				var $this = $(this);
				country = $this.val() ? $this.val() : app.user.country.value;
				zipInputs =$("#"+this.name.replace("_country" , "_zip") +", #" + this.name.replace("_country" , "_postalCode"));
				setZipType(zipInputs, country)
			});
		}
	}
	/**
	 * @function
	 * @description sets attribute type for zip input tag
	 */
	function setZipType(zipInputs,country) {
		if (!zipInputs){
			return;
		}
		if (app.device.isIOS()){
			zipInputs.attr("type", app.preferences.letterZipCountries.indexOf(country) === -1 ? "number" : "text");
		} else {
			zipInputs.attr("type", "text");
		}
	}
	/**
	 * @function
	 * @description Validates a given email
	 * @param {String} value The email which will be validated
	 * @param {String} el The input field
	 */
	function validateEmail(value, el) {
		var isOptional = this.optional(el),
			isValid = regex.email.test($.trim(value));

		return isOptional || isValid;
	}
	/**
	 * @function
	 * @description Validates a phone without separation on phoneCode and numeric phone
	 * @param {String} value The phone which will be validated
	 * @param {String} el The input field
	 */
	function simpleValidatePhone(value, el){
		var isOptional = this.optional(el),
			isValid = regex.phoneSimple.test($.trim(value));

		return isOptional || isValid;
	}
	/**
	 * @function
	 * @description Translate messages for current locale
	 */
	function localizeMessages() {
		var messageFormated = [
				'remote',
				'email',
				'url',
				'date',
				'dateISO',
				'number',
				'digits',
				'creditcard',
				'equalTo',
				'accept',
				'maxlength',
				'minlength',
				'rangelength',
				'range',
				'max',
				'min'
			];
		$.each(messageFormated, function (index, message) {
			$.validator.messages[message] = $.validator.format(getErrorMsgFromData(message) || app.resources['VALIDATOR_' + message.toUpperCase()]);
		});

	}

	/**
	 * @function
	 * @description Returns error message from data attribute object
	 * @param {String} key
	 */
	function getErrorMsgFromData(key) {
		return $('[data-'+key+'-invalid-text]').data(key+'-invalid-text');
	}

	/**
	 * @function
	 * @description Validates a given confirmation email
	 * @param {String} value The email which will be validated
	 * @param {String} el The input field
	 */
	function validateEmailConfirm(value, el) {
		var $el = $(el),
			$mainMail = $el.closest('form').find('input[id$=_email]');

		return $mainMail.val() === $el.val();
	}

	/**
	 * @function
	 * @description Validates at least one newsletter section is checked if presented
	 * @param {Boolean} value of last newsletter section
	 * @param {Object} el last section checkbox
	 */
	function validateNewsletterSections(value, el){
		var $el = $(el),
			newsletterForm = $el.closest('form'),
			sections = newsletterForm.find('.js-section'),
			registerSignUp = newsletterForm.find('.js-register_signup input'),
			valid = true;

		if(sections.length && !(registerSignUp.length && !registerSignUp.is(':checked'))){
			valid = false;

			for(var i = 0, length = sections.length; i<length; i++){
				var section = sections.eq(i);
				if(section.is(':checked')){
					valid = true;
					break;
				}
			}
		}

		return valid;
	}

	/**
	 * @function
	 * @description Validates a given confirmation email
	 * @param {String} value The email which will be validated
	 * @param {String} el The input field
	 */
	function validatePasswordConfirm(value, el) {
		var $el = $(el),
			$mainPasswd = $el.closest('form').find('input[id$=_password]');

		return $mainPasswd.val() === $el.val();
	}

	/**
	 * @function
	 * @description return validator that checks date fields as a group with a single error message
	 */
	function getBirthdayGroupValidator() {

		var $cache = {
			document : $(document),
			birthdayFields: $('.js-birthday-required'),
			birthdayErrorTarget: $('.js-date_fields-error'),
			formFieldWrapperSel: '.f-field'
		};

		if ($cache.birthdayFields.length > 1) {
			$cache.document.mousedown(function(e) {
				$cache.currElem = e.target;
			});
		}

		return function (value, el){
			var isValid = false;
			var settings = app.validator.settings;

			if (value) {
				isValid = true;
			}

			// user clicked inside of birthday fields
			if ( !isValid && $cache.birthdayFields.filter($cache.currElem).length && !$(el).closest($cache.formFieldWrapperSel).hasClass(settings.errorClass) ) {
					isValid = true;
			}

			if ($cache.birthdayErrorTarget.length) {
				// toggle custom error msg
				if (!isValid || $cache.birthdayFields.not(el).closest($cache.formFieldWrapperSel+ '.' + settings.errorClass).length ) {
					$cache.birthdayErrorTarget.html($.validator.messages.required(undefined, el)).show();
				}
				else {
					$cache.birthdayErrorTarget.hide();
				}
			}

			return isValid;
		}

		return validateBirthdayRequired;
	}

	$.validator.addMethod('js-birthday-required', getBirthdayGroupValidator(), '');

	/**
	 * @function
	 * @description Validates a given birthday fields
	 * @param {String} value The birthday fields which will be validated
	 * @param {String} el The field
	 */

	function validateBirthday(value, el) {
		var isValid = false;
		var form = $(el).closest("form");
		var birthdayErrorMsg = form.find('.js-date_fields-error');
		var ageErrorMsg = form.find('.js-date_age_fields-error').length > 0 ? form.find('.js-date_age_fields-error') : null;
		if(ageErrorMsg != null){
			ageErrorMsg.hide();
		}
		birthdayErrorMsg.hide();
		var day = $(el).hasClass('js-birthday-day') ? $(el) : form.find("select[name$='_day']");
		var month = $(el).hasClass('js-birthday-month') ? $(el) : form.find("select[name$='_month']");
		var year = $(el).hasClass('js-birthday-year') ? $(el) : form.find("select[name$='_year']");

		// in case if customer chose all fields. Check date on valid
		if( day.val() && month.val() && year.val() ) {
			var dob = new Date( year.val(), month.val() - 1, day.val() );
			if( dob.getDate() != day.val() ) {
				showBlockViolation([day, month, year], birthdayErrorMsg);
				return false;
			}

			if(ageErrorMsg != null){
				var age = calculateAge(dob);
				if(age < 18){
					showBlockViolation([year], ageErrorMsg);
					return false;
				} else {
					validateElements([day, month, year]);
				}
			}
		}
		return true;
	}

	function calculateAge(birthday) { // birthday is a date
	    var ageDifMs = Date.now() - birthday.getTime();
	    var ageDate = new Date(ageDifMs); // miliseconds from epoch
	    return Math.abs(ageDate.getUTCFullYear() - 1970);
	}


	function validateElements($elementContainer) {
		for (var i = 0; i < $elementContainer.length; i++ ){
			if($elementContainer[i].val()) {
				$elementContainer[i].closest('.f-field').removeClass(settings.errorClass).addClass(settings.validClass);
			} else {
				$elementContainer[i].closest('.f-field').removeClass(settings.validClass).addClass(settings.errorClass);
			}
		}
	}

	function showBlockViolation($elementContainer, $elementMessage) {
		$elementMessage.show();
		validateElements($elementContainer);
	}

	/**
	 * @function
	 * @description Validates a given expiration fields
	 * @param {String} value The birthday fields which will be validated
	 * @param {String} el The field
	 */
	function validateCreditCardExpirationDate(value, el) {
		var isValid = false,
			$el = $(el),
			$form = $el.closest('form'),
			$expirationErrorMsg = $form.find('.js-date_fields-error'),
			$monthEl = $el.hasClass('js-expirationdate-month') ? $el : $form.find("select[name$='_month']"),
			$yearEl = $el.hasClass('js-expirationdate-year') ? $el : $form.find("select[name$='_year']"),
			monthVal = $monthEl.val(),
			yearVal = $yearEl.val(),
			currentDate = new Date(),
			currentYear = currentDate.getFullYear(),
			currentMonth = currentDate.getMonth()+1;
		$expirationErrorMsg.hide();
		var $expirationdateLabel = $form.find('.js-label-expiration-date');
		// in case if customer doesn't select all fields
		if ( monthVal && yearVal ) {
			$monthEl.removeClass(settings.warningClass);
			$yearEl.removeClass(settings.warningClass);
			var expirationDate = new Date( yearVal, monthVal - 1 );
			if( yearVal == currentYear ) {
				$yearEl.closest('.f-field').removeClass(settings.errorClass).addClass(settings.validClass);
				if ( monthVal >= currentMonth) {
					$monthEl.closest('.f-field').removeClass(settings.errorClass).addClass(settings.validClass);
					$expirationdateLabel.removeClass(settings.errorClass).addClass(settings.validClass);
					return true;
				} else {
					$monthEl.closest('.f-field').removeClass(settings.validClass).addClass(settings.errorClass);
					$expirationdateLabel.removeClass(settings.validClass).addClass(settings.errorClass);
					return false;
				}
			} else if( yearVal > currentYear ) {
				$monthEl.closest('.f-field').removeClass(settings.errorClass).addClass(settings.validClass);
				$yearEl.closest('.f-field').removeClass(settings.errorClass).addClass(settings.validClass);
				$expirationdateLabel.removeClass(settings.errorClass).addClass(settings.validClass);
				return true;
			} else {
				$expirationErrorMsg.show();
				$monthEl.closest('.f-field').removeClass(settings.validClass).addClass(settings.errorClass);
				$yearEl.closest('.f-field').removeClass(settings.validClass).addClass(settings.errorClass);
				$expirationdateLabel.removeClass(settings.validClass).addClass(settings.errorClass);
				return false;
			}
		} else if (monthVal) {
			$monthEl.addClass(settings.warningClass);
			return true;
		} else if (yearVal) {
			$yearEl.addClass(settings.warningClass);
			return true;
		}
		$expirationErrorMsg.show();
		$expirationdateLabel.removeClass(settings.validClass).addClass(settings.errorClass);
		return false;
	}

	/**
	 * @function
	 * @description validates the phone field including ability to run custom validations written in custom object "InternationalTelephoneCode", such as length and custom regexp
	 * 				structure of validation object (app.phone.validationData)is:
	 * 				{//...
	 * 					"code_1":{
	 * 						"countryCode": "US +1", //actual key for CO
	 * 						"code":"1", //actual prefix for country phone numbers
	 * 						"min":10, //default value
	 * 						"max":10, //default value
	 * 						"regexp" : null, //default value, actually it's string (required to be something like ^[0-9]+$)
	 * 						"prefixes" : null, //default value, actually it's string - represents allowed comma separated country prefixes (required to be something like 111,222,333)
	 * 						"prefixLength" : 3 //default value, actually count digits of allowed country prefixes
	 * 					}
	 * 				}//...
	 * @param {String} value of phone field
	 * @param {String} element object
	 */
	function defaultValidatePhone(value, el) {

		var value = $.trim(value),
			isOptional = this.optional(el),
			phoneCode = $(el).closest("form").find(".phoneCode"),
			phoneHTML = value,
			phoneValue = value && app.validator.getPhoneDigits(value),
			validePhone = true,
			countryCode = '',
			regexpChecked = false,
			phoneChecks,
			phonePrefix,
			error;

		if (phoneCode && phoneCode.val() && !$.isEmptyObject(app.phone.validationData)) {
			phoneChecks = app.phone.validationData['code_' + phoneCode.val()];
			if (phoneChecks) {
				phonePrefix = String(phoneValue).substring(0, phoneChecks.prefixLength);
				countryCode = phoneChecks.countryCode;

				if (phoneChecks.min && phoneValue.length < phoneChecks.min) { /*Check phone digits for CO.custom.allowedMinLength*/
					validePhone = false;
					error = 'DIGIT_COUNT';
				} else if (phoneChecks.max && phoneValue.length > phoneChecks.max) { /*Check phone digits for CO.custom.allowedMaxLength*/
					validePhone = false;
					error = 'DIGIT_COUNT';
				} else if (phoneChecks.prefixes && phoneChecks.prefixes.indexOf(phonePrefix) === -1) { /*Check phone digits for CO.custom.prefixes*/
					validePhone = false;
					error = 'AREA_CODE';
				}

				if (validePhone && phoneChecks.regexp) { /*Check phone HTML for CO.custom.regexp*/
					if (!RegExp(phoneChecks.regexp).test(phoneHTML)) {
						validePhone = false;
					}
					regexpChecked = true;
				}
			}
		}

		if (validePhone && !regexpChecked) {
			validePhone = regex.phoneInitial.test(value);
		}

		if (!validePhone) {
			if ('INVALID_PHONE_' + countryCode + '_' + error in app.resources) {
				error = app.resources['INVALID_PHONE_' + countryCode + '_' + error];
			} else if ('INVALID_PHONE_' + error in app.resources) {
				error = app.resources['INVALID_PHONE_' + error];
			} else {
				error = app.resources['INVALID_PHONE'];
			}

			$.validator.messages['phone'] = error;
		}

		return isOptional || validePhone;
	}

	/**
	 * @function
	 * @description	checks whether the user has signed up to the subscription and whether he agreed with the policy
	 * @param {String} element object
	 */
	function validateSignUpAccept( value, e ) {
		return !$( '.js-login_signup input' ).prop( 'checked' ) || $( e ).prop( 'checked' );
	}

	function validateSignUpGender( value, e ) {
		var $signupElement = $(e).closest('form').find( '.js-register_signup input, .js-login_signup input' ),
			signupElementAbsent = !$signupElement.length,
			$genderElement = $(e).closest('.js-signup_gender-wrapper'),
			genderElementChecked = !$genderElement.length || $genderElement.hasClass('checked');
		return signupElementAbsent && genderElementChecked || !signupElementAbsent && !$signupElement.prop( 'checked' ) || genderElementChecked;
	}

	/**
	 * @function
	 * @description	checks whether the user has agreed with the policy
	 * @param {String} element object
	 */
	function validateFooterSignUpAccept( value, e ) {
		return $(e).prop('checked') && $(e).prop('value') == 'true';
	}

 	/**
	 * @function
	 * @description checks whether the user has agreed with the policy
	 * @param {String} element object
	 */
	function validateRegisterSignUpAccept( value, e ) {
		return !$( '.js-register_signup input' ).prop( 'checked' ) || $( e ).prop( 'checked' );
	}

	var passwordValidationMessages = [];

	/**
	 * @function
	 * @description Returns the password validation message composed by all validators in validateRegisterPassword()
	 * @return {String}
	 */
	function validateRegisterPasswordMessages() {
		var separator = app.resources.VALIDATOR_PASSWORD_SEPARATOR + ' ';
		var returnStr = '';
		returnStr += app.resources.VALIDATOR_PASSWORD_BASE + ' ';
		if (passwordValidationMessages.length > 1) {
			returnStr += passwordValidationMessages.slice(0, -1).join(separator);
			returnStr += ' ' + app.resources.VALIDATOR_PASSWORD_AND + ' ' + passwordValidationMessages.slice(-1);
		} else {
			returnStr += passwordValidationMessages.join(separator);
		}
		return returnStr;
	}

	/**
	 * @function
	 * @description validates a password
	 * @param {String} value object
	 */
	function validateRegisterPassword(value) {
		passwordValidationMessages = [];

		function validateRegex(rgx, count) {
			if (count === 0) {
				return true;
			}
			count = count || 1;
			var matches = value.match(new RegExp(rgx, 'g'));
			return matches ? matches.length >= count : false;
		}

		var validators = {
			forceLetters: function() {
				var validate = true;
				if (!validateRegex(app.configs.validation.password.forceLetters)) {
					passwordValidationMessages.push(app.resources.VALIDATOR_PASSWORD_FORCE_LETTERS);
					validate = false;
				}

				return validate;
			},
			forceNumbers: function() {
				var validate = true;
				if (!validateRegex(app.configs.validation.password.forceNumbers)) {
					passwordValidationMessages.push(app.resources.VALIDATOR_PASSWORD_FORCE_NUMBERS);
					validate = false;
				}

				return validate;
			},
			forceMixedCase: function() {
				var validate = true;
				if (!validateRegex(app.configs.validation.password.forceMixedCase)) {
					passwordValidationMessages.push(app.resources.VALIDATOR_PASSWORD_FORCE_MIXED_CASE);
					validate = false;
				}

				return validate;
			},
			minSpecialChars: function(testValue) {
				var validate = true;
				if (!validateRegex(app.configs.validation.password.minSpecialChars, testValue)) {
					if (testValue === 1) {
						passwordValidationMessages.push(app.resources.VALIDATOR_PASSWORD_FORCE_MIN_SPECIAL_CHAR);
					} else {
						passwordValidationMessages.push($.validator.format(app.resources.VALIDATOR_PASSWORD_FORCE_MIN_SPECIAL_CHARS, testValue));
					}
					validate = false;
				}

				return validate;
			}
		};

		var isValid = true;

		$.each(app.resources.PASSWORD_CONSTRAINTS, function(functionKey, functionValue) {
			if (functionValue !== false && functionKey in validators) {
				if (!validators[functionKey](functionValue)) {
					isValid = false;
				}
			}
		});

		return isValid;
	}

	/**
	 * @function
	 * @description checks equals user password and login
	 * @param {String} element object
	 */
	function validateRegisterPasswordEqLogin( value, e){
		var email = $.trim($(e).closest('form').find( '.js-registration-email' ).val());
		var login = email.substring(0, email.indexOf("@"));
		return login !== $.trim(value);
	}

	/**
	 * @function
	 * @description checks whether entered number is valid by jquery.creditCardValidator.js
	 * @param {Boolean} field is valid
	 */
	function validateCardNumber(value, el) {
		var isCCValid = true,
		// case when user select saved card
		$creditCardList = $('#creditCardList'),
		$el = $(el),
		callback = $.noop;

		if ($creditCardList.length && ['', 'new'].indexOf($creditCardList.val()) === -1) {
			return true;
		}
		callback = function(e) {
			isCCValid = e.length_valid && e.luhn_valid;
		};

		$el.validateCreditCard(callback, {
			accept: app.util.getConfig('acceptedCreditCardTypes'),
			formatting : true
		});
		return isCCValid;
	}

	/**
	 * @function
	 * @description checks whether entered text is Latin charset
	 * @param {Boolean} field is valid
	 */
	// NOTE: validation rules should be equal as in the same-named function in FormValidationUtils.ds server-side validation
	function validateCharset(value, el) {
		var isValid = true;
		var textarea = (el.type === 'textarea');
		for(var i = 0; i < value.length; i++) {
			var charCode = value.charCodeAt(i);
			// Charcode of 8216 equals "‘" and 8217 equals "’"
			if (textarea && charCode === 10) {
				continue;
			}
			if (charCode < 32 || (charCode > 255 && charCode !== 8216 && charCode !== 8217)) {
				isValid = false;
				break;
			}
		}
		return isValid;
	}

	/**
	 * @function
	 * @param {String} value of address field
	 * @param {String} element object
	 */
	function validateByteLength(value, el) {
		var maxLength = el.getAttribute("maxlength");
		var byteLength = 0;
		var code = "";
		var newValue = "";
		if (maxLength){
			for (var i = 0; i < value.length; i++) {
				code = value.charCodeAt(i);
				if (code > 0x7f && code <= 0x7ff){
					byteLength += 2;
				} else if (code > 0x7ff && code <= 0xffff){
					byteLength +=3;
				} else {
					byteLength++;
				}
				if (code >= 0xDC00 && code <= 0xDFFF){
					i--; //trail surrogate
				}
				if (byteLength <= maxLength){
					newValue += value[i];
				}
			}
			if (byteLength > maxLength){
				setTimeout(function(){el.value = newValue;}, 100);
				return false;
			}
		}
		return true;
	}
	/**
	 * @function
	 * @param {String} value of address field
	 * @param {String} element object
	 */
	function validateAddr(value, el) {
		return regex.addr.test($.trim(value));
	}

	function validateFileSize(value, el) {
		if (!el.files.length) {
			return true;
		}

		var $this = $(el),
			fileRestriction = $this.data('restrictions') ? new RegExp('\.('+$this.data('restrictions')+')$') : '',
			fileMaxSize = $this.data('maxsize') ? parseInt($this.data('maxsize')): Infinity,
			fileSize = el.files[0].size/Math.pow(10, 6),
			fileField = $this.closest('.js-fileField'),
			inputAttachmentTextWrapper,
			inputAttachmentTextTargetWarningFieldMessage,
			inputAttachmentTextTargetWarningField;

		inputAttachmentTextWrapper = $this.parents('.js-attach-wrapper').siblings(".f-field");
		inputAttachmentTextTargetWarningFieldMessage = inputAttachmentTextWrapper.find('.f-warning_message');
		inputAttachmentTextTargetWarningField = inputAttachmentTextWrapper.find('.f-warning_text');
		inputAttachmentTextTargetWarningFieldMessage.hide();
		inputAttachmentTextTargetWarningField.text('');
		fileField.removeClass('fileField-state-error');

		if (fileSize > fileMaxSize || fileRestriction && !fileRestriction.test(el.files[0].name)) {
			var isFileSizeError = fileSize > fileMaxSize;
			inputAttachmentTextTargetWarningFieldMessage.show();
			inputAttachmentTextTargetWarningField.text(isFileSizeError ? app.resources.ATTACHMENT_SIZE_WARNING : app.resources.ATTACHMENT_WARNING);
			fileField.addClass('fileField-state-error');

			if (isFileSizeError) {
				fileField.wrap('<form>').closest('form').get(0).reset();
				fileField.unwrap();
			}

			return false;
		}

		return true;
	}

	/**
	 * Add phone validation method to jQuery validation plugin.
	 * Text fields must have 'phone' css class to be validated as phone
	 */

	$.validator.addMethod("phone", defaultValidatePhone, app.resources.INVALID_PHONE);
	$.validator.addMethod("phoneSimple", simpleValidatePhone, app.resources.INVALID_PHONE);
	$.validator.addMethod("zip", getRelationValidator("zip", "country"), app.resources.INVALID_ZIP);
	$.validator.addMethod("zip-or-city", getZipOrCityValidator("zip", "country"), app.resources.INVALID_ZIP_OR_CITY);
	$.validator.addMethod("address1", validateAddr, getErrorMsgFromData('address1') || app.resources.INVALID_ADDRESS);
	$.validator.addMethod("byte", validateByteLength, app.resources.INVALID_ADDRESS_LENGTH);
	$.validator.addMethod("js-attachment", validateFileSize, '');

	/**
	 * Add email validation method to jQuery validation plugin.
	 * Text fields must have 'email' css class to be validated as email
	 */
	$.validator.addMethod("email", validateEmail, app.resources.INVALID_EMAIL);

	/**
	 * Add emailconfirm validation method to jQuery validation plugin.
	 * Text fields must have 'emailconfirm' css class to be validated as email
	 */
	$.validator.addMethod('emailconfirm', validateEmailConfirm, app.resources.INVALID_CONFIRM_EMAIL);

	/**
	 * Add section-last validation method to jQuery validation plugin.
	 * Text fields must have 'section-last' css class to be validated as nesletter sections
	 */
	$.validator.addMethod('section-last', validateNewsletterSections, app.resources.SECTION_MISSED_ERROR);

	/**
	 * Add passwordconfirm validation method to jQuery validation plugin.
	 * Text fields must have 'passwordconfirm' css class to be validated as password confirm field
	 */
	$.validator.addMethod('js-passwordconfirm', validatePasswordConfirm, app.resources.INVALID_CONFIRM_PASSWD_NOMATCH);

	/**
	 * Add credit card number validation method to jQuery validation plugin.
	 * Text fields must have 'ccnumber' css class to be validated as credit card number
	 */
	$.validator.addMethod("ccnumber", validateCardNumber, app.resources.INVALID_CREDIT_CARD);

	/**
	 * Add gift cert amount validation method to jQuery validation plugin.
	 * Text fields must have 'gift-cert-amont' css class to be validated
	 */
	$.validator.addMethod("gift-cert-amount", function(value, el){
		var isOptional = this.optional(el);
		var isValid = (!isNaN(value)) && (parseFloat(value)>=5) && (parseFloat(value)<=5000);
		return isOptional || isValid;
	}, app.resources.GIFT_CERT_AMOUNT_INVALID);

	/**
	 * Validate security code (ccv).
	 * Text fields must have 'js-ccvcode' css class to be validated as ccv
	 */
	$.validator.addMethod('js-ccvcode', function (value) {
		if ($.trim(value).length === 0) {
			return true;
		}

		if (typeof cvcValidationRules === "object") {
			var cardType = app.components.global.creditcard.getCardType();

			if (cardType && cardType in cvcValidationRules) {
				return !isNaN(value) && Number(value) >= 0 && value.length === cvcValidationRules[cardType];
			}
		}

		return !isNaN(value) && Number(value) >= 0 && ((value + '').length === 3 || (value + '').length === 4);
	}, app.resources.INVALID_CCV);

	/**
	 * Add positive number validation method to jQuery validation plugin.
	 * Text fields must have 'positivenumber' css class to be validated as positivenumber
	 */
	$.validator.addMethod("positivenumber", function (value, element) {
		if($.trim(value).length === 0) { return true; }
		return (!isNaN(value) && Number(value) >= 0);
	}, "");
	// "" should be replaced with error message if needed

	/**
	 * Add birthday validation method to jQuery validation plugin.
	 * Date fields like date, month, year must have 'birthday' class to be validated as birthday
	 */
	$.validator.addMethod('js-birthday', validateBirthday, "");
	// "" should be replaced with error message if needed
	/**
	 * Add search validation method to jQuery validation plugin.
	 * Avoid empty search
	 */
	$.validator.addMethod('js-validate_placeholder', function (value, element) {
		var $element = $(element);
		return value && $element.val() !== $element.prop('placeholder');
	}, '');
	/**
	 * Add credit card expiration date validation method to jQuery validation plugin.
	 * Date fields like month, year must have 'js-creditcardexpirationdate' class to be validated as expiration date
	 */
	$.validator.addMethod('js-expirationdate', validateCreditCardExpirationDate, app.resources.CREDIT_CARD_EXPIRED);
	// "" should be replaced with error message if needed

	$.validator.addMethod('validApointmentDate', function(value, element) {
		var $element = $(element);
		var $form = $element.closest('form');
		var selectedDay = $element.hasClass('js-appointment_day') ? $element.val() : $form.find('.js-appointment_day').val();
		var selectedMonth = $element.hasClass('js-appointment_month') ? $element.val() : $form.find('.js-appointment_month').val();
		var selectedYear = $element.hasClass('js-appointment_year') ? $element.val() : $form.find('.js-appointment_year').val();

		if (selectedDay && selectedMonth && selectedYear) {
			var currentDate = new Date();
			var selectedDate = new Date(selectedYear, selectedMonth - 1, selectedDay);

			if (selectedDate < currentDate) {
				return false;
			}
		}

		return true;
	});

	$.validator.addMethod('validCode', function(value) {
		var $verifyStatusField = $('.js-cod_code_verify_status');
		if (value && $verifyStatusField.text() === app.resources.COD_CODE_VALID) {
			return true;
		}
		else {
			return false;
		}
	}, app.resources.COD_CODE_INVALID);

	$.validator.addMethod('validateFieldsFilled', function(value, element) {
		var $element = $(element);
		var $form = $element.closest('form');
		var selectedDay = $element.hasClass('js-appointment_day') ? $element.val() : $form.find('.js-appointment_day').val();
		var selectedMonth = $element.hasClass('js-appointment_month') ? $element.val() : $form.find('.js-appointment_month').val();
		var selectedYear = $element.hasClass('js-appointment_year') ? $element.val() : $form.find('.js-appointment_year').val();

		if ((selectedDay || selectedMonth || selectedYear) && (!$element.val())) {
			return false;
		}

		return true;
	});
	/**
	 * Add a validator accept to subscribe to newsletter
	 * User needs select 'Yes' radio button
	 */
	$.validator.addMethod( 'js-signup_accept', validateSignUpAccept, app.resources.ACCEPT_OUR_POLICY );
	$.validator.addMethod( 'js-signup_gender', validateSignUpGender, app.resources.VALIDATOR_REQUIRED_CATEGORY );

	/**
	 * Add a validator accept to subscribe to newsletter in footer
	 * User cannot subscribe witn 'No' radio button selected
	 */
	$.validator.addMethod( 'js-footer_signup_accept', validateFooterSignUpAccept, app.resources.ACCEPT_OUR_POLICY );

	/**
	 * BM minLength configuration
	 */
	$('.js-register_password').attr('minlength', app.resources.PASSWORD_CONSTRAINTS.minLength);

	/**
	 * Add a validator password in register page
	 * Text fields must have 'js-register_password' css class to be validated as password
	 */
	$.validator.addMethod('js-register_password', validateRegisterPassword, validateRegisterPasswordMessages);

	/**
	 * Add a validator password in register page, checking pass and login equals
	 * Text fields must have 'js-register_password_eq_login' css class to be validated as password
	 */
	$.validator.addMethod( 'js-register_password_eq_login', validateRegisterPasswordEqLogin, app.resources.VALIDATOR_PASSWORD_EQ_LOGIN );

	/**
	 * Add a validator accept to subscribe to newsletter in register page
	 */
	$.validator.addMethod( 'js-register_signup_accept', validateRegisterSignUpAccept, app.resources.ACCEPT_OUR_POLICY );
	$.validator.addMethod( 'js-simple_newsletter_email', validateEmail, app.resources.INVALID_NEWSLETTER_EMAIL);
	$.validator.addMethod( "js-iban", function( value, element ) {

		// Remove spaces and to upper case
		var iban = value.replace( / /g, "" ).toUpperCase(),
			ibancheckdigits = "",
			leadingZeroes = true,
			cRest = "",
			cOperator = "",
			countrycode, ibancheck, charAt, cChar, bbanpattern, bbancountrypatterns, ibanregexp, i, p;

		// Check the country code and find the country specific format
		countrycode = iban.substring( 0, 2 );
		bbancountrypatterns = {
			"AL": "\\d{8}[\\dA-Z]{16}",
			"AD": "\\d{8}[\\dA-Z]{12}",
			"AT": "\\d{16}",
			"AZ": "[\\dA-Z]{4}\\d{20}",
			"BE": "\\d{12}",
			"BH": "[A-Z]{4}[\\dA-Z]{14}",
			"BA": "\\d{16}",
			"BR": "\\d{23}[A-Z][\\dA-Z]",
			"BG": "[A-Z]{4}\\d{6}[\\dA-Z]{8}",
			"CR": "\\d{17}",
			"HR": "\\d{17}",
			"CY": "\\d{8}[\\dA-Z]{16}",
			"CZ": "\\d{20}",
			"DK": "\\d{14}",
			"DO": "[A-Z]{4}\\d{20}",
			"EE": "\\d{16}",
			"FO": "\\d{14}",
			"FI": "\\d{14}",
			"FR": "\\d{10}[\\dA-Z]{11}\\d{2}",
			"GE": "[\\dA-Z]{2}\\d{16}",
			"DE": "\\d{18}",
			"GI": "[A-Z]{4}[\\dA-Z]{15}",
			"GR": "\\d{7}[\\dA-Z]{16}",
			"GL": "\\d{14}",
			"GT": "[\\dA-Z]{4}[\\dA-Z]{20}",
			"HU": "\\d{24}",
			"IS": "\\d{22}",
			"IE": "[\\dA-Z]{4}\\d{14}",
			"IL": "\\d{19}",
			"IT": "[A-Z]\\d{10}[\\dA-Z]{12}",
			"KZ": "\\d{3}[\\dA-Z]{13}",
			"KW": "[A-Z]{4}[\\dA-Z]{22}",
			"LV": "[A-Z]{4}[\\dA-Z]{13}",
			"LB": "\\d{4}[\\dA-Z]{20}",
			"LI": "\\d{5}[\\dA-Z]{12}",
			"LT": "\\d{16}",
			"LU": "\\d{3}[\\dA-Z]{13}",
			"MK": "\\d{3}[\\dA-Z]{10}\\d{2}",
			"MT": "[A-Z]{4}\\d{5}[\\dA-Z]{18}",
			"MR": "\\d{23}",
			"MU": "[A-Z]{4}\\d{19}[A-Z]{3}",
			"MC": "\\d{10}[\\dA-Z]{11}\\d{2}",
			"MD": "[\\dA-Z]{2}\\d{18}",
			"ME": "\\d{18}",
			"NL": "[A-Z]{4}\\d{10}",
			"NO": "\\d{11}",
			"PK": "[\\dA-Z]{4}\\d{16}",
			"PS": "[\\dA-Z]{4}\\d{21}",
			"PL": "\\d{24}",
			"PT": "\\d{21}",
			"RO": "[A-Z]{4}[\\dA-Z]{16}",
			"SM": "[A-Z]\\d{10}[\\dA-Z]{12}",
			"SA": "\\d{2}[\\dA-Z]{18}",
			"RS": "\\d{18}",
			"SK": "\\d{20}",
			"SI": "\\d{15}",
			"ES": "\\d{20}",
			"SE": "\\d{20}",
			"CH": "\\d{5}[\\dA-Z]{12}",
			"TN": "\\d{20}",
			"TR": "\\d{5}[\\dA-Z]{17}",
			"AE": "\\d{3}\\d{16}",
			"GB": "[A-Z]{4}\\d{14}",
			"VG": "[\\dA-Z]{4}\\d{16}"
		};

		bbanpattern = bbancountrypatterns[ countrycode ];

		if ( typeof bbanpattern !== "undefined" ) {
			ibanregexp = new RegExp( "^[A-Z]{2}\\d{2}" + bbanpattern + "$", "" );
			if ( !( ibanregexp.test( iban ) ) ) {
				return false; // Invalid country specific format
			}
		}

		// Now check the checksum, first convert to digits
		ibancheck = iban.substring( 4, iban.length ) + iban.substring( 0, 4 );
		for ( i = 0; i < ibancheck.length; i++ ) {
			charAt = ibancheck.charAt( i );
			if ( charAt !== "0" ) {
				leadingZeroes = false;
			}
			if ( !leadingZeroes ) {
				ibancheckdigits += "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ".indexOf( charAt );
			}
		}

		// Calculate the result of: ibancheckdigits % 97
		for ( p = 0; p < ibancheckdigits.length; p++ ) {
			cChar = ibancheckdigits.charAt( p );
			cOperator = "" + cRest + "" + cChar;
			cRest = cOperator % 97;
		}
		return cRest === 1;
	}, app.resources.VALIDATOR_IBAN );

	$.validator.messages.required = function ($1, ele, $3) {
		var requiredText = $(ele).parents('.f-field').attr('data-required-text');
		return requiredText || app.resources['VALIDATOR_REQUIRED'] || '';
	};

	$.validator.addClassRules('f-state-required', { 'required' : true });

	$.validator.messages.valid = function (ele) {
		return $(ele).closest('.f-field').attr('data-valid-text') || app.resources['VALIDATOR_VALID'] || '';
	};

	// pin code validation
	$.validator.addMethod('pincode', function(value, element) {
		if ($(element).is(':visible')) {
			$(document).trigger('pincode.validate');
			return $(element).hasClass('valid');
		} else {
			return true;
		}
	}, app.resources.COD_CODE_INVALID);

	if (app.preferences.enableUnsubscriptionPopup && !app.currentCustomer?.isAuthenticated()) {
		$.validator.addMethod('js-direct_marketing_validation', validateRegisterSignUpByEmail, app.resources.INVALID_EMAIL);
	}

	/**
	 * @description Validate registerSignUp field by email
	 * @param  {Boolean} value 'checked' attribute value
	 * @param  {Element} element input element
	 * @return {Boolean}
	 * @function
	 * @public
	 */
	function validateRegisterSignUpByEmail(value, element) {
		const emailField = $(element).closest('form').find($cache.emailField);
		const emailFieldErrorText = emailField.siblings($cache.errorMessage).find(`.${$cache.errorText}`);
		const emailFieldParent = emailField.closest($cache.emailFieldParent);

		if (!emailField[0].validity.valid) {
			emailField.addClass($cache.errorState);
			emailFieldParent.addClass($cache.errorState);

			if (emailFieldErrorText.length > 0) {
				emailFieldErrorText.text(app.resources.INVALID_EMAIL_DEFAULT);

			} else {
				$('<span>', {
					class: $cache.errorText
				}).appendTo($cache.errorMessageBlock);
				emailFieldErrorText.text(app.resources.INVALID_EMAIL_DEFAULT);
			}

			return false;
		}

		emailField.removeClass($cache.errorState);
		emailFieldParent.removeClass($cache.errorState);
		emailFieldErrorText.remove();

		return true;
	}

	// VAT number validation. Should be synchronized with backend validator.ds analog
	$.validator.addMethod('js-vatnumber_validation', function(value) {
		if (!value) {
			return true;
		}

		var rules = {
			minLength: 8,
			maxLength: 25,
			regexp: '',
			prefix: ''
		};

		var rulesJSON;
		try {
			rulesJSON = JSON.parse(app.preferences.vatValidationRules);
		} catch (e) {
			return null;
		}

		var currentCountry = $('#dwfrm_billing_billingAddress_addressFields_country').val();

		if (rulesJSON && Object.prototype.hasOwnProperty.call(rulesJSON, currentCountry)) {
			rules = rulesJSON[currentCountry];
		}

		var isValid = rules.regexp ? RegExp(rules.regexp).test(value) : true;

		if (value.length < rules.minLength || value.length > rules.maxLength || !isValid) {
			return false;
		}

		return true;
	}, app.resources.VAT_NUMBER_INVALID);

	// latin characters validation
	$.validator.addMethod('f-textinput', validateCharset, app.resources.VALIDATOR_CHARSET);
	$.validator.addMethod('f-textarea', validateCharset, app.resources.VALIDATOR_CHARSET);

	/**
	 * @description Validate zip code for provided country
	 * @param  {String} countryCode
	 * @param  {String} zip
	 * @return {Boolean}
	 * @function
	 * @public
	 */
	function validateZipByCountry(countryCode, zip) {
		countryCode = (countryCode + '').toLowerCase();
		if (!regex.zip[countryCode]) {
			countryCode = 'def';
		}
		return regex.zip[countryCode].test($.trim(zip));
	}

	/**
	 * @description Check by validator if current element
	 * is required
	 * @param  {Array} DOM jquery element
	 * @function
	 * @public
	 * @return {Boolean}
	 */

	function isRequiredElement(element){
		if(!element.length){
			return false;
		}

		var clonedElement = element.clone(),
			validationForm = $('<form/>'),
			insertedElement,
			isValid;

		clonedElement.attr({'name': 'temp', 'id': 'temp'});
		validationForm.css({visibility: 'hidden'}).append(clonedElement);
		$cache.body.append(validationForm);
		insertedElement = $('[name="temp"]');

		validationForm.validate();
		$('[name="temp"]').rules('add', {required: insertedElement.hasClass('f-state-required')});

		switch(insertedElement.prop('nodeName')){
			case "SELECT":
				insertedElement.find('option').remove();
				break;
			case "INPUT":
				insertedElement.val('');
				break;
		}

		isValid = validationForm.valid();
		validationForm.remove();

		return !isValid;
	}

	/******* app.validator public object ********/
	app.validator = {
		validateZipByCountry : validateZipByCountry,
		regex : regex,
		settings : settings,
		init : function () {
			localizeMessages();
			$("form:not(.suppress)").each(function () {
				$(this).validate(app.validator.settings);
			});
			$.validator.setDefaults(app.validator.settings);
			if (app.device.currentDevice() !== "desktop"){
				updateZipType();
			}

			$($cache.onlyNumericSel).on('keydown', function(e) {
				var keyCharCode = e.key.charCodeAt(0);
				if ($.inArray(e.keyCode, [46, 8, 9, 27, 13]) !== -1
						|| (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true))
						|| (e.keyCode === 67 && (e.ctrlKey === true || e.metaKey === true))
						|| (e.keyCode === 88 && (e.ctrlKey === true || e.metaKey === true))
						|| (e.keyCode >= 33 && e.keyCode <= 39)) {
					return;
				}

				if (keyCharCode < 48 || keyCharCode > 57) {
					e.preventDefault();
				}
			});

			$($cache.phoneCodeSel).on('change', function(){
				$(this).closest('form').find($cache.phoneSel).trigger('focusout');
			});
		},
		initForm: function(form) {
			$(form).validate(app.validator.settings);
		},
		/**
		 * Clean value from dashes
		 * Used for cleanup phone number
		*/
		getCleanPhone : function(value){
			return String(value).replace(RegExp(regex.replaceDashes),'');
		},
		getPhoneDigits : function(value){
			return String(value).replace(/\D/g,'');
		}
	};
	/******* app.pattern public object ********/
	app.pattern = {
		init : function () {
			if (app.device.currentDevice() !== "desktop"){
				$(document).on("change", ".country", function(){
					updateZipType(this);
				});
			}
		}
	}
}(window.app = window.app || {}, jQuery));
