/// define some basic modules
/* global define: true*/
define('document', function (require, exports, module) {
	module.exports = document;
});
define('window', function (require, exports, module) {
	module.exports = window;
});

define('$', function (require, exports, module) {
	module.exports = require('jquery');
});
define('$doc', function (require, exports, module) {
	module.exports = require('$')(require('document'));
});
define('$win', function (require, exports, module) {
	module.exports = require('$')(require('window'));
});
define('$body', function (require, exports, module) {
	module.exports = require('$')('body');
});
define('console', function (require, exports, module) {
	module.exports = require('window').console;
});
define('log', function (require, exports, module) {
	module.exports = require('console');
});

define('deprecated', function (require, exports, module) {
	var log = require('log'),
		warned = {};
	function deprecated(msg) {
		if (!warned[msg]) {
			warned[msg] = true;
			log.warn('Deprecated: ' + msg);
		}
	}

	deprecated.warnProp = function warnProp(obj, prop, value, msg) {
		if (Object.defineProperty) {
			// On ES5 browsers (non-oldIE), warn if the code tries to get prop;
			// allow property to be overwritten in case some other plugin wants it
			try {
				Object.defineProperty(obj, prop, {
					configurable: true,
					enumerable: true,
					get: function () {
						deprecated(msg);
						return value;
					},
					set: function (newValue) {
						deprecated(msg);
						value = newValue;
					}
				});
				return;
			} catch (err) {
				// IE8 is a dope about Object.defineProperty, can't warn there
			}
		}
		obj[prop] = value;
	};
	module.exports = deprecated;
});
define('gevent', function (require, exports, module) {
	module.exports = require('$doc');
});

// this is example of bad code but it needs to compatibility
define('app.initializedApps', function (require, exports, module) {
	var window = require('window');
	// load external libs
	require('jquery-ui');
	require('cookie');

	module.exports = (window.app && window.app.initializedApps) || [];
});
