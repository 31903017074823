(function(app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			body: $('body'),
			document: $(document),
			header: $('header'),
			menuButton: $('.js-header_menu_toggle'),
			mainNavigationSel: '.js-main_navigation',
			headerServiceMenuSel: '.js-header_service_menu',
			mainNavigation: $('.js-main_navigation').first(),
			siteWrapperCls: 'js-site_wrapper',
			headerChangeEvent: 'header.change',
			firstLevelPreSelectedSel: '.js-first-level-preselected',
			headerPromoContainerSel: '.js-header-promo-container',
			firstLevelCloseForbiddenCls: 'js-first-level-close_forbidden',
			jsChecked: 'js-checked',
			hChecked: 'h-checked',
			hCollapsed: 'h-collapsed'
		};

		$cache.categoriesMainMenuList = $cache.mainNavigation.find('.js-menu_category-list').first();
	}

	function defineElementBehaviourOnTouchScreen() {
		$($cache.mainNavigationSel).css('touch-action', 'none');
		$($cache.headerServiceMenuSel).css('touch-action', 'none');
	}

	function initializeEvents(param) {
		var buttonsMainMenu = $cache.categoriesMainMenuList.find('input');

		buttonsMainMenu.length > 0 && buttonsMainMenu.on('click', function(){
			var $currentItem = $(this);
			if($cache.categoriesMainMenuList.data('recursiveOpening') && $currentItem.attr('name') !== 'category_1_level'){
				categoryOnClickEventRecursive.call($currentItem);
			}
			else{
				categoryOnClickEvent.call($currentItem);
			}
		});

		//Part of code to open preselected menu
		if (param && param.openPreselectedMenu) {
			//Try to open preselected menu
			var preselectedMenuItems = $($cache.firstLevelPreSelectedSel);
			if (preselectedMenuItems.length) {
				// Not perfect way of layout and menu behavior,
				// related from checked input "b-menu_category-level_1_parent:checked" (_navigation.scss)
				var preselectedMenuItem = preselectedMenuItems.first();
				preselectedMenuItem.prop('checked', true);
				categoryOnClickEvent.call(preselectedMenuItem.get());
			}
		}

		if (Number(app.util.getParamFromUrl(window.location.href, app.util.getConfig('navigation.toggleParamName'))) === 1) {
			menuToggle();
		}

		$cache.menuButton.on('tap click', menuToggle);

		if(!param.disableToggleOnSwipe) {
			$cache.mainNavigation.on('swipeleft', menuToggle);
		}

		if(app.preferences.mobileNavButtonSwipe){
			$cache.menuButton.on('swipeleft', menuClose);
			$cache.menuButton.on('swiperight', menuOpen);
		}

		defineElementBehaviourOnTouchScreen();
	}
	
	function menuClose(){
		menuToggle('close');
	}
	
	function menuOpen(){
		menuToggle('open');
	}
	
	function menuToggle(action){
		action = typeof action !== 'undefined' ? action : "toggle";
		action = action ? action : "toggle";
		
		if(action === "close" && !$cache.mainNavigation.hasClass('m-navigation_opened')){
			return;
		}
		if(action === "open" && $cache.mainNavigation.hasClass('m-navigation_opened')){
			return;
		}
		
		$cache.mainNavigation.toggleClass('m-navigation_opened');
		$('.' + $cache.siteWrapperCls).toggleClass('m-site_opened');
		$cache.body.toggleClass('m-menu_opened');

		$cache.document.trigger('toggle.hideall');
		$cache.header.trigger($cache.headerChangeEvent);
		
		if ($cache.body.hasClass('m-menu_opened')) {
			$cache.document.trigger('mobile_menu.open');
		} else {
			$cache.document.trigger('mobile_menu.close');
		}
		return false;
	}

	function categoryOnClickEvent(recursive){
		var $currentItem = $(this),
			$categoriesList = $currentItem[recursive ? 'siblings' : 'closest']('.js-menu_branch'),
			$inputButtons = $categoriesList.find('input'),
			checkedStatus = $currentItem.hasClass($cache.jsChecked),
			$radioButtonsSecondary;
		
		if(recursive && $currentItem.attr('type') === 'radio'){
			$currentItem.parent('li').siblings().children('input').removeClass($cache.jsChecked).parent().removeClass($cache.hChecked).parent().removeClass($cache.hCollapsed);
		}
		
		if(!checkedStatus){
			$inputButtons.removeClass($cache.jsChecked).parent().removeClass($cache.hChecked).parent().removeClass($cache.hCollapsed);
			$cache.document.trigger('categories_navigation.opened', {
				"item": $currentItem
			});
		}
		else if($currentItem.hasClass($cache.firstLevelCloseForbiddenCls)){
			return;
		}
		else {
			$cache.document.trigger('categories_navigation.back');
		}
		$currentItem.prop('checked', !checkedStatus);
		$currentItem[checkedStatus ? 'removeClass' : 'addClass']($cache.jsChecked);

		if ($currentItem.attr('name') === 'category_1_level' && !recursive) {
			$radioButtonsSecondary = $categoriesList.find('.js-menu_branch').find('input');
			$radioButtonsSecondary.removeClass($cache.jsChecked).prop('checked', false).parent().removeClass($cache.hChecked).parent().removeClass($cache.hCollapsed);
		}

		if(!recursive){
			$inputButtons
				.filter(function() {
					return $(this).prop('checked');
				})
				.addClass($cache.jsChecked);
		}
		
		if($currentItem.hasClass($cache.jsChecked)){
			$currentItem.parent().addClass($cache.hChecked).parent().addClass($cache.hCollapsed);
		}
		else {
			$currentItem.parent().removeClass($cache.hChecked).parent().removeClass($cache.hCollapsed);
		}
	}
	
	function categoryOnClickEventRecursive(){
		$(this).each(function(){
			var $currentItem = $(this),
				$subButtons = $currentItem.siblings('.js-menu_branch').find('input');
			categoryOnClickEvent.call($currentItem, true);
			if($subButtons.length){
				categoryOnClickEventRecursive.call($subButtons);
			}
		});
	}

	function initializeDOM() {
		$cache.body
			.children()
			.not('iframe')
			.not($cache.headerPromoContainerSel)
			.wrapAll($('<div>').addClass($cache.siteWrapperCls));
		$cache.body.prepend($($cache.mainNavigationSel).clone());

		initializeCache();

		$(document).trigger('body.changed', {});
	}

	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.categoriesNavigation = {
		init: function(param) {
			initializeCache();
			initializeDOM();
			initializeEvents(param);
		},

		menuToggle: menuToggle
	};
})((window.app = window.app || {}), jQuery);
