/**
 * @class app.lazyloadxt
 *
 * App for Lazy Load XT v1.1.0 2016-01-12
 * http://ressio.github.io/lazy-load-xt
 * (C) 2016 RESS.io
 * Licensed under MIT
 *
 */
(function (app, $) {
	// Prevent default self-initialization
	$.extend($.lazyLoadXT, {
		autoInit : false
	});
	//

	var $cache = {};

	// This configuration map used for properly extend of configuration,
	// it determine if property will be appended (true) or it will be replaced (false)
	//
	// Also you can extend this map with "app.lazyloadxt.extendsettings.configmap"
	// which mast be set in "app.custom.js"
	var configmap = {
//		selector : true,
//		srcAttr : false,
		blankImage : false,
		edgeY : false,
		edgeX : false,
		throttle : false,
		visibleOnly : false,
		checkDuplicates : false,
		scrollContainer : false,
		forceLoad : false,
		loadEvent : true,
		updateEvent : true,
		forceEvent : true
	};

/**
*	If you want extend this configuration use "app.custom.js" in each project.
*	Create "app.lazyloadxt.extendsettings" object with the same structure as "settings" variable,
*	they will be added to the current settings
*/
	var settings = {
		//autoInit: auto initialization of the plugin. (default true)
		//Type: Boolean

		//selector: selector for elements that should be lazy-loaded (default 'img[data-src],video,iframe[data-src]')
		//Type: String
		//ATTENTION, please be careful with CHANGE of this value - it relate to the app logic
		selector : 'img[data-lazyloadxt-newsrc], [data-lazyloadxt-bgsrc]',

		//srcAttr: attribute containing actual src path, (default 'data-src')
		//Type: String
		//ATTENTION, please be careful with CHANGE of this value - it relate to the app logic
		srcAttr: 'data-lazyloadxt-src',

		//blankImage: blank image for used until actual image is not loaded
		//			(default is transparent 1x1 gif image in data-uri format 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7')
		//Type: String

		//edgeY: expand visible page area (viewport) in vertical direction by specified amount of pixels,
		//		so that elements start to load even if they are not visible, but will be visible after scroll by edgeY pixels (default 0)
		//Type: Number

		//edgeX: expand visible page area in horizontal direction by specified amount of pixels (default 0)
		//Type: Number

		//throttle: time interval (in ms) to check for visible elements,
		//			the plugin uses it to speed up page work in the case of flow of page change events (default 99)
		//Type: Number

		//visibleOnly: being disabled this option forces the plugin to check element position only, but not to check that it is actually visible (default true)
		//Type: Boolean

		//checkDuplicates: prevent re-add lazy-loaded elements (default true)
		//Type: Boolean

		//scrollContainer: set scroll container (overflow: scroll) for adding elements (default null),
		//Type: String

		//forceLoad: load all elements without checking for visibility (default false)
		// Type: Boolean

		//loadEvent: space-separated list of events when the plugin starts to found new elements matching selector
		//			(default 'pageshow' to check AJAX-loaded content in jQueryMobile and to support backward navigation in iPhone)
		//Type: String

		//updateEvent:	space-separated list of events when the plugin starts to check what elements are
		//				visible in current viewport (default 'load orientationchange resize scroll')
		//Type: String

		//forceEvent: space-separated list of events when the plugin starts to load all images independently of are they visible or not (default '')
		//Type: String

		//oninit: 	handler called when the plugin push elements into internal list of "lazy" elements,
		//			it may be either a function (DOM element is accessible using this object) or an object with addClass and/or
		//			removeClass properties (addClass is a space-separated list of class names that should be added to the elements,
		//			and removeClass contains class names that should be removed,
		//			removeClass has higher priority in the case of identical class names) (default {removeClass: 'lazy'})
		//Type: Object
		oninit: onInitHandler,

		//onshow:	handler called when an element appears in viewport area,
		//			it may be either a function or an object by analogy to oninit handler, (default {addClass: 'lazy-hidden'})
		//Type: Object
		onshow: onShowHandler,

		//onload:	handler called when element is successfully loaded,
		//			it may be either a function or an object by analogy to oninit handler (default {removeClass: 'lazy-hidden', addClass: 'lazy-loaded'})
		//Type: Object
		onload: onLoadHandler,

		//onerror:	handler called when browser cannot load the element,
		//			it may be either a function or an object by analogy to oninit handler (default {removeClass: 'lazy-hidden'})
		//Type: Object
		onerror: onErrorHandler,

		//oncomplete: handler called when all lazy-loaded elements are loaded (default null)
		//Type: Object
		oncomplete: onCompleteHandler
	}

	function initializeCache() {
		$cache = {
			loaderClass: 'lazyloadxt-indicator',
			backgroundElAttr: 'data-lazyloadxt-bgsrc',
			newImgElAttr: 'data-lazyloadxt-newsrc',
			imgElAttr: 'data-lazyloadxt-src',
			lazyHiddenCls: 'lazy-hidden',
			lazyHiddenClsSel: '.lazy-hidden',
			lazyLoadedCls: 'lazy-loaded',
			documentReinitEvents: 'grid-update grid-preload-updated minicart.load recommendations.loaded wishlist.added lazyload-reinit',
			updateEvents: 'categoryflyoutopen lazyload-update',
			lazyLoadXTSizer: '.js-lazyloadxt-sizer',
			dataSaveSizer: 'save-sizer'
		};
		$cache.extendsettings = Object.prototype.hasOwnProperty.call(app.lazyloadxt, 'extendsettings') ? app.lazyloadxt.extendsettings : null;
	}

	function initConfiguration(){

		// Extend current configuration settings
		if ( $cache.extendsettings ){

			// You can also rewrite "configmap" if set it as property of "app.lazyloadxt.extendsettings"
			if ( $cache.extendsettings.hasOwnProperty('configmap') ){
				$.extend(configmap, $cache.extendsettings.configmap);
			}

			for(var prop in $cache.extendsettings){
				if ( configmap.hasOwnProperty(prop) && !$.isFunction($cache.extendsettings[prop]) ){
					settings[prop] = configmap[prop] ? settings[prop] + $cache.extendsettings[prop] : $cache.extendsettings[prop];
				}
			}
		}

		$.extend($.lazyLoadXT, settings);
	}

	function initEvents(){
		$(window).lazyLoadXT();

		$(document).on($cache.documentReinitEvents, function() {
			$(window).lazyLoadXT();
		});

		$(document).on($cache.updateEvents, function() {
			$($cache.lazyHiddenClsSel).lazyLoadXT({ show: true });
		});
	}

//////* START Plugin handlers block */
	function onInitHandler(){
		var $this = $(this);

		// If element contain save sizer attribute - we suppose
		// that loader already present on element.
		if(!$this.data($cache.dataSaveSizer)){
			app.lazyloadxt.addLoader($this.closest('DIV'));
		}

		$this.addClass($cache.lazyHiddenCls);
		var attributeValue = this.getAttribute($cache.newImgElAttr);
		if (!!attributeValue){
			this.removeAttribute($cache.newImgElAttr);
			this.setAttribute($cache.imgElAttr, attributeValue);
		}

		callAdditionalHandler( 'oninit', $this );
	}

	function onShowHandler(){
		var $this = $(this);

		// Work with background images
		var url = $this.attr($cache.backgroundElAttr);

		if (!!url) {
			var image = new Image();

			image.src = url;

			image.onload = function () {
				$this
				.css('background-image', "url('" + url + "')")
				.removeAttr($cache.backgroundElAttr)
				.triggerHandler('load');
			};
		}

		callAdditionalHandler( 'onshow', $this );
	}

	function onLoadHandler(){
		var $this = $(this);

		app.lazyloadxt.removeLoader($this.closest('DIV'));
		fixDisplayLayout($this);

		this.removeAttribute($cache.imgElAttr);
		$this.removeClass($cache.lazyHiddenCls).addClass($cache.lazyLoadedCls);

		callAdditionalHandler( 'onload', $this );
	}

	function onErrorHandler(){
		var $this = $(this);
		app.lazyloadxt.removeLoader($this.closest('DIV'));
		fixDisplayLayout($this);

		$this.removeClass($cache.lazyHiddenCls);

		callAdditionalHandler( 'onerror', $this );
	}

	function onCompleteHandler(){
		callAdditionalHandler( 'oncomplete', $(this) );
	}

	function callAdditionalHandler(event, $el) {
		if ( $cache.extendsettings && $cache.extendsettings[event] ){
			var handler = $cache.extendsettings[event];
			if ($.isFunction(handler)) {
				handler.call($el[0]);
			} else {
				if (handler.addClass) {
					$el.addClass(handler.addClass);
				}
				if (handler.removeClass) {
					$el.removeClass(handler.removeClass);
				}
			}
		}
	}
/* Plugin handlers block END *//////

	function addLoader($el){
		$el.append( $("<div/>").addClass($cache.loaderClass) );
	}

	function removeLoader($el){
		$el.find('.' + $cache.loaderClass).remove();
		$el.find($cache.lazyLoadXTSizer).css('visibility', 'hidden');
	}

	function fixDisplayLayout($el){
		if($el.data($cache.dataSaveSizer)){
			$el.css('opacity',1);
		} else {
			$el.siblings($cache.lazyLoadXTSizer).remove();
			$el.css({'position': '', 'top': '', 'left': ''});
		}
	}


	/******* app.lazyloadxt public object ********/
	app.lazyloadxt = $.extend(app.lazyloadxt || {}, {
		init : function () {
			initializeCache();
			initConfiguration();
			initEvents();
		},
		addLoader : addLoader,
		removeLoader : removeLoader
	});
}(window.app = window.app || {}, jQuery));
