import CSRF from 'oneapp/src/utils/CSRF';

(function(app, $) {
	var $cache = {};
	var footer = 'FOOTER';
	var configs = {};
	var specificConfigs = {};

	function initializeConfigs(params) {
		configs = {
			successCallback: successCallback,
			newsletterFormSelector: '.js-simple_newsletter-form',
			submitActionSelector: $('.js-simple_newsletter-button').length
				? '.js-simple_newsletter-button'
				: "[name$='_newsletter_simple_apply']:not(.js-popup_submit)",
			submitBtnsSelector: ".js-action_simple [name*='_newsletter_simple_apply']",
			typeFieldSelector: "[name$='_newsletter_simple_type']",
			detailedSubmitBtnsSelector: "[name*='_newsletter_detailed_apply_']",
			detailedNewsletterFormSelector: 'form.js-newsletter_subscription_form',
			emailFieldSelector: "input[type='email']",
			popupNewsletterSubmitClass: 'js-popup_newsletter_submit',
			popupNewsletterSubmitEvent: 'nlPopupSubscription',
			newsforField: $('.js-simple_newsletter_newsfor'),
			homepageNewsletterBtnSel: '.js-simple_newsletter-button',
			homepageNewsletterIconSel: '.b-hp-nl-subscription_icon',
			homepageNewsletterFormClass: 'js-hp-nl-form',
			hiddenClass: 'h-hidden'
		};

		specificConfigs = app.componentsconfig.specific.global.components['global.simplesubscription'];

		if (params) {
			configs = $.extend(true, {}, configs, params);
		}
	}

	function initializeCache() {
		var newsletterForm, typeField, detailedNewsletterForm;
		$cache = {
			newsletterForm: function(el) {
				if (el) {
					newsletterForm = el;
					return;
				}
				if (!(newsletterForm && newsletterForm.length)) {
					newsletterForm = $(configs.newsletterFormSelector);
				}
				return newsletterForm;
			},
			typeField: function() {
				if (!(typeField && typeField.length)) {
					typeField = $(configs.typeFieldSelector);
				}
				return typeField;
			},
			detailedNewsletterForm: function() {
				if (!(detailedNewsletterForm && detailedNewsletterForm.length)) {
					detailedNewsletterForm = $(configs.detailedNewsletterFormSelector);
				}
				return detailedNewsletterForm;
			},
			document: $(document)
		};
	}

	function initializeEvents() {
		$cache.document.on('submit', configs.newsletterFormSelector, function(e) {
			var form = $(this);
			form.length && $cache.newsletterForm(form);
			e.preventDefault();
			if (app.preferences.subscribeByEnter) {
				subscribeUser(e);
			}
			return false;
		});
		$cache.document.on('click', configs.submitBtnsSelector, function(e) {
			var form = $(this).parents('form');
			form.length && $cache.newsletterForm(form);
			e.preventDefault();
			subscribeUser(e);
		});

		$cache.document.on('click', configs.detailedSubmitBtnsSelector, function(e) {
			e.preventDefault();
			detailedSubscribeUser($(this).val());
		});

		if (configs.submitActionSelector && $(configs.submitBtnsSelector).length === 0) {
			$cache.document.on('click', configs.submitActionSelector, function(e) {
				var form = $(this).parents('form');
				form.length && $cache.newsletterForm(form);
				e.preventDefault();
				subscribeUser(e, this.classList.contains(configs.popupNewsletterSubmitClass) ? configs.popupNewsletterSubmitEvent : null);
			});
		}
	}

	function subscribeUser(e, eventName) {
		var globalNewsletter = app.components.global.newsletter;
		var sectionsStatus = true;
		$cache.newsletterForm().validate();

		if (globalNewsletter && globalNewsletter.validateNewsletterSections) {
			sectionsStatus = app.components.global.newsletter.validateNewsletterSections('.js-footer-blocks-wrap');
		}

		if (!$cache.newsletterForm().valid() || !sectionsStatus) {
			return false;
		}

		if (configs.newsforField.length) {
			configs.newsforField.val(e.currentTarget.value);
		}

		var customEvent = $.Event('simple.subscribe');
		$cache.document.trigger(customEvent, $cache.newsletterForm());

		if (customEvent.isDefaultPrevented()) {
			return false;
		}

		var url = app.urls.submitSimpleSubscription;
		var data = $cache.newsletterForm().serializeArray();

		if (app.newsletterSubscriptionSource) {
			data.push({ name: 'customSource', value: app.newsletterSubscriptionSource });
		}

		var isBannerForm = $cache.newsletterForm()[0].classList.contains(configs.homepageNewsletterFormClass);

		if (app.configs?.newsletter?.animateOnSubmit && isBannerForm && $cache.newsletterForm().valid()) {
			animateOnSubmit();
		}

		CSRF.populatePayloadByCSRFToken(data)
			.then(() => app.ajax.load({
				url: url,
				type: 'POST',
				data: data,
				callback: function(response) {
					if (response.redirectURL) {
						app.page.redirect(response.redirectURL);
					} else {
						var newsletterForm = $cache.newsletterForm();
						var	emailField = newsletterForm.find(configs.emailFieldSelector);
						var	newsletterSections = newsletterForm.find('.js-section');

						$cache.document.trigger('newsletter.subscribed', [emailField.val(), footer, eventName]);

						setNewsletterCookie(2);
						emailField.blur();
						emailField.val('');
						newsletterSections.attr('checked', false);
						animateOnSubmit('initial');

						if (configs.successCallback && typeof configs.successCallback === 'function') {
							configs.successCallback(response);
						}
					}
				}
			}));
	}
	/**
	 * Function to change NL subscription submit button text, disabling and check mark visibility
	 * @param {String} [state] - if passed 'initial' - animates back to initial state
	 */
	function animateOnSubmit(state) {
		const submitBtn = document.querySelector(configs.homepageNewsletterBtnSel);
		const newsletterIcon = document.querySelector(configs.homepageNewsletterIconSel);

		if (submitBtn) {
			if (state === 'initial') {
				submitBtn.disabled = false;
				submitBtn.textContent = app.resources.CUSTOM_SUBSCRIBE_BUTTON_INITIAL;
				newsletterIcon?.classList.add(configs.hiddenClass);
			} else {
				submitBtn.disabled = true;
				submitBtn.textContent = app.resources.CUSTOM_SUBSCRIBE_BUTTON;
				newsletterIcon?.classList.remove(configs.hiddenClass);
			}
		}
	}

	function detailedSubscribeUser(type) {
		if (type) {
			$cache.typeField().val(type);
		}
		var $form = $cache.detailedNewsletterForm();
		$form.validate();
		if (!$form.valid()) {
			return false;
		}
		$form.submit();
	}

	function successCallback(data) {
		$cache.document.trigger('footer.newsletter.success');

		if (data) {
			var options = {
				content: data,
				type: 'html',
				closeBtn: app.device.isMobileView(),
				wrapCSS: 'l-newsletter_popup_desktop m-simplesubscription',
				afterShow: function() {
					$cache.document.trigger('simplenewsletterpopupconfirmation.opened');
				},
				afterClose: function() {
					$cache.document.trigger('simplenewsletterpopupconfirmation.closed');
				}
			};

			options = $.extend(true, options, specificConfigs);

			app.fancybox.open($('footer'), options);

			setTimeout(function() {
				app.fancybox.close();
			}, app.util.getConfig('ajaxNewsletter.thankyouFancyboxCloseTimeout'));
		}
	}

	function setNewsletterCookie(value) {
		var expiration = new Date();
		var year = 365 * 24 * 60;
		expiration.setTime(expiration.getTime() + year * 60 * 1000);
		$.cookie('nlPopupCount', value, { expires: expiration, path: '/' });
	}

	/*************** app.components.global.simplesubscription public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.simplesubscription = {
		init: function(params) {
			initializeConfigs(params);
			initializeCache();
			initializeEvents();
		},
		successCallback: successCallback
	};
})((window.app = window.app || {}), jQuery);
