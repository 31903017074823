(function (app, $) {
	var $cache = {},
		config = {},
		LPCookieName = 'usr-landingpage',
		LPCookieTime = 1095; //3*365

	function initializeConfigs(params) {
		if (params) {
			config = $.extend(true, {}, config, params);
		}
		config.adjustLeftNavHeightOnResize = app.device.isMobileView() ? false : config.adjustLeftNavHeightOnResize;
	}
	
	function initializeCache(){
		$cache = {
			document: $(document),
			window : $(window),
			leftHeaderNavigation : $('.js-main_navigation'),
			navigationLink: '.js-menu_category-general-link',
			delay: 300
		};
	}
	
	function initializeEvents(){
		$cache.document.on('click', $cache.navigationLink, function(){
			var parentCat = $(this).data('parentCat'),
				previousLPValue = $.cookie(LPCookieName);
			
			if(parentCat != null && (!previousLPValue || previousLPValue !== parentCat)){
				$.cookie(LPCookieName, parentCat, {path: '/', expires: LPCookieTime});
			}
		});
		if ( config.adjustLeftNavHeightOnResize ) {
			$cache.window.resize(function(){
				clearTimeout($cache.timeoutId);
				$cache.timeoutId = setTimeout(function(){adjustLeftNavHeight()}, $cache.delay);
			});
		}
	}
	
	function adjustLeftNavHeight() {
		var $nav = $cache.leftHeaderNavigation;
		if ($nav.length){
			var top = $nav.offset().top - $cache.window.scrollTop();
	
			if ($nav.css('position') !== 'fixed') {
				$nav.css('height', '');
				return;
			}
	
			if ( ($nav.outerHeight() + top) === window.innerHeight ) {
				return;
			}
	
			$nav.css('height', 'calc(100% - ' + top + 'px)');
		}
	}

	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.navigation = {
		init: function(params){
			initializeConfigs(params);
			initializeCache();
			initializeEvents();
		},
		adjustLeftNavHeight: adjustLeftNavHeight
	};
	
})(window.app = window.app || {}, jQuery);