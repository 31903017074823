/**
 * Device detection for device and device type
 *
 * Example usage: app.device.isMobileUserAgent() (will return a boolean)
 *
 * Device list:
 *   app.device.isMobileUserAgent()
 *   app.device.isTabletUserAgent()
 *   app.device.currentDevice()
 *
 */
/* global define : true */
define('app.device', function (require, exports) {
	var window = require('window'),
		deprecated = require('deprecated'),
		MODETECT = window.MODETECT;

	function uaMatch(ua) {
		ua = ua.toLowerCase();

		var match = /(chrome)[ \/]([\w.]+)/.exec(ua) ||
			/(webkit)[ \/]([\w.]+)/.exec(ua) ||
			/(opera)(?:.*version|)[ \/]([\w.]+)/.exec(ua) ||
			/(msie) ([\w.]+)/.exec(ua) ||
			ua.indexOf('compatible') < 0 && /(mozilla)(?:.*? rv:([\w.]+)|)/.exec(ua) ||
			[];

		return {
			browser: match[1] || '',
			version: match[2] || '0'
		};
	}
	/**
	 * Returns true if device is in portrait orientation
	 * @return {Boolean}
	 */
	exports.isPortraitOrientation = function () {
		return window.innerHeight > window.innerWidth;
	}
	/**
	 * Returns true if device is mobile
	 * @return {Boolean}
	 */
	exports.isMobileUserAgent = function () {
		return MODETECT && MODETECT.device && MODETECT.device.phone;
	};
	/**
	 * Returns true if device is tablet
	 * @return {Boolean}
	 */
	exports.isTabletUserAgent = function () {
		return MODETECT && MODETECT.device && MODETECT.device.tablet;
	};
	/**
	 * Returns true if there is Mac OS
	 * @return {Boolean}
	 */
	exports.isMacOS = function () {
		return navigator.userAgent.indexOf('Mac OS X') != -1;
	};
	/**
	 * Returns true if there is iOS
	 * @return {Boolean}
	 */
	exports.isIOS = function(){
		//checking MSStream not to mess up iOS with IE11
		return (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream);
	}
	/**
	 * Returns true if there is Android OS
	 * @return {Boolean}
	 */
	exports.isAndroidOS = function(){
		return /android/i.test(navigator.userAgent);
	}
	/**
	 * Detect device type
	 * @return {String} mobile|tablet|desktop
	 */
	exports.currentDevice = function () {
		return exports.isMobileUserAgent() ? 'mobile' : (exports.isTabletUserAgent() ? 'tablet' : 'desktop');
	};
	/**
	 * Detect if content should be shown as for mobile
	 * @return {Boolean}
	 */
	exports.isMobileView = function () {
		return require('app.preferences').isMobileView;
	};
	/**
	 * Return current browser object
	 * @return {Object}
	 */
	
	/**
	 * This function detect touch-screens devices also f.e. notebooks with "touch" ability.
	 */
	exports.isTouchDevice = function(){
		return (('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
	}
	
	/**
	 * Return pixel ratio for current device
	 * @return {String}
	*/
	exports.getPixelRate = function(){
		var mediaQuery = {"(-webkit-min-device-pixel-ratio: 4),(min--moz-device-pixel-ratio: 4),(min-device-pixel-ratio: 4),(min-resolution: 4dppx),(min-resolution: 384dpi)" : 4,
		                "(-webkit-min-device-pixel-ratio: 3),(min--moz-device-pixel-ratio: 3),(min-device-pixel-ratio: 3),(min-resolution: 3dppx),(min-resolution: 288dpi)" : 3,
		                "(-webkit-min-device-pixel-ratio: 2.5),(min--moz-device-pixel-ratio: 2.5),(min-device-pixel-ratio: 2.5),(min-resolution: 2.5dppx),(min-resolution: 240dpi)" : 2.5,
		                "(-webkit-min-device-pixel-ratio: 2),(min--moz-device-pixel-ratio: 2),(-o-min-device-pixel-ratio: 2/1),(min-device-pixel-ratio: 2),(min-device-pixel-ratio: 2),(min-resolution: 2dppx),(min-resolution: 192dpi)" : 2,
		                "(-webkit-min-device-pixel-ratio: 1.75),(min--moz-device-pixel-ratio: 1.75),(min-device-pixel-ratio: 1.75),(min-resolution: 1.75dppx),(min-resolution: 168dpi)" : 1.75,
		                "(-webkit-min-device-pixel-ratio: 1.5),(min--moz-device-pixel-ratio: 1.5),(-o-min-device-pixel-ratio: 3/2),(min-device-pixel-ratio: 1.5),(min-resolution: 1.5dppx),(min-resolution: 144dpi)" : 1.5,
		                "(-webkit-min-device-pixel-ratio: 1.25),(min--moz-device-pixel-ratio: 1.25),(min-device-pixel-ratio: 1.25),(min-resolution: 1.25dppx),(min-resolution: 120dpi)" : 1.25},	                  
		  pixelRatio = 1,
		  media;
		if (window.devicePixelRatio) {
			pixelRatio = window.devicePixelRatio;
		} else if ("matchMedia" in window && window.matchMedia) {
			for (media in mediaQuery){
				if (window.matchMedia(media).matches){
					pixelRatio = mediaQuery[media];
					break;
				}
			}
		} 
		return pixelRatio;
	}
	
	exports.browser = function () {
		var matched = uaMatch(navigator.userAgent),
			browser = {};

		if (matched.browser) {
			browser[matched.browser] = true;
			browser.version = matched.version;
		}

		// Chrome is Webkit, but Webkit is also Safari.
		if (browser.chrome) {
			browser.webkit = true;
		} else if (browser.webkit) {
			browser.safari = true;
		}

		return browser;
	};
	/**
	 * Return current browser name
	 * @return {String}
	 */
	exports.currentBrowser = function () {
		var matched = uaMatch(navigator.userAgent),
			browser = {};

		if (matched.browser) {
			browser = matched.browser;
			if (browser === 'webkit') {
				browser = 'safari';
			}
		}

		return browser;
	};

	deprecated.warnProp(window.app, 'isMobileUserAgent', exports.isMobileUserAgent(),
			'do not use isMobileUserAgent! Please use app.device.isMobileUserAgent()');
	deprecated.warnProp(window.app, 'isTabletUserAgent', exports.isTabletUserAgent(),
			'do not use isTabletUserAgent! Please use app.device.isTabletUserAgent()');
	deprecated.warnProp(window.app, 'currentDevice', exports.currentDevice(),
			'do not use currentDevice! Please use app.device.currentDevice()');
	deprecated.warnProp(window.app, 'isMobileView', exports.isMobileView,
			'do not use isMobileView! Please use app.device.isMobileView()');
});
// shortcat
define('device', function (require, exports, module) {
	module.exports = require('app.device');
});
