( function(app, $) {
	var $cache = {},
		documentEventsInitialized = false;
	
	function initializeCache(params) {
		$cache = {
			initResetPasswordElement : $(".js-password_reset"),
			submitPasswordResetSel: ".js-reset_password-submit",
			formPasswordResetSel: "form#PasswordResetForm"
		};
	}
	
	function initializeEvents(params) {
		$cache.initResetPasswordElement.on("click", function(e){
			e.preventDefault();
			var url = $(this).attr('href');
			app.fancybox.open(url, {
				type: 'ajax',
				width: 370,
				height: 'auto',
				ajax: {
					headers: null,
					xhrFields: {
						withCredentials: true
					}
				},
				autoSize: false,
				afterShow: function() {
					app.validator.init();
				},
				wrapCSS: 'l-reset_password-wrap'
			});
		});
		if (!documentEventsInitialized) {
			initializeDocumentEvents();
		}
	}
	function submitForm() {
		$(this).validate();
		if (!$(this).valid()) {
			return false;
		}
		var url = $(this).attr("action");
		var data = $(this).serialize() + "&" + $(this).find("button").attr("name") + "=send" + "&format=ajax";
		
		var submitBtn = $(this).find($cache.submitPasswordResetSel);
		submitBtn.prop("disabled", true);
		
		$.ajax({
			url: url,
			type: 'POST',
			xhrFields: {
				withCredentials: true
			},
			data: data
		}).done(function(data) {
			submitBtn.prop("disabled", false);
			app.fancybox.close();

			if (data) {
				if (data.redirectURL) {
					app.page.redirect(data.redirectURL);
				} else {
					app.fancybox.open(
						$('footer'), {
							content: data,
							type: 'html',
							width: 370,
							height: 'auto',
							autoSize: false,
							afterShow: function() {
								app.validator.init();
								$('.js-reset_password-close').on('click', function() {
									app.fancybox.close();
								});
							},
							wrapCSS: 'l-reset_password-result-wrap'
						}
					);
				}
			}
		});
	}
	function initializeDocumentEvents() {
		documentEventsInitialized = true;
		$(document).on("submit", "#PasswordResetForm", function(e){
			e.preventDefault();
			submitForm.call(this);
		});
		$(document).on('change', '.js-passwordreset_email', function() {
			var $closestForm = $(this).closest('form#PasswordResetForm');
			if( $closestForm.length && !$closestForm.valid() ) {
				var $errorMsg = $closestForm.find(".js-passwordreset_error");
				if( !$errorMsg.length ) {
					$errorMsg = $closestForm.siblings(".js-passwordreset_error");
				}
				$errorMsg.html('');
			}
		});
		$(document).on("touchstart", $cache.submitPasswordResetSel, function() {
			submitForm.call($(this).closest($cache.formPasswordResetSel));
		});
	}

	/**
	 * @namespace app.global.resetpassword public object
	 **/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.resetpassword = {
		init : function(params) {
			initializeCache(params);
			initializeEvents(params);
		}
	};
}(window.app = window.app || {}, jQuery));
