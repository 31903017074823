(function (app, $) {
	var $cache = {};
	
	function initializeCache() {
		$cache.catList = $('.js-menu_homepage_categories-list');
		$cache.radioButtons = $cache.catList.find('input');
	}

	function initializeEvents() {
		$cache.radioButtons.on('click', categoryOnClickEvent);
	}

	function categoryOnClickEvent(e) {
		var $currentItem = $(this),
			$categoriesList = $currentItem.closest('.js-menu_branch'),
			$radioButtons = $categoriesList.find('input');
			$radioButtonsSecondary = $categoriesList.find('.js-menu_branch').find('input');

		if( $currentItem.hasClass('js-checked') ) {
			$currentItem.prop('checked', false);
			$radioButtons.removeClass('js-checked');
		} else {
			$radioButtons.removeClass('js-checked');
			$currentItem.addClass('js-checked');
		}

		if( $currentItem.attr('name') == 'category_1_level' ) {
			 $radioButtonsSecondary
				.removeClass('js-checked')
				.prop('checked', false);
		}

		$radioButtons
			.filter(function() {
				return $(this).prop('checked');
			})
			.addClass('js-checked');
	}

	app.components = app.components || {};
	app.components.storefront = app.components.storefront || {};
	app.components.storefront.mobile = {
		init : function (params) {
			$(document).ready(function(){
				initializeCache();
				initializeEvents();
			});
		}
	};
	
}(window.app = window.app || {}, jQuery));