/* global define*/
define('app.ajax', function (require, exports) {
	/**
	 * @module ajax
	 */
	// request cache
	var currentRequests = [],
		actionObject = {xhr : null,
						action : ""},
		$ = require('$'),
		util = require('app.util'),
		window = require('window'),
		resources = require('app.resources');

	/**
	 * @function
	 * @description Ajax request to get json response
	 * @param {Boolean} async  Asynchronous or not
	 * @param {String} url URI for the request
	 * @param {Object} data Name/Value pair data request
	 * @param {Function} callback  Callback function to be called
	 */
	exports.getJson = function (options) {
		var action = "";
			action = options.data && typeof options.data === "object" && app.forms.names.checkoutSubmitFormAction in options.data && options.data[app.forms.names.checkoutSubmitFormAction]
			
		if(actionObject.action === action){
			actionObject.xhr.abort();
		}
		actionObject.action = action;
		
		options.url = util.toAbsoluteUrl(options.url);
		// return if no url exists or url matches a current request
		if (!options.url || currentRequests[options.url]) {
			return;
		}

		currentRequests[options.url] = true;

		// make the server call
		actionObject.xhr = $.ajax({
			dataType : 'json',
			type : options.type || 'GET',
			url : options.url,
			async : (typeof options.async === 'undefined' || options.async === null) ? true : options.async,
			data : options.data || {},
			beforeSend: options.beforeSend || $.noop,
			complete: options.complete || $.noop
		})
			// success
			.done(function (response) {
				if (options.callback) {
					options.callback(response);
				}
			})
			// failed
			.fail(function (xhr, textStatus) {
				if (textStatus === 'parsererror') {
					window.alert(resources.BAD_RESPONSE);
				}
				if (options.callback) {
					options.callback(null);
				}
			})
			// executed on success or fail
			.always(function () {
				// remove current request from hash
				if (currentRequests[options.url]) {
					delete currentRequests[options.url];
				}
				actionObject.action = "";
			});
	};
	/**
	 * @function
	 * @description ajax request to load html response in a given container
	 * @param {String} url URI for the request
	 * @param {Object} data Name/Value pair data request
	 * @param {Function} callback  Callback function to be called
	 * @param {Object} target Selector or element that will receive content
	 * @return {Promise}
	 */
	exports.load = function (options) {
		var request,
			deferred;
		options.url = util.toAbsoluteUrl(options.url);
		
		if (currentRequests[options.url]) {
			// return current deferred if call is in progress
			request = currentRequests[options.url];
		} else if (!options.url) {
			// return rejected deferred if empty url
			deferred = $.Deferred();
			deferred.reject(new Error('Empty url param'));
			request = deferred.promise();
		} else {
			// make the server call
			request = $.ajax({
				type : options.type || 'GET',
				url : util.appendParamToURL(options.url, 'format', options.format || 'ajax'),
				xhrFields: window.app.corsOrigin ? { withCredentials: true } : undefined,
				data : options.data
			})
			.done(function (response) {
				// success
				if (options.target) {
					$(options.target).empty().html(response);
				}
				if (options.callback) {
					options.callback(response);
				}
			})
			.fail(function (xhr, textStatus) {
				// failed
				if (textStatus === 'parsererror') {
					window.alert(resources.BAD_RESPONSE);
				}
				if (options.callback) {
					options.callback(null, textStatus);
				}
			})
			.always(function () {
				require('app.progress').hide();
				// remove current request from hash
				if (currentRequests[options.url]) {
					delete currentRequests[options.url];
				}
			});
			currentRequests[options.url] = request;
		}
		return request;
	};
});
// define shortcut
define('ajax', function (require, exports, module) {
	module.exports = require('app.ajax');
});
