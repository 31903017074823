( function(app, $) {
	var $cache = {};

	function initializeCache(){
		$cache = {
			dispatchDelayForm: $('.js-giftcert_dispatch-delay input'),
			datePickerDispatchTarget: $('.js-giftcert_datepicker-target span')
		};
	}
	
	function initializeDOM(){
		app.datepicker.init();
	}
	
	function initializeEvents(){
		$cache.datePickerDispatchTarget.on('click', function(){
			$cache.dispatchDelayForm.focus();
		});
		$cache.dispatchDelayForm.on('click', function(){
			$(this).attr('readonly', true);
		});
	}
	
	
	/**
	 * @namespace app.components.giftcertpurchase.custom public object
	 **/
	app.components = app.components || {};
	app.components.giftcertpurchase = app.components.giftcertpurchase || {};
	app.components.giftcertpurchase.custom = {
		init : function () {
			initializeCache();
			initializeDOM();
			initializeEvents();
		}
	};

}(window.app = window.app || {}, jQuery));