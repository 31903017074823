/**
 * All java script logic for the application.
 *    (c) 2009-2012 Demandware Inc.
 *    Subject to standard usage terms and conditions
 * The code relies on the jQuery JS library to
 * be also loaded.
 *    For all details and documentation:
 *    https://github.com/Demandware/Site-Genesis
 */
// semi-colon to assure functionality upon script concatenation and minification
;

// if jQuery has not been loaded, load from google cdn
if (!window.jQuery) {
	var s = document.createElement('script');
	s.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/1.7.1/jquery.min.js');
	s.setAttribute('type', 'text/javascript');
	document.getElementsByTagName('head')[0].appendChild(s);
}
/** @namespace */
var app = (function (app, $) {
	//allows the use of $ within this function without conflicting with other JavaScript libraries which are using it (JQuery extension)
	document.cookie="dw=1";
	/******** private functions & vars **********/

	/**
	 * @private
	 * @function
	 * @description Cache dom elements which are being accessed multiple times.<br/>app.ui holds globally available elements.
	 */
	function initUiCache() {
		app.ui = {
			searchContainer : $("#navigation .header-search"),
			printPage		: $("a.print-page"),
			reviewsContainer: $("#pwrwritediv"),
			main			: $("main"),
			primary			: $("#primary"),
			secondary		: $("#secondary"),
			// elements found in content slots
			slots : {
				subscribeEmail : $(".subscribe-email")
			},
			newsletterBody	: $("#p-account_newsletter")
		};
	}

	/**
	 * @private
	 * @function
	 * @description Apply dialogify event handler to all elements that match one or more of the specified selectors.
	 */
	function initializeEvents() {
		var controlKeys = ["8", "13", "46", "45", "36", "35", "38", "37", "40", "39"];

		$("body").on("click", ".dialogify, [data-dlg-options], [data-dlg-action]", app.util.setDialogify)
		.on("keydown", "textarea[data-character-limit]", function(e) {
			var text = $.trim($(this).val()),
				charsLimit = $(this).data("character-limit"),
				charsUsed = text.length;

				if ((charsUsed >= charsLimit) && (controlKeys.indexOf(e.which.toString()) < 0)) {
					e.preventDefault();
				}
		})
		.on("change keyup mouseup", "textarea[data-character-limit]", function(e) {
			var text = $.trim($(this).val()),
				charsLimit = $(this).data("character-limit"),
				charsUsed = text.length,
				charsRemain = charsLimit - charsUsed;

			if(charsRemain < 0) {
				$(this).val( text.slice(0, charsRemain) );
				charsRemain = 0;
			}

			$(this).next('div.char-count').find('.char-remain-count').html(charsRemain);
		});


		/**
		 * initialize search suggestions, pending the value of the site preference(enhancedSearchSuggestions)
		 * this will either init the legacy(false) or the beta versions(true) of the the search suggest feature.
		 * */
		if(app.clientcache.LISTING_SEARCHSUGGEST_LEGACY){
			app.searchsuggestbeta.init(app.ui.searchContainer, app.resources.SIMPLE_SEARCH);
		}else{
			app.searchsuggest.init(app.ui.searchContainer, app.resources.SIMPLE_SEARCH);
		}
		
		// print handler
		app.ui.printPage.on("click", function () { window.print(); return false; });


		// add show/hide navigation elements
		$('.secondary-navigation .toggle').click(function(){
			$(this).toggleClass('expanded').next('ul').toggle();
		});

		// add generic toggle functionality
		$('.toggle').next('.toggle-content').hide();
		$('.toggle').click(function(){
			$(this).toggleClass('expanded').next('.toggle-content').toggle();
		});

		// subscribe email box
		if (app.ui.slots.subscribeEmail.length > 0)	{
			app.ui.slots.subscribeEmail.focus(function () {
				var val = $(this.val());
				if(val.length > 0 && val !== app.resources.SUBSCRIBE_EMAIL_DEFAULT) {
					return; // do not animate when contains non-default value
				}

				$(this).animate({ color: '#999999'}, 500, 'linear', function () {
					$(this).val('').css('color','#333333');
				});
			}).blur(function () {
				var val = $.trim($(this.val()));
				if(val.length > 0) {
					return; // do not animate when contains value
				}

				$(this).val(app.resources.SUBSCRIBE_EMAIL_DEFAULT)
					   .css('color','#999999')
					   .animate({color: '#333333'}, 500, 'linear');

			});
		}

		// Country selector URL for Mobile
		$(function() {
			var $el = $('a[href="' + app.urls.countryGatewayStart + '"]:visible');
			switch (app.page.ns) {
				case 'storefront':
					$el.attr('href', app.urls.storefrontChangeCountry);
					break;
				case 'customerservice':
					(function () {
						// body id for customerservice section subpages matches pattern: p-customer_service_customer-service-menu-<category_id>
						var categoryIdMatch = $('body').attr('id').match(/p\-customer_service_customer\-service\-menu\-(.+)/);
						if (categoryIdMatch) {
							// create dynamic key to proper Change Country URL
							$el.attr('href', app.urls[categoryIdMatch[1] + 'ChangeCountry']);
						}
						else {
							// body id of top level Customer Service page does not match the above pattern
							$el.attr('href', app.urls.customerserviceChangeCountry);
						}
					}());
					break;
				case 'search':
					$el.on('click', function () {
						$(this).attr('href', app.urls.categorySearchChangeCountry);
					});
					break;
				case 'product':
					$el.attr('href', app.urls.productChangeCountry);
					break;
				case 'checkout':
					$el.attr('href', app.urls.checkoutChangeCountry);
					break;
				case 'account':
					app.ui.newsletterBody.length ? $el.attr('href', app.urls.newsletterChangeCountry) : $el.attr('href', app.urls.accountChangeCountry);
					break;
				case 'trackorder':
					$el.attr('href', app.urls.trackorderChangeCountry);
					break;
				case 'wishlist':
					$el.attr('href', app.urls.wishlistChangeCountry);
					break;
				case 'orderhistory':
					$el.attr('href', app.urls.accountChangeCountry);
					break;
				default:
					$el.attr('href', app.urls.errorChangeCountry);
			}
		});
	}
	/**
	 * @private
	 * @function
	 * @description Adds class ('js') to html for css targeting and loads js specific styles.
	 */
	function initializeDom() {
		// add class to html for css targeting
		$('html').addClass('js');
		if (app.clientcache.LISTING_INFINITE_SCROLL){
			$('html').addClass('infinite-scroll');
		}
		if (app.clientcache.ACCESSIBILITY_ENABLED) {
			$('body').addClass('m-accessibility');
			if (app.clientcache.ACCESSIBILITY_OUTLINE_COLOR) {
				$('body').addClass('m-accessibility-color');
				var bodyStyle = $('body').attr('style') || '';
				bodyStyle += '--accessibility-color: ' + app.clientcache.ACCESSIBILITY_OUTLINE_COLOR + ';';
				$('body').attr('style', bodyStyle);
			}
		}
		// load js specific styles
		app.util.limitCharacters();
	}


	/**
	 * @property {Object} _app "inherits" app object via $.extend() at the end of this seaf (Self-Executing Anonymous Function)
	 */
	var _app = {
		containerId		: "content",
		ProductCache	: null,  // app.Product object ref to the current/main product
		ProductDetail	: null,
		clearDivHtml	: '<div class="clear"></div>',
		currencyCodes	: app.currencyCodes || {}, // holds currency code/symbol for the site
		
		/**
		 * @name init
		 * @function
		 * @description Master page initialization routine
		 */
		init: function () {
			if (document.cookie.length===0) {
				$(".js-disabled-cookies").removeClass('h-hidden');
			}

			// init global cache
			initUiCache();

			// init global dom elements
			initializeDom();

			// init global events
			initializeEvents();

			// execute page specific initializations
			var ns = app.page.ns;
			if (ns && app[ns] && app[ns].init) {
				app[ns].init();
			}
			
			if (ns) {
				app.componentsMgr.load(ns);
			}
			app.initializedApps = app.initializedApps || [];
			app.initializedApps.push("app");
		}
	};

	return $.extend(app, _app);
}(window.app = window.app || {}, jQuery));

// jquery extensions
(function ($) {
	// params
	// toggleClass - required
	// triggerSelector - optional. the selector for the element that triggers the event handler. defaults to the child elements of the list.
	// eventName - optional. defaults to 'click'
	$.fn.toggledList = function (options) {
		if (!options.toggleClass) { return this; }

		var list = this;
		function handleToggle(e) {
			e.preventDefault();
			var classTarget = options.triggerSelector ? $(this).parent() : $(this);
			classTarget.toggleClass(options.toggleClass);
			// execute callback if exists
			if (options.callback) { options.callback(); }
		}

		return list.on(options.eventName || "click", options.triggerSelector || list.children(), handleToggle);
	};

	$.fn.syncHeight = function () {
		function sortHeight(a, b) {
			return $(a).height() - $(b).height();
		}

		var arr = $.makeArray(this);
		arr.sort(sortHeight);
		return this.height($(arr[arr.length-1]).height());
	};
	
	$.fn.serializeArrayAll = function () {
		var data = $(this).serializeArray();
		
		$(':disabled[name]', this).each(function () { 
			data.push({ name: this.name, value: $(this).val() });
		});
		
		return data;
	}
}(jQuery));

// general extension functions
(function () {
	String.format = function() {
		var s = arguments[0];
		var i,len=arguments.length - 1;
		for (i = 0; i < len; i++) {
			var reg = new RegExp("\\{" + i + "\\}", "gm");
			s = s.replace(reg, arguments[i + 1]);
		}
		return s;
	};
})();

/*
 * Fix array indexOf method in IE
 */
if (!Array.prototype.indexOf) {
	Array.prototype.indexOf = function(obj, start) {
	     for (var i = (start || 0), j = this.length; i < j; i++) {
	         if (this[i] === obj) { return i; }
	     }
	     return -1;
	}
}

if (!Array.isArray) {
	Array.isArray = function (vArg) {
		return Object.prototype.toString.call(vArg) === "[object Array]";
	};
}

if (!Object.isObject) {
	Object.isObject = function(obj) {
		return Object.prototype.toString.call(obj) !== '[object Object]';
	};
}

/* 
* Prevent console messages in non supported browsers
* Mute some console methods for specific type of environment instance
*/
(function () {
	var loggers = [ 'log', 'info', 'warn', 'debug', 'error' ];
	var block = new Array();
	switch (app.debugMode) {
		case app.constants.STAGING_SYSTEM :
			block = [ 'log', 'debug' ];
			break;
		case app.constants.PRODUCTION_SYSTEM :
			block = [ 'log', 'info', 'warn', 'debug', 'error' ];
			break;
	}
	
	var f = function() {};
	if(!('console' in window)) window['console'] = {};
	for(var i=0; i<loggers.length; i++) {
		var logger = loggers[i];
		if(block.indexOf(loggers[i]) >= 0 || !(logger in console)) {
			console[logger] = f;
		}
	};
})();

// initialize app only if code loaded from our domain
if (!app.isExportHeader) {
	jQuery(function() { app.init(); });
}
